import StationSpinner from 'components/station/common/Spinner';
import { swalToastError } from 'helpers/component-utils';
import { CATEGORIES } from 'helpers/utils';
import { React, useEffect, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { startGlass } from 'services/qcService';

var dayjs = require('dayjs');

export default function GlassDetail({
  barcode,
  startStage,
  userId,
  onScanStarted,
  onScanCompleted,
  setProductCompleted
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [glassDetail, setGlassDetail] = useState({});

  useEffect(() => {
    if (barcode) {
      onScanStarted();
      setIsLoading(true);
      const payload = { barcode, stage: startStage, userId };
      startGlass(payload)
        .then(res => {
          if (res && res.data && res.data.glassDetail) {
            setGlassDetail(res.data.glassDetail);
          }
          onScanCompleted(res.data.userTrackingId);
          setProductCompleted(CATEGORIES.GLASS);
          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Glass Detail Failed!');
        });
    }
  }, [barcode]);
  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label">Job No:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">{glassDetail.jobNo}</p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label">Customer:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">
            {glassDetail.customer}
            <Badge bg="danger">{glassDetail.priorityType}</Badge>
          </p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label">Date Required:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">
            {glassDetail.requiredDate
              ? dayjs(glassDetail.requiredDate).format('DD/MM/YYYY')
              : ''}
          </p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label">Unit Size:</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">
            {glassDetail.width &&
              glassDetail.height &&
              glassDetail.width + ` x ` + glassDetail.height}
          </p>
        </Col>
      </Row>
      <Row className="thinnerRow">
        <Col sm={5}>
          <b className="widget-label">Type</b>
        </Col>
        <Col sm={7}>
          <p className="widget-text">{glassDetail.type}</p>
        </Col>
      </Row>
    </div>
  );
}
