import axios from 'axios';

const getMachines = async () => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/machine-management/machines`
    );

    return {
      success: true,
      machines: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getMachineById = async machineId => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/machine-management/machines/${machineId}`
    );

    return {
      success: true,
      machine: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const createMachine = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/machine-management/machines`,
    method: 'POST',
    data: payload
  });

  try {
    return {
      success: true,
      newMachine: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateMachine = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/machine-management/machines`,
    method: 'PATCH',
    data: payload
  });

  try {
    return {
      success: true,
      newMachine: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteMachine = async machineId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/machine-management/machines/${machineId}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

// --------------------------------------
const getStartUpChecksByMachine = async (machineId, app) => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/machine-management/machines/${machineId}/startup-checks?app=${app}`
    );

    return {
      success: true,
      checks: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const createStartupCheck = async (machineId, payload) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/machine-management/machines/${machineId}/startup-checks`,
    method: 'POST',
    data: payload
  });

  try {
    return {
      success: true,
      newCheck: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateCheck = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/machine-management/machines/startup-checks`,
    method: 'PATCH',
    data: payload
  });

  try {
    return {
      success: true,
      newCheck: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteStartupCheck = async checkId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/machine-management/machines/startup-checks/${checkId}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getQAChecklist = async barcode => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Panel/has-checklist?barcode=${barcode}`,
    method: 'GET'
  });
  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  getMachines,
  getMachineById,
  createMachine,
  updateMachine,
  deleteMachine,
  getStartUpChecksByMachine,
  createStartupCheck,
  updateCheck,
  deleteStartupCheck,
  getQAChecklist
};
