import axios from 'axios';

const getTrackingGlassItems = async barcode => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/tracking-glass?barcode=${barcode}`
    );

    return {
      success: res.data.success,
      trackingGlassItems: res.data.glasses
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const checkGlassGeorgianImgPath = async barcode => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/Panel/check-georgian-glass-image?barcode=${barcode}`
    );

    return {
      success: res.data.success,
      missing: res.data.missing,
      path: res.data.path
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export { getTrackingGlassItems, checkGlassGeorgianImgPath };
