import classNames from 'classnames';
import Flex from 'components/common/Flex';
import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import { getCurrentUser } from 'helpers/utils';
import { React, useEffect, useRef, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import SignaturePad from 'react-signature-canvas';
import { uploadMultiFilesToS3 } from 'services/attachmentService';
import {
  addWeldedCillChecking,
  getWeledCillByBarcode
} from 'services/qcService';

const checkingValue = {
  OK: 'Ok',
  REJECTED: 'Rejected',
  NAH: 'N/A'
};

const defaultCheckSheet = {
  weld: checkingValue.NAH,
  weldComment: '',
  angle: checkingValue.NAH,
  angleComment: '',
  length: checkingValue.NAH,
  lengthComment: '',
  cillType: checkingValue.NAH,
  cillTypeComment: '',
  reinforcing: checkingValue.NAH,
  reinforcingComment: '',
  colour: checkingValue.NAH,
  colourComment: '',
  bracing: checkingValue.NAH,
  bracingComment: '',
  loadBearingKit: checkingValue.NAH,
  loadBearingKitComment: '',
  signature: '',
  photo: ''
};

export default function WeldedCillChecking({ barcode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [weldedCillChecking, setWeldedCillChecking] =
    useState(defaultCheckSheet);
  const [weldedCill, setWeldedCill] = useState({});
  const [isDisabledForm, setIsDisabledForm] = useState(true);

  const [files, setFiles] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);

  const signedRef = useRef();

  const handleInputChange = e => {
    let value = e.target.value;
    setWeldedCillChecking({ ...weldedCillChecking, [e.target.name]: value });
  };

  const handleRadioChange = e => {
    setWeldedCillChecking({
      ...weldedCillChecking,
      [e.target.name]: e.target.value
    });
  };

  const clearSignature = () => {
    signedRef.current.clear();

    setWeldedCillChecking({
      ...weldedCillChecking,
      signature: ''
    });

    signedRef.current.on();
  };

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      getWeledCillByBarcode(barcode)
        .then(res => {
          if (res && res.data && res.data.weldedCill) {
            setWeldedCill(res.data.weldedCill);
          }
          if (res && res.data && res.data.weldedCillSubmited) {
            setWeldedCillChecking(res.data.weldedCillSubmited);
            Object.keys(res.data.weldedCillSubmited).map(key => {
              if (res.data.weldedCillSubmited[key] == checkingValue.REJECTED) {
                setIsDisabledForm(false);
              }
            });
          } else {
            setIsDisabledForm(false);
          }
          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Welded Cill Failed!');
        });
    }
  }, [barcode]);

  const handleSubmit = e => {
    e.preventDefault();

    const currentUser = getCurrentUser();

    let signature = '';
    if (
      signedRef.current &&
      signedRef.current.getTrimmedCanvas().toDataURL('image/png')
    ) {
      const signatureBase64 = signedRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      signature = signatureBase64;
    }

    const payload = [];
    const formData = new FormData();
    if (filesUpload.length > 0) {
      filesUpload.map((item, index) => {
        formData.append('files', item);
      });

      const prefix = `eva-scan-station/welded-cill-checking`;
      uploadMultiFilesToS3(formData, prefix)
        .then(res => {
          if (res?.success && res.mappings?.length > 0) {
            res.mappings.map(file => {
              const data = {
                ...weldedCillChecking,
                photo: `s3/${process.env.REACT_APP_BUCKET_NAME}/${prefix}/${file.hashedName}`,
                jobNo: weldedCill.jobNo,
                customer: weldedCill.customer,
                bayId: weldedCill.bayId,
                description: weldedCill.description,
                pCode: weldedCill.pCode,
                requiredDate: dayjs(weldedCill.requiredDate).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                headeId: weldedCill.headeId,
                createdBy: currentUser.userId,
                boxId: weldedCill.boxId,
                signature: signature,
                winNo: weldedCill.winNo
              };
              payload.push(data);
            });
            addWeldedCillChecking(payload)
              .then(res => {
                if (res && res.data) {
                  swalToastSuccess('Add Welded Cill Checking Success!');
                }
              })
              .catch(error => {
                swalToastError('Add Welded Cill Checking Failed!');
              });
          }
        })
        .catch(error => {
          swalToastError('Upload file Failed!');
        });
    } else {
      const data = {
        ...weldedCillChecking,
        photo: ``,
        jobNo: weldedCill.jobNo || '',
        customer: weldedCill.customer || '',
        bayId: weldedCill.bayId || 0,
        description: weldedCill.description || '',
        pCode: weldedCill.pCode || '',
        requiredDate: dayjs(weldedCill.requiredDate).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        headeId: weldedCill.headeId || 0,
        boxId: weldedCill.boxId || 0,
        createdBy: currentUser.userId,
        signature: signature,
        winNo: weldedCill.winNo
      };
      payload.push(data);
      addWeldedCillChecking(payload)
        .then(res => {
          if (res && res.data) {
            swalToastSuccess('Add Welded Cill Checking Success!');
          }
        })
        .catch(error => {
          swalToastError('Add Welded Cill Checking Failed!');
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    onDrop: acceptedFiles => {
      setFilesUpload([...filesUpload, ...acceptedFiles]);
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  return (
    <>
      {barcode ? (
        isLoading ? (
          <StationSpinner></StationSpinner>
        ) : (
          <Form
            autoComplete="off"
            onSubmit={handleSubmit}
            id="product-checking-form"
          >
            <div>
              <Table style={{ cursor: 'default' }}>
                <thead>
                  <tr>
                    <th scope="col">Check</th>
                    <th scope="col">OK</th>
                    <th scope="col">N/A</th>
                    <th scope="col">Rejected</th>
                    <th scope="col">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Angle</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="angle1"
                        name="angle"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.angle == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="angle2"
                        name="angle"
                        value={checkingValue.NAH}
                        defaultChecked={
                          weldedCillChecking?.angle == checkingValue.NAH ||
                          !weldedCillChecking.angle
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="angle3"
                        name="angle"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.angle == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {weldedCillChecking?.angle === checkingValue.REJECTED && (
                        <Form.Control
                          name="angleComment"
                          type="text"
                          value={weldedCillChecking?.angleComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Weld</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="weld1"
                        name="weld"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.weld == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="weld2"
                        name="weld"
                        value={checkingValue.NAH}
                        defaultChecked={
                          weldedCillChecking?.weld == checkingValue.NAH ||
                          !weldedCillChecking.weld
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="weld3"
                        name="weld"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.weld == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {weldedCillChecking?.weld === checkingValue.REJECTED && (
                        <Form.Control
                          name="weldComment"
                          type="text"
                          value={weldedCillChecking?.weldComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Length</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="length1"
                        name="length"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.length == checkingValue.OK ||
                          !weldedCillChecking.length
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="length2"
                        name="length"
                        value={checkingValue.NAH}
                        defaultChecked={
                          weldedCillChecking?.length == checkingValue.NAH
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="length3"
                        name="length"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.length == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {weldedCillChecking?.length ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="lengthComment"
                          type="text"
                          value={weldedCillChecking?.lengthComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Cill Type</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="cillType1"
                        name="cillType"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.cillType == checkingValue.OK ||
                          !weldedCillChecking.cillType
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="cillType2"
                        name="cillType"
                        value={checkingValue.NAH}
                        defaultChecked={
                          weldedCillChecking?.cillType == checkingValue.NAH
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="cillType3"
                        name="cillType"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.cillType == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {weldedCillChecking?.cillType ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="cillTypeComment"
                          type="text"
                          value={weldedCillChecking?.cillTypeComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Reinforcing</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="reinforcing1"
                        name="reinforcing"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.reinforcing == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="reinforcing2"
                        name="reinforcing"
                        value={checkingValue.NAH}
                        defaultChecked={
                          weldedCillChecking?.reinforcing ==
                            checkingValue.NAH || !weldedCillChecking.reinforcing
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="reinforcing3"
                        name="reinforcing"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.reinforcing ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {weldedCillChecking?.reinforcing ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="reinforcingComment"
                          type="text"
                          value={weldedCillChecking?.reinforcingComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Colour</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="colour1"
                        name="colour"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.colour == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="colour2"
                        name="colour"
                        value={checkingValue.NAH}
                        defaultChecked={
                          weldedCillChecking?.colour == checkingValue.NAH ||
                          !weldedCillChecking.colour
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="colour3"
                        name="colour"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.colour == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {weldedCillChecking?.colour ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="colourComment"
                          type="text"
                          value={weldedCillChecking?.colourComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Bracing</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="bracing1"
                        name="bracing"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.bracing == checkingValue.OK ||
                          !weldedCillChecking.bracing
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="bracing2"
                        name="bracing"
                        value={checkingValue.NAH}
                        defaultChecked={
                          weldedCillChecking?.bracing == checkingValue.NAH
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="bracing3"
                        name="bracing"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.bracing == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {weldedCillChecking?.bracing ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="bracingComment"
                          type="text"
                          value={weldedCillChecking?.bracingComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Load Bearing Kit</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="loadBearingKit1"
                        name="loadBearingKit"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.loadBearingKit == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="loadBearingKit2"
                        name="loadBearingKit"
                        value={checkingValue.NAH}
                        defaultChecked={
                          weldedCillChecking?.loadBearingKit ==
                            checkingValue.NAH ||
                          !weldedCillChecking.loadBearingKit
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="loadBearingKit3"
                        name="loadBearingKit"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          weldedCillChecking?.loadBearingKit ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {weldedCillChecking?.loadBearingKit ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="loadBearingKitComment"
                          type="text"
                          value={weldedCillChecking?.loadBearingKitComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Taking Photos</td>
                    <td colSpan={3}>
                      <div
                        {...getRootProps({
                          className: `${classNames(`dropzone-area py-1`)}`
                        })}
                      >
                        <input
                          name="photoUpload"
                          {...getInputProps()}
                          capture="environment"
                        />
                        <Flex justifyContent="center" alignItems="center">
                          <p className="fs-0 mb-0 text-700">Taking Photos</p>
                        </Flex>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {files.map((file, index) => (
                          <Flex alignItems="center" key={index}>
                            <img
                              className="rounded"
                              width={50}
                              height={50}
                              src={file.preview}
                              alt={'document'}
                            />
                          </Flex>
                        ))}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Sign off by:</td>
                    <td colSpan={3}>
                      <div style={{ cursor: 'default' }}>
                        <SignaturePad
                          canvasProps={{ width: 300, height: 100 }}
                          backgroundColor="#a2a2a2"
                          ref={signedRef}
                        />
                      </div>
                      <div style={{ maxWidth: 300, textAlign: 'end' }}>
                        <Button
                          size="small"
                          variant="secondary "
                          onClick={clearSignature}
                        >
                          Clear
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={isDisabledForm}
                        >
                          Submit
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Form>
        )
      ) : (
        <h3>Please Scan Barcode</h3>
      )}
    </>
  );
}
