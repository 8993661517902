import StationSpinner from 'components/station/common/Spinner';
import { React, useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { getPartItems } from 'services/partService';

export default function GlassPackersWidget(props) {
  let barcode = props.barcode;
  let groupName = props.groupName;

  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let rows = [];

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);

      getPartItems(barcode, groupName).then(res => {
        setDataList(res?.partItems);
        setIsLoading(false);
      });
    } else {
      setDataList([]);
    }
  }, [barcode]);

  if (dataList?.length > 0) {
    dataList.forEach(function (item, index) {
      let textDecorationCss = '';

      if (item.fontUnderline && item.fontStrikeOut) {
        textDecorationCss = 'underline line-through';
      } else {
        if (item.fontUnderline) textDecorationCss = 'underline';
        else if (item.fontStrikeOut) textDecorationCss = 'line-through';
      }

      rows.push(
        <tr
          key={'row' + index}
          style={{
            color: item.fontColour,
            backgroundColor: item.bkColour,
            fontWeight: item.fontBold ? 'bold' : '',
            fontSize: item.fontSize,
            fontStyle: item.fontItalic ? 'italic' : '',
            textDecoration: textDecorationCss
          }}
        >
          <td>{item.pCode}</td>
          <td>{item.description}</td>
          <td>{item.qty}</td>
        </tr>
      );
    });
  } else {
    rows.push(
      <tr key={'row0'}>
        <td colSpan={3}>{'No records found.'}</td>
      </tr>
    );
  }

  //console.log(rows);
  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>P/Code</th>
            <th>Description</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
}
