import React from 'react';
import { Col, Row } from 'react-bootstrap';
import version from '../../../package.json';

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto" className="d-flex gap-2">
          <p className="mb-0 text-600">EVA Scan Station</p>
          <p className="mb-0 text-600">v{version.version}</p>
          <p>
            <span className="d-none d-sm-inline-block">| </span>
            <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
            <a href="https://emplas.co.uk/">Emplas</a>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
