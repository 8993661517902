import StationSpinner from 'components/station/common/Spinner';
import { React, useEffect, useState } from 'react';
import { getQAChecklist } from 'services/machineService';

export default function WrapingMachine({ barcode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isQACheckList, setIsQACheckList] = useState('');

  useEffect(() => {
    if (barcode != 0) {
      getQAChecklist(barcode).then(res => {
        if (res && res.data) {
          setIsQACheckList(res.data.message);
        }
      });
    }
  }, [barcode]);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      {isQACheckList ? (
        <div>
          {isQACheckList === 'Passed' && (
            <div
              style={{
                backgroundColor: '#5cb85c'
              }}
            >
              <h5 style={{ fontSize: 32, color: '#f0ad4e' }}>
                This frame has been approved by QA. Ready for wrapping.
              </h5>
            </div>
          )}

          {isQACheckList === 'Rejected' && (
            <div
              style={{
                backgroundColor: '#d9534f'
              }}
            >
              <h5 style={{ fontSize: 32, color: '#f0ad4e' }}>
                This frame has been rejected by QA. Please do not wrap!
              </h5>
            </div>
          )}

          {isQACheckList === 'NotQC' && (
            <div
              style={{
                backgroundColor: '#d9534f'
              }}
            >
              <h5 style={{ fontSize: 32, color: '#f0ad4e' }}>
                This frame has not been checked by QA. Please do not wrap!
              </h5>
            </div>
          )}
        </div>
      ) : (
        <h5>Please Scan Barcode</h5>
      )}
    </>
  );
}
