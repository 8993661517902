import { React, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useState } from 'react';
import { getTrackingGlassItems } from 'services/glassService';
import StationSpinner from 'components/station/common/Spinner';

export default function TrackingGlassWidget(props) {
  let barcode = props.barcode;
  const [trackingGlassItems, setTrackingGlassItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let trackingGlassItemRows = [];

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);

      getTrackingGlassItems(barcode).then(res => {
        console.log(res);

        setTrackingGlassItems(res?.trackingGlassItems);

        setIsLoading(false);
      });
    } else {
      setTrackingGlassItems([]);
    }
  }, [barcode]);

  if (trackingGlassItems?.length > 0) {
    trackingGlassItems.forEach(function (item, index) {
      trackingGlassItemRows.push(
        <tr key={'row' + index}>
          <td className="col-sm-1">{item.jobNo}</td>
          <td className="col-sm-1">{item.winNo}</td>
          <td className="col-sm-5">{item.description}</td>
          <td className="col-sm-1">TG{item.glassId}</td>
          <td className="col-sm-3">
            {item.width} x {item.height}
          </td>
          <td className="col-sm-1">{item.stillageLocation}</td>
        </tr>
      );
    });
  } else {
    trackingGlassItemRows.push(
      <tr key={'row0'}>
        <td colSpan={6}>{'No records found.'}</td>
      </tr>
    );
  }

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Job No</th>
            <th>No</th>
            <th>Description</th>
            <th>TG Code</th>
            <th>Size</th>
            <th>Stillage Location</th>
          </tr>
        </thead>
        <tbody>{trackingGlassItemRows}</tbody>
      </Table>
    </>
  );
}
