import AccessDenied from 'components/errors/AccessDenied';
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  getStationPanels,
  getStationScanningActions
} from 'redux/slices/stationSlice';
import { updateScanningAction } from 'services/stationService';
import { getPermission, getPermissions } from 'redux/slices/permissionSlice';
import { swalToast } from 'helpers/component-utils';

export default function PanelAction() {
  const dispatch = useDispatch();

  const toastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
  };

  let actionOptions = [];
  let panelOptions = [];

  const [validated, setValidated] = useState(false);
  const [actionId, setActionId] = useState(0);
  const [appliedPanel, setAppliedPanel] = useState(0);

  const scanningActions = useSelector(store => store.station.actions);
  const panels = useSelector(store => store.station.panels);

  const permissionId = useSelector(store => store.permission.permissionId);
  const permissions = useSelector(store => store.permission.permissions);

  useEffect(() => {
    dispatch(getStationScanningActions());
    dispatch(getStationPanels());

    if (permissionId <= 0) {
      let loggedData = JSON.parse(localStorage.getItem('login-data'));
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }
  }, []);

  if (scanningActions?.length > 0) {
    actionOptions.push(
      <option key={'action0'} value={0}>
        Please select an action
      </option>
    );
    scanningActions.forEach(function (item, index) {
      actionOptions.push(
        <option key={'action' + index + 1} value={item.id}>
          {item.actionName}
        </option>
      );
    });
  } else {
    actionOptions.push(
      <option key={'action0'} value={0}>
        No action found.
      </option>
    );
  }

  if (panels?.length > 0) {
    panelOptions.push(
      <option key={'panel0'} value={0}>
        Please select a panel
      </option>
    );
    panels.forEach(function (item, index) {
      panelOptions.push(
        <option key={'panel' + index + 1} value={item.id}>
          {item.name}
        </option>
      );
    });
  } else {
    panelOptions.push(
      <option key={'action0'} value={0}>
        No action found.
      </option>
    );
  }

  const handleSelectAction = e => {
    let selectedActionId = e.target.value;
    setActionId(selectedActionId);

    let item = scanningActions.find(x => x.id == selectedActionId);
    let panelId = item != undefined ? item.appliedPanelId : 0;

    setAppliedPanel(panelId);
  };

  const handleInputChange = e => {
    setAppliedPanel(e.target.value);
  };

  const handleSubmit = e => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (actionId < 1) {
      swalToast.fire({
        icon: 'error',
        title: `Please select an action`,
        background: '#d9534f',
        iconColor: '#fff',
        color: '#fff'
      });
      return;
    }

    if (appliedPanel < 1) {
      swalToast.fire({
        icon: 'error',
        title: `Please select a panel`,
        background: '#d9534f',
        iconColor: '#fff',
        color: '#fff'
      });
      return;
    }

    //console.log('do next');
    updateScanningAction(actionId, appliedPanel).then(res => {
      if (res?.success) {
        swalToast.fire({
          icon: 'success',
          title: `Panel has been assigned for action`,
          background: '#5cb85c',
          iconColor: '#fff',
          color: '#fff'
        });
        dispatch(getStationScanningActions());
      } else {
        swalToast.fire({
          icon: 'error',
          title: `Failed to assign panel for action. Please try again`,
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
      }
    });
  };

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <div>
      <div className="g-3 mt-2 row">
        <div className="col-lg-10">
          <Card>
            <Card.Header>
              {' '}
              <h5 className="mb-0">Panel - Action Manager</h5>
            </Card.Header>
            <Card.Body>
              <Form
                autoComplete="off"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Form.Group as={Row} className="mb-3">
                  <Col className="d-flex align-items-center gap-3">
                    <Form.Label className="col-lg-2 col-sm-3 col-sx-4">
                      Action:
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="action"
                      drop="down"
                      value={actionId}
                      onChange={handleSelectAction}
                    >
                      {actionOptions}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col className="d-flex align-items-center gap-3">
                    <Form.Label className="col-lg-2 col-sm-3 col-sx-4">
                      Applied Panel:
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="appliedPanel"
                      drop="down"
                      value={appliedPanel}
                      onChange={handleInputChange}
                    >
                      {panelOptions}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Col sm="5"></Col>
                  <Col sm="1">
                    <Button size="sm" variant="primary" type="submit">
                      Save
                    </Button>{' '}
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <AccessDenied></AccessDenied>
  );
}
