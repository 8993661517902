import classNames from 'classnames';
import Flex from 'components/common/Flex';
import StationSpinner from 'components/station/common/Spinner';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import { CATEGORIES, getCurrentUser } from 'helpers/utils';
import { React, useEffect, useRef, useState } from 'react';
import { Badge, Button, Form, Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import SignaturePad from 'react-signature-canvas';
import { uploadMultiFilesToS3 } from 'services/attachmentService';
import { addAncillChecking, getAncillIntoPack } from 'services/qcService';

var dayjs = require('dayjs');

const checkingValue = {
  OK: 'Ok',
  REJECTED: 'Rejected',
  NAH: 'N/A'
};

export default function AncillPackChecking({
  barcode,
  startStage,
  userId,
  onScanStarted,
  onScanCompleted,
  setProductCompleted,
  groupName
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledForm, setIsDisabledForm] = useState(true);
  const [itemsInPack, setItemsInPack] = useState([]);
  const [ancillCheck, setAncillCheck] = useState([]);
  const [job, setJob] = useState();

  const [files, setFiles] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);

  const signedRef = useRef();

  useEffect(() => {
    setFiles([]);
    setFilesUpload([]);
    if (barcode) {
      onScanStarted();
      setIsLoading(true);
      const payload = {
        barcode,
        stage: startStage,
        userId,
        filterGroup: groupName
      };
      getAncillIntoPack(payload)
        .then(res => {
          if (res && res.data) {
            if (res.data.ancilsIntoPack) {
              setItemsInPack(res.data.ancilsIntoPack);

              res.data.ancilsIntoPack.map(item => {
                if (!item.status || item.status == checkingValue.REJECTED) {
                  setIsDisabledForm(false);
                }
              });

              buildAncillChecking(res.data.ancilsIntoPack);
              onScanCompleted(res.data?.userTrackingId);
              setProductCompleted(CATEGORIES.BOX);
            }

            if (res.data.jobInfo) {
              setJob(res.data.jobInfo);
            }
          }

          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Items Into Pack Failed!');
          setIsLoading(false);
          onScanCompleted(0);
        });
    }
  }, [barcode]);

  const buildAncillChecking = data => {
    const items = data.map(item => {
      return {
        ...item,
        status: item.status || checkingValue.NAH,
        comment: '',
        signature: '',
        createdBy: 0,
        jobNo: item.jobNo,
        winNo: item.winNo,
        requiredDate: dayjs(item.requiredDate).format('YYYY-MM-DD HH:mm:ss')
      };
    });
    setAncillCheck(items);
  };

  const handleInputChange = (e, item) => {
    let value = e.target.value;

    const currentItemChecking = ancillCheck.find(
      a =>
        a.pCode == item.pCode &&
        a.description == item.description &&
        a.itemNo == item.itemNo &&
        a.partsItemId == item.partsItemId
    );

    if (currentItemChecking) {
      currentItemChecking.comment = value;
    }

    setAncillCheck([...ancillCheck]);
    setItemsInPack([...ancillCheck]);
  };

  const handleRadioChange = (e, item) => {
    let value = e.target.value;

    const currentItemChecking = ancillCheck.find(
      a =>
        a.pCode == item.pCode &&
        a.description == item.description &&
        a.itemNo == item.itemNo &&
        a.partsItemId == item.partsItemId
    );

    if (currentItemChecking) {
      currentItemChecking.status = value;
      if (currentItemChecking.status != checkingValue.REJECTED) {
        currentItemChecking.comment = '';
      }
      setAncillCheck([...ancillCheck]);
      setItemsInPack([...ancillCheck]);
    }
  };

  const clearSignature = () => {
    signedRef.current.clear();

    const items = ancillCheck.map(item => {
      return {
        ...item,
        signature: ''
      };
    });

    setAncillCheck(items);

    signedRef.current.on();
  };

  const handleSubmit = e => {
    e.preventDefault();

    const currentUser = getCurrentUser() || {};

    let signature = '';
    if (
      signedRef.current &&
      signedRef.current.getTrimmedCanvas().toDataURL('image/png')
    ) {
      const signatureBase64 = signedRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      signature = signatureBase64;
    }

    const payload = [];
    const formData = new FormData();
    if (filesUpload.length > 0) {
      filesUpload.map((item, index) => {
        formData.append('files', item);
      });

      const prefix = `eva-scan-station/ancill-checking`;
      uploadMultiFilesToS3(formData, prefix)
        .then(res => {
          if (res?.success && res.mappings?.length > 0) {
            res.mappings.map(file => {
              ancillCheck.map(item => {
                const data = {
                  ...item,
                  photo: `s3/${process.env.REACT_APP_BUCKET_NAME}/${prefix}/${file.hashedName}`,
                  createdBy: currentUser.userId,
                  signature: signature
                };
                payload.push(data);
              });
            });
            addAncillChecking(payload)
              .then(res => {
                if (res && res.data) {
                  // setCheckSheet(defaultCheckSheet);
                  swalToastSuccess('Add Ancill Checking Success');
                }
              })
              .catch(error => {
                swalToastError('Add Ancill Checking Failed!');
              });
          }
        })
        .catch(error => {
          swalToastError('Upload file Failed!');
        });
    } else {
      ancillCheck.map(item => {
        const data = {
          ...item,
          photo: ``,
          createdBy: currentUser.userId,
          signature: signature
        };
        payload.push(data);
      });
      addAncillChecking(payload)
        .then(res => {
          if (res && res.data) {
            swalToastSuccess('Add Ancill Checking Success');
          }
        })
        .catch(error => {
          swalToastError('Add Ancill Checking Failed!');
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    onDrop: acceptedFiles => {
      setFilesUpload([...filesUpload, ...acceptedFiles]);
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  const isShowCommentField = item => {
    const currentItemChecking = ancillCheck.find(
      a =>
        a.pCode == item.pCode &&
        a.description == item.description &&
        a.itemNo == item.itemNo &&
        a.partsItemId == item.partsItemId
    );

    if (
      currentItemChecking &&
      currentItemChecking?.status == checkingValue.REJECTED
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      {barcode ? (
        isLoading ? (
          <StationSpinner></StationSpinner>
        ) : (
          <Form
            autoComplete="off"
            onSubmit={handleSubmit}
            id="product-checking-form"
          >
            <div>
              {job && (
                <div
                  style={{
                    display: 'flex',
                    fontWeight: 600,
                    fontSize: 18,
                    gap: '15px'
                  }}
                >
                  <span>{job.jobNo}</span>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {job.customer}
                    {job.priorityType && (
                      <Badge style={{ fontSize: 8 }} bg="danger">
                        {'NEW CUSTOEMR'}
                      </Badge>
                    )}
                  </span>
                  <span>
                    {job.requiredDate
                      ? dayjs(job.requiredDate).format('DD/MM/YYYY')
                      : ''}
                  </span>
                </div>
              )}
              <Table style={{ cursor: 'default' }}>
                <thead>
                  <tr>
                    <th scope="col">P/Code</th>
                    <th scope="col">Description</th>
                    <th scope="col">Length</th>
                    <th scope="col">QTy</th>
                    <th scope="col">OK</th>
                    <th scope="col">N/A</th>
                    <th scope="col">Rejected</th>
                    <th scope="col">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {itemsInPack.length > 0 &&
                    itemsInPack.map((item, index) => (
                      <tr key={index}>
                        <td>{item.pCode}</td>
                        <td>{item.description}</td>
                        <td>{item.length}</td>
                        <td>{item.qTy}</td>
                        <td>
                          <Form.Check
                            inline
                            type="radio"
                            id={item.description + index}
                            name={item.description + index}
                            value={checkingValue.OK}
                            onClick={e => handleRadioChange(e, item)}
                            defaultChecked={item.status == checkingValue.OK}
                            disabled={isDisabledForm}
                          />
                        </td>
                        <td>
                          <Form.Check
                            inline
                            type="radio"
                            id={item.description + index}
                            name={item.description + index}
                            value={checkingValue.NAH}
                            defaultChecked={
                              item.status == checkingValue.NAH || !item.status
                            }
                            onClick={e => handleRadioChange(e, item)}
                            disabled={isDisabledForm}
                          />
                        </td>
                        <td>
                          <Form.Check
                            inline
                            type="radio"
                            id={item.description + index}
                            name={item.description + index}
                            value={checkingValue.REJECTED}
                            onClick={e => handleRadioChange(e, item)}
                            defaultChecked={
                              item.status == checkingValue.REJECTED
                            }
                            disabled={isDisabledForm}
                          />
                        </td>
                        <td>
                          {isShowCommentField(item) && (
                            <Form.Control
                              name={'comment' + index}
                              type="text"
                              value={item.comment}
                              onChange={e => handleInputChange(e, item)}
                            />
                          )}
                        </td>
                      </tr>
                    ))}

                  <tr>
                    <td>Taking Photos</td>
                    <td colSpan={3}>
                      <div
                        {...getRootProps({
                          className: `${classNames(`dropzone-area py-1`)}`
                        })}
                      >
                        <input
                          name="photoUpload"
                          {...getInputProps()}
                          capture="environment"
                        />
                        <Flex justifyContent="center" alignItems="center">
                          <p className="fs-0 mb-0 text-700">Taking Photos</p>
                        </Flex>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {files.map((file, index) => (
                          <Flex alignItems="center" key={index}>
                            <img
                              className="rounded"
                              width={50}
                              height={50}
                              src={file.preview}
                              alt={'document'}
                            />
                          </Flex>
                        ))}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Sign off by:</td>
                    <td colSpan={3}>
                      <div style={{ cursor: 'default' }}>
                        <SignaturePad
                          canvasProps={{ width: 300, height: 100 }}
                          backgroundColor="#a2a2a2"
                          ref={signedRef}
                        />
                      </div>
                      <div style={{ maxWidth: 300, textAlign: 'end' }}>
                        <Button
                          size="small"
                          variant="secondary "
                          onClick={clearSignature}
                        >
                          Clear
                        </Button>
                      </div>
                      <div>
                        <Button variant="primary" onClick={handleSubmit}>
                          Submit
                        </Button>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td colSpan={3}></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Form>
        )
      ) : (
        <h3>Please Scan Barcode</h3>
      )}
    </>
  );
}
