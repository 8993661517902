import classNames from 'classnames';
import Flex from 'components/common/Flex';
import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import { getCurrentUser } from 'helpers/utils';
import { React, useEffect, useRef, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import SignaturePad from 'react-signature-canvas';
import { uploadMultiFilesToS3 } from 'services/attachmentService';
import {
  addGlassChecking,
  addWeldedCillChecking,
  getGlassByBarcode
} from 'services/qcService';

const checkingValue = {
  OK: 'Ok',
  REJECTED: 'Rejected',
  NAH: 'N/A'
};

const defaultCheckSheet = {
  sizeOnLabel: checkingValue.NAH,
  sizeOnLabelComment: '',
  spacerBar: checkingValue.NAH,
  spacerBarComment: '',
  geoBar: checkingValue.NAH,
  geoBarComment: '',
  lead: checkingValue.NAH,
  leadComment: '',
  cleanliness: checkingValue.NAH,
  cleanlinessComment: '',
  gapsInTheSealant: checkingValue.NAH,
  gapsInTheSealantComment: '',
  signature: '',
  photo: ''
};

export default function WeldedCillChecking({
  barcode,
  updateQueue,
  setIsShowSubmitedProductChecking,
  setIsPassedProductChecking
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [glassChecking, setGlassChecking] = useState(defaultCheckSheet);
  const [glassDetail, setGlassDetail] = useState({});
  const [isDisabledForm, setIsDisabledForm] = useState(true);

  const [files, setFiles] = useState([]);
  const [filesUpload, setFilesUpload] = useState([]);

  const signedRef = useRef();

  const handleInputChange = e => {
    let value = e.target.value;
    setGlassChecking({ ...glassChecking, [e.target.name]: value });
  };

  const handleRadioChange = e => {
    const value = e.target.value;
    if (value != checkingValue.REJECTED) {
      setGlassChecking({
        ...glassChecking,
        [e.target.name]: e.target.value,
        [e.target.name + 'Comment']: ''
      });
    } else {
      setGlassChecking({
        ...glassChecking,
        [e.target.name]: e.target.value
      });
    }
  };

  const clearSignature = () => {
    signedRef.current.clear();

    setGlassChecking({
      ...glassChecking,
      signature: ''
    });

    signedRef.current.on();
  };

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      getGlassByBarcode(barcode)
        .then(res => {
          if (res && res.data.glassDetail) {
            setGlassDetail(res.data.glassDetail);
          }

          if (res && res.data.glasschecked) {
            setGlassChecking(res.data.glasschecked);

            Object.keys(res.data.glasschecked).map(key => {
              if (res.data.glasschecked[key] == checkingValue.REJECTED) {
                setIsDisabledForm(false);
              }
            });
          } else {
            setIsDisabledForm(false);
          }

          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Get Glass Failed!');
        });
    }
  }, [barcode]);

  const handleSubmit = e => {
    e.preventDefault();

    const currentUser = getCurrentUser();

    let signature = '';
    if (
      signedRef.current &&
      signedRef.current.getTrimmedCanvas().toDataURL('image/png')
    ) {
      const signatureBase64 = signedRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      signature = signatureBase64;
    }

    const payload = [];
    const formData = new FormData();
    if (filesUpload.length > 0) {
      filesUpload.map((item, index) => {
        formData.append('files', item);
      });

      const prefix = `eva-scan-station/glass-checking`;
      uploadMultiFilesToS3(formData, prefix)
        .then(res => {
          if (res?.success && res.mappings?.length > 0) {
            res.mappings.map(file => {
              const data = {
                ...glassChecking,
                photo: `s3/${process.env.REACT_APP_BUCKET_NAME}/${prefix}/${file.hashedName}`,
                jobNo: glassDetail.jobNo,
                customer: glassDetail.customer,
                glassId: glassDetail.glassId,
                requiredDate: dayjs(glassDetail.requiredDate).format(
                  'YYYY-MM-DD HH:mm:ss'
                ),
                headerId: glassDetail.headerId,
                frameId: glassDetail.frameId,
                width: glassDetail.width,
                height: glassDetail.height,
                winNo: glassDetail.winNo,
                createdBy: currentUser.userId,
                signature: signature
              };
              payload.push(data);
            });
            addWeldedCillChecking(payload)
              .then(res => {
                if (res && res.data) {
                  swalToastSuccess('Add Welded Cill Checking Success!');
                }
              })
              .catch(error => {
                swalToastError('Add Welded Cill Checking Failed!');
              });
          }
        })
        .catch(error => {
          swalToastError('Upload file Failed!');
        });
    } else {
      const data = {
        ...glassChecking,
        photo: ``,
        jobNo: glassDetail.jobNo,
        customer: glassDetail.customer,
        glassId: glassDetail.glassId,
        requiredDate: dayjs(glassDetail.requiredDate).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        headerId: glassDetail.headerId,
        frameId: glassDetail.frameId,
        width: glassDetail.width,
        height: glassDetail.height,
        winNo: glassDetail.winNo,
        createdBy: currentUser.userId,
        signature: signature
      };
      payload.push(data);
      addGlassChecking(payload)
        .then(res => {
          if (res && res.data) {
            swalToastSuccess('Add Glass Checking Success!');
          }
        })
        .catch(error => {
          swalToastError('Add Glass Checking Failed!');
        });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    onDrop: acceptedFiles => {
      setFilesUpload([...filesUpload, ...acceptedFiles]);
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  return (
    <>
      {barcode ? (
        isLoading ? (
          <StationSpinner></StationSpinner>
        ) : (
          <Form
            autoComplete="off"
            onSubmit={handleSubmit}
            id="product-checking-form"
          >
            <div>
              <Table style={{ cursor: 'default' }}>
                <thead>
                  <tr>
                    <th scope="col">Check</th>
                    <th scope="col">OK</th>
                    <th scope="col">N/A</th>
                    <th scope="col">Rejected</th>
                    <th scope="col">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Size on label</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="sizeOnLabel1"
                        name="sizeOnLabel"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.sizeOnLabel == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="sizeOnLabel2"
                        name="sizeOnLabel"
                        value={checkingValue.NAH}
                        defaultChecked={
                          glassChecking?.sizeOnLabel == checkingValue.NAH ||
                          !glassChecking.sizeOnLabel
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="sizeOnLabel3"
                        name="sizeOnLabel"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.sizeOnLabel == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {glassChecking?.sizeOnLabel ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="sizeOnLabelComment"
                          type="text"
                          value={glassChecking?.sizeOnLabelComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Spacer Bar</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="spacerBar1"
                        name="spacerBar"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.spacerBar == checkingValue.OK ||
                          !glassChecking.spacerBar
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="spacerBar2"
                        name="spacerBar"
                        value={checkingValue.NAH}
                        defaultChecked={
                          glassChecking?.spacerBar == checkingValue.NAH
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="spacerBar3"
                        name="spacerBar"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.spacerBar == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {glassChecking?.spacerBar === checkingValue.REJECTED && (
                        <Form.Control
                          name="spacerBarComment"
                          type="text"
                          value={glassChecking?.spacerBarComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Geo Bar</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="geoBar1"
                        name="geoBar"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.geoBar == checkingValue.OK ||
                          !glassChecking.geoBar
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="geoBar2"
                        name="geoBar"
                        value={checkingValue.NAH}
                        defaultChecked={
                          glassChecking?.geoBar == checkingValue.NAH
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="geoBar3"
                        name="geoBar"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.geoBar == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {glassChecking?.geoBar === checkingValue.REJECTED && (
                        <Form.Control
                          name="geoBarComment"
                          type="text"
                          value={glassChecking?.geoBarComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Lead</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="lead1"
                        name="lead"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={glassChecking?.lead == checkingValue.OK}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="lead2"
                        name="lead"
                        value={checkingValue.NAH}
                        defaultChecked={
                          glassChecking?.lead == checkingValue.NAH ||
                          !glassChecking.lead
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="lead3"
                        name="lead"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.lead == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {glassChecking?.lead === checkingValue.REJECTED && (
                        <Form.Control
                          name="leadComment"
                          type="text"
                          value={glassChecking?.leadComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Cleanliness</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="cleanliness1"
                        name="cleanliness"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.cleanliness == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="cleanliness2"
                        name="cleanliness"
                        value={checkingValue.NAH}
                        defaultChecked={
                          glassChecking?.cleanliness == checkingValue.NAH ||
                          !glassChecking.cleanliness
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="cleanliness3"
                        name="cleanliness"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.cleanliness == checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {glassChecking?.cleanliness ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="cleanlinessComment"
                          type="text"
                          value={glassChecking?.cleanlinessComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Gaps in the sealant</td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="gapInTheSealant1"
                        name="gapsInTheSealant"
                        value={checkingValue.OK}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.gapsInTheSealant == checkingValue.OK
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="gapInTheSealant2"
                        name="gapsInTheSealant"
                        value={checkingValue.NAH}
                        defaultChecked={
                          glassChecking?.gapsInTheSealant ==
                            checkingValue.NAH || !glassChecking.gapsInTheSealant
                        }
                        onClick={handleRadioChange}
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      <Form.Check
                        inline
                        type="radio"
                        id="gapInTheSealant3"
                        name="gapsInTheSealant"
                        value={checkingValue.REJECTED}
                        onClick={handleRadioChange}
                        defaultChecked={
                          glassChecking?.gapsInTheSealant ==
                          checkingValue.REJECTED
                        }
                        disabled={isDisabledForm}
                      />
                    </td>
                    <td>
                      {glassChecking?.gapsInTheSealant ===
                        checkingValue.REJECTED && (
                        <Form.Control
                          name="gapsInTheSealantComment"
                          type="text"
                          value={glassChecking?.gapsInTheSealantComment}
                          onChange={handleInputChange}
                        />
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Taking Photos</td>
                    <td colSpan={3}>
                      <div
                        {...getRootProps({
                          className: `${classNames(`dropzone-area py-1`)}`
                        })}
                      >
                        <input
                          name="photoUpload"
                          {...getInputProps()}
                          capture="environment"
                        />
                        <Flex justifyContent="center" alignItems="center">
                          <p className="fs-0 mb-0 text-700">Taking Photos</p>
                        </Flex>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {files.map((file, index) => (
                          <Flex alignItems="center" key={index}>
                            <img
                              className="rounded"
                              width={50}
                              height={50}
                              src={file.preview}
                              alt={'document'}
                            />
                          </Flex>
                        ))}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Sign off by:</td>
                    <td colSpan={3}>
                      <div style={{ cursor: 'default' }}>
                        <SignaturePad
                          canvasProps={{ width: 300, height: 100 }}
                          backgroundColor="#a2a2a2"
                          ref={signedRef}
                        />
                      </div>
                      <div style={{ maxWidth: 300, textAlign: 'end' }}>
                        <Button
                          size="small"
                          variant="secondary "
                          onClick={clearSignature}
                        >
                          Clear
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={isDisabledForm}
                        >
                          Submit
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Form>
        )
      ) : (
        <h3>Please Scan Barcode</h3>
      )}
    </>
  );
}
