import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';

export default function SashImageHighlighted({ content }) {
  return content ? (
    <Image
      style={{ maxWidth: 800, maxHeight: 400 }}
      src={`data:image/jpeg;base64, ${content.fileContents}`}
      width={300}
      height={300}
      className="center-block"
      alt="frame image"
      unoptimized="true"
    ></Image>
  ) : (
    <h4>No image found</h4>
  );
}
