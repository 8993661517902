import axios from 'axios';

const getOptionsByKey = async key => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/option-lists-management/${key}`
  );

  try {
    return {
      optionLists: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const createOption = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/option-lists-management`,
    method: 'POST',
    data: payload
  });

  try {
    return {
      success: res.data.success,
      newOptionList: res.data.newOptionList
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateOptionName = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/option-lists-management/option-name`,
    method: 'PATCH',
    data: payload
  });

  try {
    return {
      success: res.data.success,
      updatedOption: res.data.updatedOption
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteOption = async optionId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/option-lists-management/${optionId}`,
    method: 'delete'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export { getOptionsByKey, createOption, updateOptionName, deleteOption };
