import StationSpinner from 'components/station/common/Spinner';
import { React, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getPartItemsV3 } from 'services/partService';

export default function PartsWidgetV6(props) {
  const [partItems, setPartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.barcode != 0) {
      setIsLoading(true);
      getPartItemsV3(props.barcode, props.groupName)
        .then(res => {
          setPartItems(res?.partItems);
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setPartItems([]);
    }
  }, [props.barcode]);

  const getTextDecorationStyle = item => {
    if (item.fontUnderline && item.fontStrikeOut) {
      return 'underline line-through';
    } else {
      if (item.fontUnderline) return 'underline';
      else if (item.fontStrikeOut) return 'line-through';
    }
  };

  const getTextVNotch = (vNotchInfoA, vNotchInfoB) => {
    if (vNotchInfoA && !vNotchInfoB) {
      return vNotchInfoA;
    } else if (vNotchInfoB && !vNotchInfoA) {
      return vNotchInfoB;
    } else if (vNotchInfoB && vNotchInfoA) {
      return vNotchInfoB + ' ' + vNotchInfoA;
    }
  };

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Qty</th>
            <th>P/Code</th>
            <th>Description</th>
            <th>Length</th>
            <th>V-Notch</th>
          </tr>
        </thead>
        <tbody>
          {partItems.length > 0 ? (
            partItems.map((item, index) => (
              <tr
                key={'row' + index}
                style={{
                  color: item.fontColour,
                  backgroundColor: item.bkColour,
                  fontWeight: item.fontBold ? 'bold' : '',
                  fontSize: item.fontSize,
                  fontStyle: item.fontItalic ? 'italic' : '',
                  textDecoration: getTextDecorationStyle(item)
                }}
              >
                <td>{item.qty}</td>
                <td>{item.pCode}</td>
                <td>{item.description}</td>
                <td>{item.length1}</td>
                <td>{getTextVNotch(item.vNotchInfoA, item.vNotchInfoB)}</td>
              </tr>
            ))
          ) : (
            <tr key={'row0'}>
              <td colSpan={3}>{'No records found.'}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
