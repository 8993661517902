import StationSpinner from 'components/station/common/Spinner';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';

export default function LoginForm(props) {
  const [loginID, setLoginID] = useState('');
  const loginCompleted = props.loginCompleted;

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    props.login(loginID);
  };

  const handleFieldChange = e => {
    setLoginID(e.target.value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 row">
        <Col sm={10}>
          <Form.Control
            autoFocus={true}
            value={loginID}
            name="email"
            onChange={handleFieldChange}
            type="text"
          />
        </Col>
        <Col sm={2}>
          {!loginCompleted ? <StationSpinner></StationSpinner> : ''}
        </Col>
      </Form.Group>
      <Form.Group className="mb-3 row ">
        <Col sm={1}>
          <button className="btn btn-primary" type="submit">
            Login
          </button>
        </Col>
      </Form.Group>
    </Form>
  );
}

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};
