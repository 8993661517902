export const widgetWithFilterGroupSetup = [
  1, 2, 7, 9, 15, 17, 18, 19, 22, 24, 33, 30, 28, 37, 39, 40
];

export const widgetWithFrameImage = [16];

export const widgetWithPartImage = [17, 18];

export const gfmaContent = `<div style={{ padding: '50px', textAlign: 'center' }}>
<h3>
  THIS JOB IS <b>FORWARD MANUFACTURE</b>
</h3>
<br></br>
<h3>
  PLEASE RAISE A GLASS IPF <b>NOT</b> AN ODR
</h3>
<br></br>
<h3>
  HOLD FRAME IN GLAZING AREA - <b>DO NOT</b> SEND TO DESPATCH
</h3>
</div>`;

export const newAndCriticalContent = `
  <h3>New and Critical Customer</h3><h3>Nowy i Krytyczny Klient</h3><h3>Client Nou și Critic</h3>
`;

export const measurables = [
  { id: 1, name: 'Frequency', unit: 'Frame(s)' }
  // { id: 2, name: 'Duration', unit: 'Hour(s)' }
];

export const checkTypes = [
  { id: 1, name: 'EHS' },
  { id: 2, name: 'SIP' },
  { id: 3, name: 'SOS' }
];
