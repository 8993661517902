import { React } from 'react';

export default function StationInfoWidget(props) {
  const station = props.station;

  return (
    <div style={{ fontSize: '12px' }}>
      <b>Station name:</b>
      <p>{station.stationName}</p>
      <b>Start stage:</b>
      <p>
        {station.startStageName} ({station.startStage})
      </p>
      <b>Completed stage:</b>
      <p>
        {station.completedStageName} ({station.completedStage})
      </p>
      <b>Complete action: </b>
      <p>{station.scanningActionName}</p>
      <b>Applied panel: </b>
      <p>{station.appliedPanelName}</p>
    </div>
  );
}
