import axios from 'axios';

const getUserLogsByDate = async (date, userId, stageId) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Tracking/user-logs?date=${date}&userId=${userId}&stageId=${stageId}`
  });

  try {
    return {
      success: true,
      userLogs: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getUserCuttingLogs = async (date, userId, stageId) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Tracking/user-cutting-logs?date=${date}&userId=${userId}&stageId=${stageId}`
  });

  try {
    return {
      success: true,
      userLogs: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const insertCuttingLog = async data => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Tracking/cutting-log`,
    method: 'POST',
    data: data
  });

  try {
    return {
      data: res.data,
      success: res.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const insertTrackingBox = async (trackingbox, ancilaryCutItems) => {
  const data = { trackingbox, ancilaryCutItems };
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Tracking/insert-tracking-box`,
    method: 'POST',
    data: data
  });

  try {
    return {
      data: res.data.data,
      success: res.data.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const insertListCuttingLog = async items => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Tracking/cutting-logs`,
    method: 'POST',
    data: items
  });

  try {
    return {
      data: res.data.data,
      success: res.data.success
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  getUserLogsByDate,
  insertCuttingLog,
  insertTrackingBox,
  insertListCuttingLog,
  getUserCuttingLogs
};
