import axios from 'axios';

const getTapeDetails = async barcode => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Panel/tape-details?barcode=${barcode}`
  );

  try {
    return {
      success: res.data.success,
      tapeType: res.data.tapeType,
      glazingMethod: res.data.glazingMethod
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export { getTapeDetails };
