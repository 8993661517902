import axios from 'axios';

const sendEmail = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/email/send`,
    method: 'POST',
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
    data: payload
  });
  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};
// const sendEmail = async payload => {
//   const res = await axios({
//     url: `${process.env.REACT_APP_EVA_API_HOST}/api/Email/send`,
//     method: 'POST',
//     cache: 'no-cache',
//     headers: {
//       'Cache-Control': 'no-cache',
//       'Content-Type': 'application/json'
//     },
//     data: payload
//   });
//   try {
//     return {
//       success: res.data.success,
//       message: res.data.message
//     };
//   } catch (err) {
//     if (err.response) {
//       // Request made but the server responded with an error
//       console.log('ERR', err);
//       return { message: err.response.data.message, success: false };
//     } else if (err.request) {
//       // Request made but no response is received from the server.
//     } else {
//       // Error occured while setting up the request
//     }
//   }
// };

export { sendEmail };
