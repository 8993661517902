export const isBoxBarcode = barcode => {
  if (barcode.toLowerCase().startsWith('bid')) return true; // barcode uses BID method
  if (barcode.length < 12) return false;
  if (barcode.length >= 12 && parseInt(barcode.substring(0, 12)) === 0)
    return true; // barcode is just a box id
  return false;
};

export const isFrameBarcode = barcode => {
  if (barcode.length < 18) return false;
  return !isBoxBarcode(barcode) && barcode.length === 18;
};

export const isGlassBarcode = barcode => {
  if (barcode.toLowerCase().startsWith('tg')) return true;
  return false;
};

export const isCuidBarcode = barcode => {
  return barcode.toLowerCase().startsWith('cuid');
};

export const isStillageBarcode = barcode => {
  return barcode.toLowerCase().startsWith('stid');
};

export const isValidBarcode = barcode => {
  if (
    barcode &&
    (isFrameBarcode(barcode) ||
      isBoxBarcode(barcode) ||
      isGlassBarcode(barcode) ||
      isStillageBarcode(barcode))
  )
    return true;
  return false;
};

export const isValidBarcodeAndScanByJobNo = barcode => {
  return (
    isValidBarcode(barcode) ||
    isScanByJobNoAndWinNo(barcode) ||
    isScanByJobNoOrStillageNo(barcode)
  );
};

export const isScanByJobNoAndWinNo = barcode => {
  return (
    new RegExp(/[\\_|/]/).test(barcode) && barcode.split(/[\\_|/]/).length === 2
  );
};

export const spitJobNoAndWinNo = string => {
  return string.split(/[\\_|/]/);
};

export const isScanByJobNoOrStillageNo = barcode => {
  return (
    (new RegExp(/[-]/).test(barcode) && barcode.split(/[-]/).length === 2) ||
    new RegExp(/^[0-9]/).test(barcode)
  );
};
