import { swalToast } from 'helpers/component-utils';
import React, { useEffect, useState } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import '../../assets/scss/user.scss';
import {
  getUserScanNoFromLastQc,
  updateTrackingQc
} from 'services/stationQcService';
import { addQcLog } from 'services/qualitycheckService';

export default function DurationQualityCheck(props) {
  // const station = props.station;
  // const loginData = props.loginData;
  // const checks = props.checks;
  // const manual = props.manual;

  // const [currentStep, setCurrentStep] = useState(0);
  // const [currentStepObject, setCurrentStepObject] = useState({});
  // const [currentCheckModal, setCurrentCheckModal] = useState({
  //   index: 0,
  //   check: {
  //     id: 0,
  //     stationId: 0,
  //     checkType: 0,
  //     checkTypeName: '',
  //     measurable: 0,
  //     checkEvery: 0,
  //     createdBy: 0,
  //     created: '',
  //     updatedBy: 0,
  //     updated: '',
  //     steps: []
  //   },
  //   show: true
  // });

  // useEffect(() => {
  //   console.log(manual);
  //   if (manual) {
  //     if (checks.length > 0) {
  //       checks.sort((a, b) => a.id - b.id);
  //       let modalList = [];
  //       checks.map((c, i) => {
  //         let modal = { index: i + 1, check: c, show: true };
  //         modalList.push(modal);
  //       });
  //       setActiveCheckModals(modalList);
  //       setCurrentCheckModal({ ...modalList[0] });
  //       setCurrentStepObject(modalList[0].check.steps[0]);
  //     }
  //   }
  // }, [manual]);

  // useEffect(() => {
  //   if (checks.length > 0) {
  //     let validChecks = checks.filter(x => x.checkEvery > 0);
  //     console.log(validChecks);

  //     if (validChecks.length > 0) {
  //       validChecks.forEach(item => {
  //         const intervalId = setInterval(() => {
  //           // Your logic here
  //           console.log(`Interval for object with id ${item.checkTypeName}`);
  //         }, item.checkEvery * 60 * 1000);

  //         // Clear interval when component unmounts or updates
  //         return () => clearInterval(intervalId);
  //       });
  //     }
  //   }
  // }, []);

  return <></>;
}
