import dayjs from 'dayjs';
import { React } from 'react';

export default function AncillLabel({ cuttingPrint }) {
  return (
    <div
      style={{
        position: 'relative',
        width: '380px',
        height: '143px',
        fontSize: 16,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '-53px',
          transform: 'rotate(90deg)',
          background: '#000',
          color: '#fff',
          borderRadius: '0 0 5px 5px',
          width: '140px',
          height: '33px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold'
        }}
      >
        {dayjs(new Date(cuttingPrint.requiredDate)).format('DD/MM/YYYY')}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          height: '100%',
          margin: '0 40px',
          width: '100%',
          textAlign: 'center'
        }}
      >
        <div style={{ fontWeight: 'bold', color: '#000' }}>
          WinNo: {cuttingPrint.winNo} - {cuttingPrint.locationA}
        </div>
        <div style={{ fontWeight: 'bold', color: '#000' }}>
          {cuttingPrint.pCode} - {cuttingPrint.length1}
        </div>
        <div style={{ fontWeight: 'bold', color: '#000' }}>
          {cuttingPrint.description}
        </div>
        <div className="d-flex justify-content-center">
          <div
            style={{
              textAlign: 'center',
              background: '#000',
              color: '#fff',
              width: 'fit-content',
              padding: '0 25px',
              borderRadius: '5px',
              fontWeight: 'bold'
            }}
          >
            Box {cuttingPrint.boxId}
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          right: '-53px',
          transform: 'rotate(90deg)',
          background: '#000',
          color: '#fff',
          borderRadius: '5px 5px 0 0',
          width: '140px',
          height: '33px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold'
        }}
      >
        {cuttingPrint.jobNo}
      </div>
    </div>
  );
}
