import StationSpinner from 'components/station/common/Spinner';
import { swalToast } from 'helpers/component-utils';
import { React, useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getPartImageSize } from 'redux/slices/widgetSlice';
import { getPartItemsV2 } from 'services/partService';

export default function ImageWidget(props) {
  let barcode = props.barcode;
  let groupName = props.groupName;
  let panelSetupId = props.panelSetupId;
  const [isLoading, setIsLoading] = useState(false);
  const [partItems, setPartItems] = useState([]);
  let itemRows = [];

  const partImgSize = useSelector(store => store.widget.partImageSize);
  console.log(partImgSize);
  const dispatch = useDispatch();
  let width = '';
  let height = '';
  useEffect(() => {
    if (panelSetupId) dispatch(getPartImageSize(panelSetupId));
  }, [panelSetupId, barcode]);

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);
      getPartItemsV2(barcode, groupName, true)
        .then(res => {
          if (res?.success) {
            console.log(res);
            setPartItems(res?.partItems);
            setIsLoading(false);
          } else {
            swalToast.fire({
              icon: 'error',
              title: `Failed to get items.`,
              background: '#d9534f',
              iconColor: '#fff',
              color: '#fff'
            });
          }
        })
        .catch(err => {
          console.log(err);
          swalToast.fire({
            icon: 'error',
            title: `Error. Something went wrong`,
            background: '#d9534f',
            iconColor: '#fff',
            color: '#fff'
          });
        });
    }
  }, [props.barcode]);

  width = partImgSize.width ? partImgSize.width + 'px' : '100px';
  height = partImgSize.height ? partImgSize.height + 'px' : '100px';
  console.log(width, height);

  if (partItems?.length > 0) {
    partItems.forEach(function (item, index) {
      const imgActualPath = item.s3ImagePath
        ? item.s3ImagePath.replace('s3/eva-files/', '')
        : '';

      itemRows.push(
        <tr key={'row' + index}>
          <td>{item.pCode}</td>
          <td>
            {imgActualPath ? (
              <img
                style={{
                  width: width,
                  height: height
                }}
                src={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${process.env.REACT_APP_BUCKET_NAME}?key=${imgActualPath}`}
              ></img>
            ) : (
              'No image uploaded'
            )}
          </td>
        </tr>
      );
    });
  } else {
    itemRows.push(
      <tr key={'row0'}>
        <td colSpan={2}>{'No records found.'}</td>
      </tr>
    );
  }

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>P/Code</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>{itemRows}</tbody>
      </Table>
    </>
  );
}
