import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getStages,
  getPanels,
  getScanningActions
} from 'services/stationService';

import { getFilterGroups, getPartMatchFields } from 'services/filterService';
import { getFaultAreas } from 'services/qcService';

const initialState = {
  stages: [],
  panels: [],
  widgets: [],
  filterGroups: [],
  actions: [],
  partMatchFields: [],
  faultAreas: []
};

export const getStationStages = createAsyncThunk(
  'station/getStationStages',
  async () => {
    try {
      const res = await getStages();
      return res.stages;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getStationPanels = createAsyncThunk(
  'station/getStationPanels',
  async () => {
    try {
      const res = await getPanels();
      return res.panels;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getStationFilterGroups = createAsyncThunk(
  'station/getStationFilterGroups',
  async () => {
    try {
      const res = await getFilterGroups();
      return res.groups;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getStationScanningActions = createAsyncThunk(
  'station/getStationScanningActions',
  async () => {
    try {
      const res = await getScanningActions();
      return res.actions;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getStationPartMatchFields = createAsyncThunk(
  'station/getStationPartMatchFields',
  async () => {
    try {
      const res = await getPartMatchFields();
      return res.matchFields;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getListFaultArea = createAsyncThunk(
  'station/getListFaultArea',
  async () => {
    try {
      const res = await getFaultAreas();
      return res.areas;
    } catch (error) {
      console.log(error);
    }
  }
);

const stationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {},
  extraReducers: {
    [getStationStages.fulfilled]: (state, action) => {
      state.stages = [];
      state.stages.push(...action.payload);
    },
    [getStationPanels.fulfilled]: (state, action) => {
      state.panels = [];
      state.panels.push(...action.payload);
    },
    [getStationFilterGroups.fulfilled]: (state, action) => {
      state.filterGroups = [];
      state.filterGroups.push(...action.payload);
    },
    [getStationScanningActions.fulfilled]: (state, action) => {
      state.actions = [];
      state.actions.push(...action.payload);
    },
    [getStationPartMatchFields.fulfilled]: (state, action) => {
      state.partMatchFields = [];
      state.partMatchFields.push(...action.payload);
    },
    [getListFaultArea.fulfilled]: (state, action) => {
      state.faultAreas = [];
      state.faultAreas.push(...action.payload);
    }
  }
});

// export const { changeCss } = partSlice.actions;

export default stationSlice.reducer;
