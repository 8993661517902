import BarcodeScannerComponent from '@steima/react-qr-barcode-scanner';
import UnauthenticateError from 'components/errors/Unauthenticate';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import StationNavbarTop from 'components/station/navbar/StationNavbarTop';
import AlertWidget from 'components/widgets/AlertWidget';
import CuttingListWidget from 'components/widgets/CuttingListWidget';
import FrameDetailWidget from 'components/widgets/FrameDetail';
import FrameDetailV2Widget from 'components/widgets/FrameDetailV2';
import GlassGeorgianWidget from 'components/widgets/GlassGeorgianLayout';
import GlassPackersWidget from 'components/widgets/GlassPackers';
import GlassPartsWidget from 'components/widgets/GlassPartss';
import ImageWidget from 'components/widgets/ImageWidget';
import PartsWidget from 'components/widgets/Parts';
import PartsWidgetV3 from 'components/widgets/PartsV3';
import PartsV4Widget from 'components/widgets/PartsV4Widget';
import PartsWidgetV5 from 'components/widgets/PartsV5';
import PartsWidgetV6 from 'components/widgets/PartsV6';
import RejectWidget from 'components/widgets/RejectWidget';
import SawCutting from 'components/widgets/SawCutting';
import StationInfoWidget from 'components/widgets/StationInfo';
import TapeDetailWidget from 'components/widgets/TapeDetail';
import TrackingGlassWidget from 'components/widgets/TrackingGlass';
import UserLogWidget from 'components/widgets/UserLogWiget';
import AppContext from 'context/Context';
import {
  isCuidBarcode,
  isScanByJobNoAndWinNo,
  isScanByJobNoOrStillageNo,
  isValidBarcode,
  isValidBarcodeAndScanByJobNo,
  spitJobNoAndWinNo
} from 'helpers/barcode-utils';
import {
  swalToastError,
  swalToastInfo,
  swalToastSuccess,
  swalToastWarning
} from 'helpers/component-utils';
import { getNextPosition } from 'helpers/utils';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import ReactGridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { GlobalHotKeys, configure } from 'react-hotkeys';
import { useDispatch, useSelector } from 'react-redux';
import 'react-resizable/css/styles.css';
import { useParams } from 'react-router-dom';
import { getPermission } from 'redux/slices/permissionSlice';
import { getStationPanels } from 'redux/slices/stationSlice';
import { completeBatch, moveToProductionStage } from 'services/cuttingService';
import {
  completeScanFrame,
  completeStillageScan,
  getHeaderIdAndFrameId
} from 'services/frameService';
import { getQualityChecksByStation } from 'services/stationQcService';
import {
  getStationByPath,
  getStationLayout,
  updatePanelLockMode,
  updateStationLayout
} from 'services/stationService';
import { extendSession, loginByBarcode } from 'services/userService';
import CuttingListDetail from './CuttingListDetail';
import DurationQualityCheck from './DurationQualityCheck';
import GlazingForwardManuAlert from './GlazingForwardManuAlert';
import NewAndCriticalAlert from './NewAndCriticalAlert';
import Panel from './Panel';
import ProductCodeAlert from './ProductCodeAlert';
import QualityCheck from './QualityCheck';
import StartUpCheck from './StartUpCheck';
import StationFooter from './footer/StationFooter';
import ProductChecking from 'components/widgets/ProductChecking';
import PartsWidgetV7 from 'components/widgets/PartsV7';
import PatioWarning from './PatioWarning';
import SashLocatorWidget from 'components/widgets/SashLocatorWidget';
import {
  clearSashLocation,
  getEnabledClearSash
} from 'services/sashLocatorService';
import ProductCheckingWarning from './ProductCheckingWarning';
import WrapingMachine from 'components/widgets/WrapingWidget';
import FrameDetailV3 from 'components/widgets/FrameDetailV3';
import AncillPackChecking from 'components/widgets/AncillPackChecking';
import WeldedCillChecking from 'components/widgets/WeldedCillChecking';
import HardwareBoxesChecking from 'components/widgets/HardwareBoxesChecking';
import GlassChecking from 'components/widgets/GlassChecking';
import StillageChecking from 'components/widgets/StillageChecking';
import WeldedCillDetail from 'components/widgets/WeldedCillDetail';
import GlassDetail from 'components/widgets/GlassDetail';
import StillageDetail from 'components/widgets/StillageDetail';
import ODRChecking from 'components/widgets/OdrProcessed';
import PartsWidgetV8 from 'components/widgets/PartsV8';
import UserLogV2 from 'components/widgets/UserLogV2Widget';
import PartsWidgetV9 from 'components/widgets/PartsV9';
import PartsWidgetV10 from 'components/widgets/PartsV10';
import FrameDetailV4 from 'components/widgets/FrameDetailV4';

const defaultLoginData = {
  isLogged: false,
  userId: 0,
  userName: 'Not Logged In',
  realName: 'N A',
  lastLogin: 0,
  permissionId: 0
};

export default function HomeStation() {
  const {
    config: { showSettingPanel },
    setConfig
  } = useContext(AppContext);

  const innerWidth = window.innerWidth;

  const ref = useRef(true);
  const params = useParams();
  const dispatch = useDispatch();
  const dbPanels = useSelector(store => store.station.panels);

  const [station, setStation] = useState({});
  const [layoutFromDb, setLayoutFromDb] = useState([]);
  const [latestLayout, setLatestLayout] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [removedPanelId, setRemovedPanelId] = useState(0);
  const [layoutForSetting, setLayoutForSetting] = useState([]);
  const [loginData, setLoginData] = useState(defaultLoginData);
  const [isScanning, setIsScanning] = useState(false);
  const [stationPermission, setStationPermission] = useState('');
  const [batchData, setBatchData] = useState({});

  //panel data params
  const [scannedBarcode, setScannedBarcode] = useState(0);
  const [actualScannedBarcode, setActualScannedBarcode] = useState(0);
  const [scanCompleted, setScanCompleted] = useState(false);
  const [userTrackingId, setUserTrackingId] = useState(0);

  const [manualStartUpCheck, setManualStartUpCheck] = useState(false);
  const [manualQualityCheck, setManualQualityCheck] = useState(false);
  const [frameScanCompleted, setFrameScanCompleted] = useState(false);
  const [qualityChecks, setQualityChecks] = useState([]);
  const [checkType, setCheckType] = useState('');

  const [showModalGFMA, setShowModalGFMA] = useState(false); // Glazing Forward Manu Alert
  const [showNewCriticalAlert, setShowNewCriticalAlert] = useState(false); // New & Critical Alert
  const [isShowStatupCheck, setIsShowStatupCheck] = useState(false);
  const [isShowQCCheck, setIsShowQCCheck] = useState(false);
  const [isCodeChanged, setIsCodeChanged] = useState(false);
  const [diff, setDiff] = useState([]);
  const [queue, setQueue] = useState([]);
  const [cameraCode, setCameraCode] = useState();
  const [isShowCamera, setIsShowCamera] = useState(false);
  const [isShowCuttingDetail, setIsShowCuttingDetail] = useState(false);
  const [currentCuttingDetail, setCurrentCuttingDetail] = useState({});
  const [titleCuttingList, setTitleCuttingList] = useState('');
  const [isShowPatioWarning, setIsShowPatioWarning] = useState(false);

  const [isShowCuttingDetailV2, setIsShowCuttingDetailV2] = useState(false);
  const [currentCuttingDetailV2, setCurrentCuttingDetailV2] = useState({});
  const [titleCuttingListV2, setTitleCuttingListV2] = useState('');
  const [isShowSubmitedProductChecking, setIsShowSubmitedProductChecking] =
    useState(false);
  const [isPassedProductChecking, setIsPassedProductChecking] = useState(true);
  const [LastUserpermission, setLastUserPermission] = useState(0);
  const [productCompleted, setProductCompleted] = useState('');

  const PopDataOfQueue = (key, callback) => {
    const lastItem = queue[queue.length - 1];
    if (lastItem && lastItem === key) {
      callback(true);
    }
  };

  const updateQueue = async value => {
    if (Array.isArray(value)) {
      await setQueue([...value]);
    } else {
      await setQueue(prevState => {
        return [value, ...prevState];
      });
    }
  };

  let debounceTime = 10;
  let panels = [];

  const keyMap = {
    EV_LOGOUT: 'ctrl+alt+0',
    EV_EXTEND: 'ctrl+alt+9',
    EV_PANELS: 'f4'
  };

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    ignoreEventsCondition: function () {}
  });

  const logout = () => {
    localStorage.removeItem('login-data');
    setLoginData(defaultLoginData);

    //close all modal is showing
    setQueue([]);
    setConfig('showSettingPanel', false);
    setShowModalGFMA(false);
    setShowNewCriticalAlert(false);
    setIsShowQCCheck(false);
    setIsShowStatupCheck(false);
    setIsCodeChanged(false);
    setIsShowCuttingDetail(false);
    setIsShowPatioWarning(false);
    setIsShowSubmitedProductChecking(false);
  };

  const keyMapHandlers = {
    EV_LOGOUT: event => {
      logout();
    },
    EV_EXTEND: event => {
      onExtendSession();
    },

    EV_PANELS: event => {
      openAvailablePanels();
    }
  };

  const onSessionExpired = () => {
    logout();
    tryFocus();
  };

  const onAutoExpandSession = () => {
    onExtendSession();
  };

  const onExtendSession = () => {
    if (!loginData.isLogged) {
      return;
    }
    extendSession(loginData.userId, loginData.sessionId).then(res => {
      const loggedData = {
        ...loginData,
        lastLogin: res.user?.lastLogin
      };

      setLoginData(prevState => {
        return Object.assign({}, prevState, loggedData);
      });
      localStorage.setItem('login-data', JSON.stringify(loggedData));

      swalToastSuccess('Session has been extended');
    });
  };

  const openAvailablePanels = () => {
    if (showSettingPanel) setConfig('showSettingPanel', false);
    else {
      setConfig('showSettingPanel', true);
    }
  };

  const tryFocus = () => {
    setTimeout(() => {
      document.getElementById('barcodeInput').focus();
    }, 200);
  };

  useEffect(() => {
    const handleTabClose = event => {
      event.stopPropagation();
      logout();
      return;
    };
    window.addEventListener('unload', handleTabClose);
    return () => {
      window.removeEventListener('unload ', handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (queue.length === 0) {
      tryFocus();
    }
  }, [
    showNewCriticalAlert,
    showModalGFMA,
    isShowStatupCheck,
    isShowQCCheck,
    isCodeChanged
  ]);

  useEffect(() => {
    dispatch(getStationPanels());

    // check for session logged data if exists
    let loggedData = JSON.parse(localStorage.getItem('login-data'));

    if (loggedData) {
      setLoginData({ ...loginData, ...loggedData });
    }
  }, []);

  useEffect(() => {
    if (removedPanelId > 0) {
      let layoutAfterRemoved = layoutFromDb.filter(function (item, index, arr) {
        return item.key != removedPanelId;
      });

      setLayoutFromDb([...layoutAfterRemoved]);
    }
  }, [removedPanelId]);

  useEffect(() => {
    getStationByPath(params.stationPath).then(res => {
      setStation({ ...res.station });
      if (loginData.isLogged) {
        localStorage.setItem('station-id', res.station.id);
        getQualityChecksByStation(res.station.id).then(res => {
          if (res?.qualityChecks.length > 0) {
            const measure = res?.qualityChecks[0].measurable;
            if (measure === 1) {
              setCheckType('freqency');
            } else if (measure === 2) {
              setCheckType('duration');
            }
            setQualityChecks(res.qualityChecks);
          }
        });
        getStationLayout(res?.station?.id ?? 0).then(res1 => {
          setLayoutForSetting(res1.layout);

          if (res1.layout.length > 0) {
            res1.layout.forEach(function (item, index) {
              let panelBody = '';
              let isWorking = station.appliedPanelId == item.panelId;

              switch (item.panelId) {
                case 1:
                  panelBody = (
                    <PartsWidget
                      queue={queue}
                      setQueue={setQueue}
                      updateQueue={updateQueue}
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                      setIsCodeChanged={setIsCodeChanged}
                      setDiff={setDiff}
                    ></PartsWidget>
                  );
                  break;
                case 2:
                  panelBody = (
                    <GlassPackersWidget
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                    ></GlassPackersWidget>
                  );
                  break;
                case 3:
                  panelBody = (
                    <FrameDetailWidget
                      queue={queue}
                      setQueue={setQueue}
                      updateQueue={updateQueue}
                      showModalGFMA={showModalGFMA}
                      showNewCriticalAlert={showNewCriticalAlert}
                      setShowModalGFMA={setShowModalGFMA}
                      setShowNewCriticalAlert={setShowNewCriticalAlert}
                      barcode={scannedBarcode}
                      startStage={station.startStage}
                      isWorking={isWorking}
                      userId={loginData.userId}
                      stationId={res.station.id}
                      showFrameWeight={item.frameWeightDisplayed}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      setIsShowPatioWarning={setIsShowPatioWarning}
                      setProductCompleted={setProductCompleted}
                      setScannedBarcode={setScannedBarcode}
                      setIsScanning={setIsScanning}
                    />
                  );
                  break;
                case 4:
                  panelBody = (
                    <StationInfoWidget
                      station={res.station}
                    ></StationInfoWidget>
                  );
                  break;
                case 5:
                  panelBody = (
                    <SawCutting
                      barcode={scannedBarcode}
                      onStartBatch={handleStartSawBatch}
                      startStage={station.startStage}
                      completedStage={station.completedStage}
                      isWorking={isWorking}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      userId={loginData.userId}
                    ></SawCutting>
                  );
                  break;
                case 7:
                  panelBody = (
                    <PartsWidgetV3
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                    ></PartsWidgetV3>
                  );
                  break;
                case 8:
                  panelBody = (
                    <TapeDetailWidget
                      barcode={scannedBarcode}
                    ></TapeDetailWidget>
                  );
                  break;
                case 9:
                  panelBody = (
                    <GlassPartsWidget
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                    ></GlassPartsWidget>
                  );
                  break;
                case 10:
                  panelBody = (
                    <TrackingGlassWidget
                      barcode={scannedBarcode}
                    ></TrackingGlassWidget>
                  );
                  break;
                case 11:
                  panelBody = (
                    <GlassGeorgianWidget
                      barcode={scannedBarcode}
                    ></GlassGeorgianWidget>
                  );
                  break;
                case 12:
                  panelBody = (
                    <RejectWidget
                      barcode={scannedBarcode}
                      userId={loginData.userId}
                      userName={loginData.realName}
                      permissionId={loginData.permissionId}
                    ></RejectWidget>
                  );
                  break;
                case 13:
                  panelBody = (
                    <AlertWidget
                      stageId={station.completedStage}
                      stageName={station.startStageName}
                      station={res.station}
                      userId={loginData.userId}
                      userName={loginData.userName}
                    ></AlertWidget>
                  );
                  break;
                case 14:
                  panelBody = (
                    <UserLogWidget
                      station={res.station}
                      userId={loginData.userId}
                      realName={loginData.realName}
                    ></UserLogWidget>
                  );
                  break;
                case 15:
                  panelBody = (
                    <PartsV4Widget
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                    ></PartsV4Widget>
                  );
                  break;
                case 16:
                  panelBody = (
                    <FrameDetailV2Widget
                      queue={queue}
                      setQueue={setQueue}
                      updateQueue={updateQueue}
                      showModalGFMA={showModalGFMA}
                      showNewCriticalAlert={showNewCriticalAlert}
                      setShowModalGFMA={setShowModalGFMA}
                      setShowNewCriticalAlert={setShowNewCriticalAlert}
                      barcode={scannedBarcode}
                      panelSetupId={item.id}
                      startStage={station.startStage}
                      userId={loginData.userId}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      setIsShowPatioWarning={setIsShowPatioWarning}
                      setProductCompleted={setProductCompleted}
                      setScannedBarcode={setScannedBarcode}
                      setIsScanning={setIsScanning}
                    />
                  );
                  break;
                case 17:
                  panelBody = (
                    <ImageWidget
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                      panelSetupId={item.id}
                    ></ImageWidget>
                  );
                  break;
                case 18:
                  panelBody = (
                    <PartsWidgetV5
                      queue={queue}
                      setQueue={setQueue}
                      updateQueue={updateQueue}
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                      panelSetupId={item.id}
                      setIsCodeChanged={setIsCodeChanged}
                      setDiff={setDiff}
                    ></PartsWidgetV5>
                  );
                  break;
                case 19:
                  panelBody = (
                    <PartsWidgetV6
                      queue={queue}
                      setQueue={setQueue}
                      updateQueue={updateQueue}
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                      panelSetupId={item.id}
                      setIsCodeChanged={setIsCodeChanged}
                      setDiff={setDiff}
                    ></PartsWidgetV6>
                  );
                  break;
                case 20:
                  panelBody = (
                    <CuttingListWidget
                      setCurrentCuttingDetail={setCurrentCuttingDetail}
                      setIsShowCuttingDetail={setIsShowCuttingDetail}
                      setTitleCuttingList={setTitleCuttingList}
                    ></CuttingListWidget>
                  );
                  break;
                case 21:
                  panelBody = (
                    <ProductChecking
                      queue={queue}
                      setQueue={setQueue}
                      updateQueue={updateQueue}
                      setIsShowSubmitedProductChecking={
                        setIsShowSubmitedProductChecking
                      }
                      barcode={scannedBarcode}
                      setIsPassedProductChecking={setIsPassedProductChecking}
                    ></ProductChecking>
                  );
                  break;
                case 22:
                  panelBody = (
                    <PartsWidgetV7
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                      panelSetupId={item.id}
                    ></PartsWidgetV7>
                  );
                  break;
                case 23:
                  panelBody = (
                    <SashLocatorWidget
                      barcode={scannedBarcode}
                      onScanCompleted={handleScanCompleted}
                      permissionId={loginData.permissionId}
                      stationId={res.station.id}
                      panelId={item.panelId}
                    ></SashLocatorWidget>
                  );
                  break;
                case 25:
                  panelBody = (
                    <WrapingMachine barcode={scannedBarcode}></WrapingMachine>
                  );
                  break;
                case 26:
                  panelBody = (
                    <FrameDetailV3
                      queue={queue}
                      setQueue={setQueue}
                      updateQueue={updateQueue}
                      showModalGFMA={showModalGFMA}
                      showNewCriticalAlert={showNewCriticalAlert}
                      setShowModalGFMA={setShowModalGFMA}
                      setShowNewCriticalAlert={setShowNewCriticalAlert}
                      barcode={scannedBarcode}
                      startStage={station.startStage}
                      isWorking={isWorking}
                      userId={loginData.userId}
                      stationId={res.station.id}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      setIsShowPatioWarning={setIsShowPatioWarning}
                      setProductCompleted={setProductCompleted}
                      setScannedBarcode={setScannedBarcode}
                      setIsScanning={setIsScanning}
                    />
                  );
                  break;
                case 28:
                  panelBody = (
                    <AncillPackChecking
                      barcode={scannedBarcode}
                      startStage={station.startStage}
                      userId={loginData.userId}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      setProductCompleted={setProductCompleted}
                      groupName={item.filterGroup}
                    />
                  );
                  break;
                case 29:
                  panelBody = <WeldedCillChecking barcode={scannedBarcode} />;
                  break;
                case 30:
                  panelBody = (
                    <HardwareBoxesChecking
                      barcode={scannedBarcode}
                      startStage={station.startStage}
                      userId={loginData.userId}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      setProductCompleted={setProductCompleted}
                      groupName={item.filterGroup}
                    />
                  );
                  break;
                case 31:
                  panelBody = <GlassChecking barcode={scannedBarcode} />;
                  break;
                case 32:
                  panelBody = <StillageChecking barcode={scannedBarcode} />;
                  break;
                case 33:
                  panelBody = (
                    <WeldedCillDetail
                      barcode={scannedBarcode}
                      startStage={station.startStage}
                      userId={loginData.userId}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      groupName={item.filterGroup}
                      setProductCompleted={setProductCompleted}
                    />
                  );
                  break;
                case 34:
                  panelBody = (
                    <GlassDetail
                      barcode={scannedBarcode}
                      startStage={station.startStage}
                      userId={loginData.userId}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      setProductCompleted={setProductCompleted}
                    />
                  );
                  break;
                case 35:
                  panelBody = (
                    <StillageDetail
                      barcode={scannedBarcode}
                      startStage={station.startStage}
                      userId={loginData.userId}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      setProductCompleted={setProductCompleted}
                    />
                  );
                  break;
                case 36:
                  panelBody = (
                    <ODRChecking
                      barcode={scannedBarcode}
                      startStage={station.startStage}
                      userId={loginData.userId}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      setProductCompleted={setProductCompleted}
                    />
                  );
                  break;
                case 37:
                  panelBody = (
                    <PartsWidgetV8
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                      panelSetupId={item.id}
                    ></PartsWidgetV8>
                  );
                  break;
                case 38:
                  panelBody = (
                    <UserLogV2
                      completedStage={station.completedStage}
                      userId={loginData.userId}
                      realName={loginData.realName}
                    />
                  );
                  break;
                case 39:
                  panelBody = (
                    <PartsWidgetV9
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                      panelSetupId={item.id}
                    />
                  );
                  break;
                case 40:
                  panelBody = (
                    <PartsWidgetV10
                      barcode={scannedBarcode}
                      groupName={item.filterGroup}
                      panelSetupId={item.id}
                    />
                  );
                  break;
                case 41:
                  panelBody = (
                    <FrameDetailV4
                      queue={queue}
                      setQueue={setQueue}
                      updateQueue={updateQueue}
                      showModalGFMA={showModalGFMA}
                      showNewCriticalAlert={showNewCriticalAlert}
                      setShowModalGFMA={setShowModalGFMA}
                      setShowNewCriticalAlert={setShowNewCriticalAlert}
                      barcode={scannedBarcode}
                      startStage={station.startStage}
                      isWorking={isWorking}
                      userId={loginData.userId}
                      stationId={res.station.id}
                      onScanStarted={handleScanStarted}
                      onScanCompleted={handleScanCompleted}
                      setIsShowPatioWarning={setIsShowPatioWarning}
                      setProductCompleted={setProductCompleted}
                      setScannedBarcode={setScannedBarcode}
                      setIsScanning={setIsScanning}
                    />
                  );
                  break;
              }
              panels.push(
                <Panel
                  key={item.id}
                  title={item.panelTitle}
                  titleColour={item.panelTitleColour}
                  fontSize={item.fontSize}
                  data-grid={{
                    x: item.x,
                    y: item.y,
                    w: item.w,
                    h: item.h,
                    static: item.static,
                    minW: 7
                  }}
                  station={res.station}
                  panelSetupId={item.id}
                  panelId={item.panelId}
                  body={panelBody}
                  isPinned={item.static}
                  isMain={item.isMainPanel}
                  filterGroup={item.filterGroup}
                  onRemovePanel={removePanelFromLayout}
                  onLockPanel={handleChangeLockMode}
                  barcode={scannedBarcode}
                  showFrameWeight={item.frameWeightDisplayed}
                ></Panel>
              );
            });

            setLayoutFromDb([...panels]);
          }
        });
      }
    });
  }, [scannedBarcode, loginData]);

  useEffect(() => {
    if (loginData.userId > 0) {
      setLayoutForSetting(latestLayout);
      let updatedPanels = [];

      if (latestLayout.length > 0) {
        latestLayout.forEach(function (item, index) {
          let panelBody = '';
          let isWorking = station.appliedPanelId == item.panelId;
          let stationId = localStorage.getItem('station-id');

          switch (item.panelId) {
            case 1:
              panelBody = (
                <PartsWidget
                  queue={queue}
                  setQueue={setQueue}
                  updateQueue={updateQueue}
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                  setIsCodeChanged={setIsCodeChanged}
                  setDiff={setDiff}
                ></PartsWidget>
              );
              break;
            case 2:
              panelBody = (
                <GlassPackersWidget
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                ></GlassPackersWidget>
              );
              break;
            case 3:
              panelBody = (
                <FrameDetailWidget
                  queue={queue}
                  setQueue={setQueue}
                  updateQueue={updateQueue}
                  showModalGFMA={showModalGFMA}
                  showNewCriticalAlert={showNewCriticalAlert}
                  setShowModalGFMA={setShowModalGFMA}
                  setShowNewCriticalAlert={setShowNewCriticalAlert}
                  barcode={scannedBarcode}
                  startStage={station.startStage}
                  isWorking={isWorking}
                  userId={loginData.userId}
                  stationId={stationId}
                  showFrameWeight={item.frameWeightDisplayed}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  setIsShowPatioWarning={setIsShowPatioWarning}
                  setProductCompleted={setProductCompleted}
                  setScannedBarcode={setScannedBarcode}
                  setIsScanning={setIsScanning}
                />
              );
              break;
            case 4:
              panelBody = (
                <StationInfoWidget station={station}></StationInfoWidget>
              );
              break;
            case 5:
              panelBody = (
                <SawCutting
                  barcode={scannedBarcode}
                  onStartBatch={handleStartSawBatch}
                  startStage={station.startStage}
                  completedStage={station.completedStage}
                  isWorking={isWorking}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  userId={loginData.userId}
                ></SawCutting>
              );
              break;
            case 7:
              panelBody = (
                <PartsWidgetV3
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                ></PartsWidgetV3>
              );
              break;
            case 8:
              panelBody = (
                <TapeDetailWidget barcode={scannedBarcode}></TapeDetailWidget>
              );
              break;
            case 9:
              panelBody = (
                <GlassPartsWidget
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                ></GlassPartsWidget>
              );
              break;
            case 10:
              panelBody = (
                <TrackingGlassWidget
                  barcode={scannedBarcode}
                ></TrackingGlassWidget>
              );
              break;
            case 11:
              panelBody = (
                <GlassGeorgianWidget
                  barcode={scannedBarcode}
                ></GlassGeorgianWidget>
              );
              break;
            case 12:
              panelBody = (
                <RejectWidget
                  barcode={scannedBarcode}
                  userId={loginData.userId}
                  userName={loginData.realName}
                  permissionId={loginData.permissionId}
                ></RejectWidget>
              );
              break;
            case 13:
              panelBody = (
                <AlertWidget
                  stageId={station.completedStage}
                  stageName={station.startStageName}
                  station={station}
                  userId={loginData.userId}
                  userName={loginData.userName}
                ></AlertWidget>
              );
              break;
            case 14:
              panelBody = (
                <UserLogWidget
                  station={station}
                  userId={loginData.userId}
                  realName={loginData.realName}
                ></UserLogWidget>
              );

              break;
            case 15:
              panelBody = (
                <PartsV4Widget
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                ></PartsV4Widget>
              );
              break;
            case 16:
              panelBody = (
                <FrameDetailV2Widget
                  queue={queue}
                  setQueue={setQueue}
                  updateQueue={updateQueue}
                  showModalGFMA={showModalGFMA}
                  showNewCriticalAlert={showNewCriticalAlert}
                  setShowModalGFMA={setShowModalGFMA}
                  setShowNewCriticalAlert={setShowNewCriticalAlert}
                  barcode={scannedBarcode}
                  panelSetupId={item.id}
                  startStage={station.startStage}
                  userId={loginData.userId}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  setIsShowPatioWarning={setIsShowPatioWarning}
                  setProductCompleted={setProductCompleted}
                  setScannedBarcode={setScannedBarcode}
                  setIsScanning={setIsScanning}
                />
              );
              break;
            case 17:
              panelBody = (
                <ImageWidget
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                  panelSetupId={item.id}
                ></ImageWidget>
              );
              break;
            case 18:
              panelBody = (
                <PartsWidgetV5
                  queue={queue}
                  setQueue={setQueue}
                  updateQueue={updateQueue}
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                  panelSetupId={item.id}
                  setIsCodeChanged={setIsCodeChanged}
                  setDiff={setDiff}
                ></PartsWidgetV5>
              );
              break;
            case 19:
              panelBody = (
                <PartsWidgetV6
                  queue={queue}
                  setQueue={setQueue}
                  updateQueue={updateQueue}
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                  panelSetupId={item.id}
                  setIsCodeChanged={setIsCodeChanged}
                  setDiff={setDiff}
                ></PartsWidgetV6>
              );
              break;
            case 20:
              panelBody = (
                <CuttingListWidget
                  setCurrentCuttingDetail={setCurrentCuttingDetail}
                  setIsShowCuttingDetail={setIsShowCuttingDetail}
                  setTitleCuttingList={setTitleCuttingList}
                ></CuttingListWidget>
              );
              break;
            case 21:
              panelBody = (
                <ProductChecking
                  queue={queue}
                  setQueue={setQueue}
                  updateQueue={updateQueue}
                  setIsShowSubmitedProductChecking={
                    setIsShowSubmitedProductChecking
                  }
                  barcode={scannedBarcode}
                  setIsPassedProductChecking={setIsPassedProductChecking}
                ></ProductChecking>
              );
              break;
            case 22:
              panelBody = (
                <PartsWidgetV7
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                  panelSetupId={item.id}
                ></PartsWidgetV7>
              );
              break;
            case 23:
              panelBody = (
                <SashLocatorWidget
                  barcode={scannedBarcode}
                  onScanCompleted={handleScanCompleted}
                  permissionId={loginData.permissionId}
                  stationId={station.id}
                  panelId={item.panelId}
                ></SashLocatorWidget>
              );
              break;
            case 25:
              panelBody = (
                <WrapingMachine barcode={scannedBarcode}></WrapingMachine>
              );
              break;
            case 26:
              panelBody = (
                <FrameDetailV3
                  queue={queue}
                  setQueue={setQueue}
                  updateQueue={updateQueue}
                  showModalGFMA={showModalGFMA}
                  showNewCriticalAlert={showNewCriticalAlert}
                  setShowModalGFMA={setShowModalGFMA}
                  setShowNewCriticalAlert={setShowNewCriticalAlert}
                  barcode={scannedBarcode}
                  startStage={station.startStage}
                  isWorking={isWorking}
                  userId={loginData.userId}
                  stationId={stationId}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  setIsShowPatioWarning={setIsShowPatioWarning}
                  setProductCompleted={setProductCompleted}
                  setScannedBarcode={setScannedBarcode}
                  setIsScanning={setIsScanning}
                />
              );
              break;
            case 28:
              panelBody = (
                <AncillPackChecking
                  barcode={scannedBarcode}
                  startStage={station.startStage}
                  userId={loginData.userId}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  groupName={item.filterGroup}
                />
              );
              break;
            case 29:
              panelBody = <WeldedCillChecking barcode={scannedBarcode} />;
              break;
            case 30:
              panelBody = (
                <HardwareBoxesChecking
                  barcode={scannedBarcode}
                  startStage={station.startStage}
                  userId={loginData.userId}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  setProductCompleted={setProductCompleted}
                  groupName={item.filterGroup}
                />
              );
              break;
            case 31:
              panelBody = <GlassChecking barcode={scannedBarcode} />;
              break;
            case 32:
              panelBody = <StillageChecking barcode={scannedBarcode} />;
              break;
            case 33:
              panelBody = (
                <WeldedCillDetail
                  barcode={scannedBarcode}
                  startStage={station.startStage}
                  userId={loginData.userId}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  groupName={item.filterGroup}
                  setProductCompleted={setProductCompleted}
                />
              );
              break;
            case 34:
              panelBody = (
                <GlassDetail
                  barcode={scannedBarcode}
                  startStage={station.startStage}
                  userId={loginData.userId}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  setProductCompleted={setProductCompleted}
                />
              );
              break;
            case 35:
              panelBody = (
                <StillageDetail
                  barcode={scannedBarcode}
                  startStage={station.startStage}
                  userId={loginData.userId}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  setProductCompleted={setProductCompleted}
                />
              );
              break;
            case 36:
              panelBody = (
                <ODRChecking
                  barcode={scannedBarcode}
                  startStage={station.startStage}
                  userId={loginData.userId}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  setProductCompleted={setProductCompleted}
                />
              );
              break;
            case 37:
              panelBody = (
                <PartsWidgetV8
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                  panelSetupId={item.id}
                ></PartsWidgetV8>
              );
              break;
            case 38:
              panelBody = (
                <UserLogV2
                  completedStage={station.completedStage}
                  userId={loginData.userId}
                  realName={loginData.realName}
                />
              );
              break;
            case 39:
              panelBody = (
                <PartsWidgetV9
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                  panelSetupId={item.id}
                />
              );
              break;
            case 40:
              panelBody = (
                <PartsWidgetV10
                  barcode={scannedBarcode}
                  groupName={item.filterGroup}
                  panelSetupId={item.id}
                />
              );
              break;
            case 41:
              panelBody = (
                <FrameDetailV4
                  queue={queue}
                  setQueue={setQueue}
                  updateQueue={updateQueue}
                  showModalGFMA={showModalGFMA}
                  showNewCriticalAlert={showNewCriticalAlert}
                  setShowModalGFMA={setShowModalGFMA}
                  setShowNewCriticalAlert={setShowNewCriticalAlert}
                  barcode={scannedBarcode}
                  startStage={station.startStage}
                  isWorking={isWorking}
                  userId={loginData.userId}
                  stationId={stationId}
                  onScanStarted={handleScanStarted}
                  onScanCompleted={handleScanCompleted}
                  setIsShowPatioWarning={setIsShowPatioWarning}
                  setProductCompleted={setProductCompleted}
                  setScannedBarcode={setScannedBarcode}
                  setIsScanning={setIsScanning}
                />
              );
              break;
          }
          updatedPanels.push(
            <Panel
              key={item.id}
              title={item.panelTitle}
              data-grid={{
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
                static: item.static,
                minW: 7
              }}
              station={station}
              panelSetupId={item.id}
              panelId={item.panelId}
              body={panelBody}
              isPinned={item.static}
              filterGroup={item.filterGroup}
              onRemovePanel={removePanelFromLayout}
              onLockPanel={handleChangeLockMode}
              onEditSetup={handleEditPanelSetup}
              barcode={scannedBarcode}
              showFrameWeight={item.frameWeightDisplayed}
            ></Panel>
          );
        });

        setLayoutFromDb([...updatedPanels]);
      }
    }
  }, [latestLayout]);

  // saving
  const saveLayout = lly => {
    let newMode = 'update';

    let stationId = localStorage.getItem('station-id');

    if (stationId) {
      getStationLayout(stationId).then(res => {
        if (lly.length < res.layout.length) {
          newMode = 'remove';
        }

        if (lly?.length > 0) {
          lly.forEach(function (item) {
            if (!isNaN(item.i)) {
              item.Id = item.i;
            } else {
              let id = Number(item.i.match(/\d+/)[0]);
              let title = item.i.replace(id, '');

              item.PanelTitle = title;
              item.Id = 0;
              item.PanelId = id;
              item.StationId = stationId;
            }
          });
        }

        updateStationLayout(stationId, lly, newMode)
          .then(res => {
            if (res?.success) {
              getStationLayout(stationId).then(res => {
                setLatestLayout(res.layout);
              });
            } else {
              swalToastError('Failed to update station layout');
            }
          })
          .catch(err => {
            swalToastError('Error. Something went wrong');
          });
      });
    }
  };

  useEffect(() => {
    setLayoutForSetting(layoutForSetting);
  }, [layoutForSetting]);

  const debounceSaveLayout = useCallback(
    debounce(ly => saveLayout(ly), debounceTime),
    []
  );

  const handleChangeLockMode = payload => {
    updatePanelLockMode(payload.id, payload.nextMode).then(res => {
      if (res?.success) {
        swalToastSuccess('Lock mode changed');

        setTimeout(() => {
          location.reload();
        }, 1000);
      }
    });
  };

  const onLayoutChange = layout => {
    const firstRender = ref.current;

    if (firstRender && layout.length === layoutForSetting.length) {
      ref.current = false;
    } else {
      debounceSaveLayout(layout);
    }
  };

  const showHidePanelSetup = () => {
    setCollapsed(!collapsed);
  };

  const addPanelToLayout = panelData => {
    let existedPanels = [...layoutForSetting];
    debounceTime = 200;

    let existed =
      existedPanels.find(el => el.panelId == panelData.id) != undefined;

    if (!existed) {
      let nextPositionItem = getNextPosition(latestLayout);

      let cxx = [...layoutFromDb];

      let panelBody = '';
      let isWorking = station.appliedPanelId == panelData.id;
      let stationId = localStorage.getItem('station-id');

      switch (panelData.id) {
        case 1:
          panelBody = (
            <PartsWidget
              queue={queue}
              setQueue={setQueue}
              updateQueue={updateQueue}
              barcode={scannedBarcode}
              groupName={panelData.filterGroupName}
              setIsCodeChanged={setIsCodeChanged}
              setDiff={setDiff}
            ></PartsWidget>
          );
          break;
        case 2:
          panelBody = (
            <GlassPackersWidget
              barcode={scannedBarcode}
              groupName={panelData.filterGroupName}
            ></GlassPackersWidget>
          );
          break;
        case 3:
          panelBody = (
            <FrameDetailWidget
              queue={queue}
              setQueue={setQueue}
              updateQueue={updateQueue}
              showModalGFMA={showModalGFMA}
              showNewCriticalAlert={showNewCriticalAlert}
              setShowModalGFMA={setShowModalGFMA}
              setShowNewCriticalAlert={setShowNewCriticalAlert}
              barcode={scannedBarcode}
              startStage={station.startStage}
              isWorking={isWorking}
              userId={loginData.userId}
              stationId={stationId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              setIsShowPatioWarning={setIsShowPatioWarning}
              setProductCompleted={setProductCompleted}
              setScannedBarcode={setScannedBarcode}
              setIsScanning={setIsScanning}
            />
          );
          break;
        case 4:
          panelBody = <StationInfoWidget station={station}></StationInfoWidget>;
          break;
        case 5:
          panelBody = (
            <SawCutting
              barcode={scannedBarcode}
              onStartBatch={handleStartSawBatch}
              startStage={station.startStage}
              completedStage={station.completedStage}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              userId={loginData.userId}
            ></SawCutting>
          );
          break;
        case 7:
          panelBody = (
            <PartsWidgetV3
              barcode={scannedBarcode}
              groupName={panelData.filterGroupName}
            ></PartsWidgetV3>
          );
          break;
        case 8:
          panelBody = (
            <TapeDetailWidget barcode={scannedBarcode}></TapeDetailWidget>
          );
          break;
        case 9:
          panelBody = (
            <GlassPartsWidget
              barcode={scannedBarcode}
              groupName={panelData.filterGroupName}
            ></GlassPartsWidget>
          );
          break;
        case 10:
          panelBody = (
            <TrackingGlassWidget barcode={scannedBarcode}></TrackingGlassWidget>
          );
          break;
        case 11:
          panelBody = (
            <GlassGeorgianWidget barcode={scannedBarcode}></GlassGeorgianWidget>
          );
          break;
        case 12:
          panelBody = (
            <RejectWidget
              barcode={scannedBarcode}
              userId={loginData.userId}
              userName={loginData.realName}
              permissionId={loginData.permissionId}
            ></RejectWidget>
          );
          break;
        case 13:
          panelBody = (
            <AlertWidget
              stageId={station.completedStage}
              stageName={station.completedStageName}
              station={station}
              userId={loginData.userId}
              userName={loginData.userName}
            ></AlertWidget>
          );
          break;
        case 14:
          panelBody = (
            <UserLogWidget
              station={station}
              userId={loginData.userId}
              realName={loginData.realName}
            ></UserLogWidget>
          );
          break;
        case 15:
          panelBody = (
            <PartsV4Widget
              barcode={scannedBarcode}
              groupName={panelData.filterGroupName}
            ></PartsV4Widget>
          );
          break;
        case 16:
          panelBody = (
            <FrameDetailV2Widget
              queue={queue}
              setQueue={setQueue}
              updateQueue={updateQueue}
              showModalGFMA={showModalGFMA}
              showNewCriticalAlert={showNewCriticalAlert}
              setShowModalGFMA={setShowModalGFMA}
              setShowNewCriticalAlert={setShowNewCriticalAlert}
              barcode={scannedBarcode}
              startStage={station.startStage}
              userId={loginData.userId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              setIsShowPatioWarning={setIsShowPatioWarning}
              setProductCompleted={setProductCompleted}
              setScannedBarcode={setScannedBarcode}
              setIsScanning={setIsScanning}
            />
          );
          break;
        case 17:
          panelBody = (
            <ImageWidget
              barcode={scannedBarcode}
              groupName={panelData.filterGroupName}
            ></ImageWidget>
          );
          break;
        case 18:
          panelBody = (
            <PartsWidgetV5
              queue={queue}
              setQueue={setQueue}
              updateQueue={updateQueue}
              barcode={scannedBarcode}
              groupName={panelData.filterGroupName}
              setIsCodeChanged={setIsCodeChanged}
              setDiff={setDiff}
            ></PartsWidgetV5>
          );
          break;
        case 19:
          panelBody = (
            <PartsWidgetV6
              queue={queue}
              setQueue={setQueue}
              updateQueue={updateQueue}
              barcode={scannedBarcode}
              groupName={panelData.filterGroup}
              panelSetupId={panelData.id}
              setIsCodeChanged={setIsCodeChanged}
              setDiff={setDiff}
            ></PartsWidgetV6>
          );
          break;
        case 20:
          panelBody = (
            <CuttingListWidget
              setCurrentCuttingDetail={setCurrentCuttingDetail}
              setIsShowCuttingDetail={setIsShowCuttingDetail}
              setTitleCuttingList={setTitleCuttingList}
            ></CuttingListWidget>
          );
          break;
        case 21:
          panelBody = (
            <ProductChecking
              queue={queue}
              setQueue={setQueue}
              updateQueue={updateQueue}
              setIsShowSubmitedProductChecking={
                setIsShowSubmitedProductChecking
              }
              barcode={scannedBarcode}
              setIsPassedProductChecking={setIsPassedProductChecking}
            ></ProductChecking>
          );
          break;
        case 22:
          panelBody = (
            <PartsWidgetV7
              barcode={scannedBarcode}
              groupName={panelData.filterGroup}
              panelSetupId={panelData.id}
            ></PartsWidgetV7>
          );
          break;
        case 23:
          panelBody = (
            <SashLocatorWidget
              barcode={scannedBarcode}
              onScanCompleted={handleScanCompleted}
              permissionId={loginData.permissionId}
              stationId={station.id}
              panelId={panelData.id}
            ></SashLocatorWidget>
          );
          break;
        case 25:
          panelBody = (
            <WrapingMachine barcode={scannedBarcode}></WrapingMachine>
          );
          break;
        case 26:
          panelBody = (
            <FrameDetailV3
              queue={queue}
              setQueue={setQueue}
              updateQueue={updateQueue}
              showModalGFMA={showModalGFMA}
              showNewCriticalAlert={showNewCriticalAlert}
              setShowModalGFMA={setShowModalGFMA}
              setShowNewCriticalAlert={setShowNewCriticalAlert}
              barcode={scannedBarcode}
              startStage={station.startStage}
              userId={loginData.userId}
              stationId={stationId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              setIsShowPatioWarning={setIsShowPatioWarning}
              setProductCompleted={setProductCompleted}
              setScannedBarcode={setScannedBarcode}
              setIsScanning={setIsScanning}
            />
          );
          break;
        case 28:
          panelBody = (
            <AncillPackChecking
              barcode={scannedBarcode}
              startStage={station.startStage}
              userId={loginData.userId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              groupName={panelData.filterGroup}
              setScannedBarcode={setScannedBarcode}
              setIsScanning={setIsScanning}
            />
          );
          break;
        case 29:
          panelBody = <WeldedCillChecking barcode={scannedBarcode} />;
          break;
        case 30:
          panelBody = (
            <HardwareBoxesChecking
              barcode={scannedBarcode}
              startStage={station.startStage}
              userId={loginData.userId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              setProductCompleted={setProductCompleted}
              groupName={panelData.filterGroup}
            />
          );
          break;
        case 31:
          panelBody = <GlassChecking barcode={scannedBarcode} />;
          break;
        case 32:
          panelBody = <StillageChecking barcode={scannedBarcode} />;
          break;
        case 33:
          panelBody = (
            <WeldedCillDetail
              barcode={scannedBarcode}
              startStage={station.startStage}
              userId={loginData.userId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              groupName={panelData.filterGroup}
              setProductCompleted={setProductCompleted}
            />
          );
          break;
        case 34:
          panelBody = (
            <GlassDetail
              barcode={scannedBarcode}
              startStage={station.startStage}
              userId={loginData.userId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              setProductCompleted={setProductCompleted}
            />
          );
          break;
        case 35:
          panelBody = (
            <StillageDetail
              barcode={scannedBarcode}
              startStage={station.startStage}
              userId={loginData.userId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              setProductCompleted={setProductCompleted}
            />
          );
          break;
        case 36:
          panelBody = (
            <ODRChecking
              barcode={scannedBarcode}
              startStage={station.startStage}
              userId={loginData.userId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              setProductCompleted={setProductCompleted}
            />
          );
          break;
        case 37:
          panelBody = (
            <PartsWidgetV8
              barcode={scannedBarcode}
              groupName={panelData.filterGroup}
              panelSetupId={panelData.id}
            ></PartsWidgetV8>
          );
          break;
        case 38:
          panelBody = (
            <UserLogV2
              completedStage={station.completedStage}
              userId={loginData.userId}
              realName={loginData.realName}
            />
          );
          break;
        case 39:
          panelBody = (
            <PartsWidgetV9
              barcode={scannedBarcode}
              groupName={panelData.filterGroup}
              panelSetupId={panelData.id}
            />
          );
          break;
        case 40:
          panelBody = (
            <PartsWidgetV10
              barcode={scannedBarcode}
              groupName={panelData.filterGroup}
              panelSetupId={panelData.id}
            />
          );
          break;
        case 41:
          panelBody = (
            <FrameDetailV4
              queue={queue}
              setQueue={setQueue}
              updateQueue={updateQueue}
              showModalGFMA={showModalGFMA}
              showNewCriticalAlert={showNewCriticalAlert}
              setShowModalGFMA={setShowModalGFMA}
              setShowNewCriticalAlert={setShowNewCriticalAlert}
              barcode={scannedBarcode}
              startStage={station.startStage}
              isWorking={isWorking}
              userId={loginData.userId}
              stationId={stationId}
              onScanStarted={handleScanStarted}
              onScanCompleted={handleScanCompleted}
              setIsShowPatioWarning={setIsShowPatioWarning}
              setProductCompleted={setProductCompleted}
              setScannedBarcode={setScannedBarcode}
              setIsScanning={setIsScanning}
            />
          );
          break;
      }

      cxx.push(
        <Panel
          key={panelData.id + panelData.name}
          title={panelData.name}
          data-grid={nextPositionItem}
          body={panelBody}
          // showFrameWeight={item.frameWeightDisplayed}
        ></Panel>
      );

      setLayoutFromDb([...cxx]);
    } else {
      swalToastError('Panel is already existed');
      return;
    }
  };

  const removePanelFromLayout = layoutId => {
    setRemovedPanelId(layoutId);
  };

  const login = loginCode => {
    loginByBarcode(loginCode, station.enableUidLogin)
      .then(async res => {
        if (res.user) {
          if (!res.permission || !res.permission.isGranted) {
            swalToastError('No access granted');
            return;
          }

          const responseData = res.user;

          const loggedData = {
            isLogged: true,
            permissionId: res?.permission.permissionId,
            loginType: res?.loginType,
            ...responseData
          };

          setLoginData(prevState => {
            return Object.assign({}, prevState, loggedData);
          });
          localStorage.setItem('login-data', JSON.stringify(loggedData));
          dispatch(getPermission(res?.permission.permissionId));
          setLastUserPermission(res?.permission.permissionId);
        } else {
          swalToastError('User not found');
        }
      })
      .catch(err => {
        if (err?.response && err?.response.status === 400) {
          swalToastError(err?.response.data.message);
        } else {
          swalToastError(`Login failed. Please try again`);
        }
      });
  };

  const buildTheBarcode = (headerId, frameId) => {
    return '';
  };

  const invalidBarcode = () => {
    swalToastError('Invalid barcode');
    setIsScanning(false);
    setScannedBarcode(0);
  };

  const buildBarcode = (headerId, frameId) => {
    //convert header id and frame id to string
    headerId = headerId + '';
    frameId = frameId + '';

    let headerIdInBarcode = '000000000';
    let frameIdInBarcode = '0000';
    const endBarcode = '00100';

    const headNum =
      headerIdInBarcode.substring(0, 9 - headerId.length) + headerId;
    const frameNum =
      frameIdInBarcode.substring(0, 4 - frameId.length) + frameId;

    return headNum + frameNum + endBarcode;
  };

  const handleScanBarcode = async barcode => {
    if (loginData.userId < 1) {
      login(barcode);
      return;
    }

    if (!barcode) {
      return;
    }

    setFrameScanCompleted(false);

    if (station.enableScanByJobNo) {
      if (!isValidBarcodeAndScanByJobNo(barcode) || isCuidBarcode(barcode)) {
        invalidBarcode();
        return;
      }
    } else {
      if (!isValidBarcode(barcode) || isCuidBarcode(barcode)) {
        invalidBarcode();
        return;
      }
    }

    //build barcode from input jobNo and winNo
    if (station.enableScanByJobNo) {
      if (isScanByJobNoAndWinNo(barcode)) {
        let jobNo = '';
        let winNo = 0;
        const jobNoAndWinNo = spitJobNoAndWinNo(barcode);
        if (jobNoAndWinNo && jobNoAndWinNo.length === 2) {
          jobNo = jobNoAndWinNo[0];
          winNo = +jobNoAndWinNo[1];
        }

        const res = await getHeaderIdAndFrameId(jobNo, winNo);
        if (res && res.data) {
          barcode = buildBarcode(res.data.headerId, res.data.frameId);
        } else {
          swalToastError('Get Header Id And Frame Id Failed');
        }
      }
    }

    // move job to Production stage in NC Dashboard.
    // 6 is Live. 27 is Dev
    if (station.id === 6) {
      moveToProductionStage(barcode)
        .then(res => {
          if (res.data) {
            swalToastSuccess(
              'Move This Job To Production Stage On NC Dashboard Success'
            );
          }
        })
        .catch(error => {
          console.log(error);
          swalToastError(
            'Move This Job To Production Stage on NC Dashboard Failed'
          );
        });
    }

    if (scannedBarcode) {
      if (!frameScanCompleted) {
        swalToastWarning('Please wait current scanning finished...');
        return;
      }
      if (station.scanNextToComplete) {
        // specific case: a barcode is scanned twice
        if (barcode === scannedBarcode) {
          swalToastWarning('This barcode has just been scanned.');
          setFrameScanCompleted(true);
          return;
        }

        swalToastInfo('Completing current and scanning next barcode');

        handleCompleteScan(barcode, false);
      } else {
        swalToastError('Please press F8 to complete current scanning first!');
        return;
      }
    }

    if (latestLayout.length < 1 && layoutFromDb.length < 1) {
      swalToastError('Please add a panel to be able to scan');
      return;
    }

    if (scannedBarcode == 0) {
      setIsScanning(true);
      setScannedBarcode(barcode);
    }
  };

  const handleScanStarted = () => {
    setFrameScanCompleted(false);
  };

  const handleScanCompleted = userTrackingId => {
    if (userTrackingId > 0) {
      setActualScannedBarcode(scannedBarcode);
      setFrameScanCompleted(true);
      setUserTrackingId(userTrackingId);
    }
  };

  const handleEditPanelSetup = () => {};

  const handleStartSawBatch = batchData => {
    setBatchData(batchData);
  };

  const handleCompleteScan = (nextBarcode, fromf8) => {
    let payload = {};

    if (nextBarcode == 0) nextBarcode = scannedBarcode;
    switch (station.appliedPanelId) {
      case 3: // frame detail
      case 16: // frame detail v2
      case 26: // frame detail v3
      case 41: // frame detail v4
      case 33: // weld cill detail
      case 28: // ancill checking
      case 30: // hardware box checking
      case 34: // Glass Detail
      case 35: // Stillage Detail
      case 36: // ODR Checking
        payload = {
          barcode: scannedBarcode,
          stage: station.completedStage,
          userId: loginData.userId,
          category: productCompleted,
          productAllocationId: station.productAllocationId
        };
        completeScanFrame(payload)
          .then(res => {
            if (res?.completed) {
              swalToastSuccess('Scanning has been completed');

              //Clear Sash Locator
              const sashLocatorWidgetExist = layoutForSetting.find(
                item => item.panelId === 23 // Sash Locator Widget
              );
              if (sashLocatorWidgetExist) {
                clearSash(station.id, scannedBarcode);
              }

              if (fromf8) {
                setIsScanning(false);
                setScannedBarcode(0);
              } else {
                setIsScanning(true);
                setScannedBarcode(nextBarcode);
              }
            } else {
              swalToastError('Failed to complete scanning. Please try again!');
            }
          })
          .catch(err => {
            swalToastError('Error. Failed To Complete');
          });
        break;
      case 5: // saw cutting
        payload = {
          batchNo: batchData.batchNo,
          subBatchNo: batchData.subBatchNo,
          isSash: batchData.isSash,
          barcode: scannedBarcode,
          completedStage: station.completedStage,
          ProductAllocationId: station.productAllocationId,
          userId: loginData.userId
        };
        completeBatch(payload).then(res => {
          if (res?.completed) {
            swalToastSuccess('Batch has been completed');

            if (fromf8) {
              setIsScanning(false);
              setScannedBarcode(0);
            } else {
              setScannedBarcode(nextBarcode);
            }

            setScanCompleted(true);
          } else {
            swalToastError('Failed to complete batch. Please try again');
          }
        });
        break;
    }
  };

  const clearSash = (stationId, scannedBarcode) => {
    getEnabledClearSash(23, stationId)
      .then(res => {
        if (res && res.data) {
          const clearData = {
            barcode: scannedBarcode
          };
          clearSashLocation(clearData)
            .then(res => {
              if (res.data) {
                swalToastSuccess('Clear Sash Location Success!');
              }
            })
            .catch(error => {
              swalToastError('Error. Failed To Clear Sash Location!');
            });
        }
      })
      .catch(err => {
        swalToastError('Get Enabled Clear Sash Failed!');
      });
  };

  const onStationUpdated = newPath => {
    swalToastSuccess('Station updated');
    location.href = `/station/${newPath}`;
  };

  const handleManualStartupCheck = () => {
    setManualStartUpCheck(true);
  };

  const handleStartupCheckCompleted = () => {
    setManualStartUpCheck(false);

    // pop data from queue
    setIsShowStatupCheck(false);
    const newQueue = queue;
    newQueue.pop();
    updateQueue(newQueue);

    //do qc checks right after
    setManualQualityCheck(true);
  };

  const handleNoStartupCheck = () => {
    setManualStartUpCheck(false);
  };

  const handleReportStartupCheckIssue = () => {
    setManualStartUpCheck(false);
  };

  const handleCompleteAllQc = () => {
    setManualQualityCheck(false);

    // pop data from queue
    setIsShowQCCheck(false);
    const newQueue = queue;
    newQueue.pop();
    updateQueue(newQueue);
  };

  let oldBarcode = '';

  useEffect(() => {
    if (cameraCode) {
      handleScanBarcode(cameraCode);
      setIsShowCamera(false);
    }
  }, [cameraCode]);

  const handleScanBarcodeCamera = barcode => {
    if (barcode === oldBarcode) {
      swalToastWarning('This barcode has just been scanned.');
      setFrameScanCompleted(true);
      return;
    }
    oldBarcode = barcode;
    setCameraCode(barcode);
  };

  return (
    <div>
      <StationNavbarTop
        stationId={station.id}
        name={station.stationName}
        isDisabledSessionExpired={station.disabledSessionExpired}
        minutesSessionExpired={station.minutesSessionExpired}
        showHidePanelSetup={showHidePanelSetup}
        loginData={loginData}
        onStationScan={handleScanBarcode}
        isScanning={isScanning}
        onManualStartupCheck={handleManualStartupCheck}
        onSessionExpired={onSessionExpired}
        onAutoExpandSession={onAutoExpandSession}
        setIsShowCamera={setIsShowCamera}
        isShowCamera={isShowCamera}
      ></StationNavbarTop>

      {/* CHECK IS TABLET SHOW CAMERA ICON */}
      {(isTablet || isMobile || innerWidth <= 1024) && isShowCamera && (
        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 9999 }}>
          <BarcodeScannerComponent
            facingMode={'environment'}
            width={500}
            height={300}
            onUpdate={(err, result) => {
              if (result) {
                handleScanBarcodeCamera(result.text);
              }
            }}
            onError={error => {
              console.log('ERROR OPEN CAMERA', error);

              if (error.name === 'NotAllowedError') {
                swalToastError(`Don't Have Permission To Access Camera!`);
              } else {
                swalToastError(
                  'Some Thing Went Wrong When Access Camera! Please Try Again'
                );
              }
            }}
          />
        </div>
      )}

      {loginData.userId > 0 ? (
        <>
          <GlobalHotKeys keyMap={keyMap} handlers={keyMapHandlers} />
          <div style={{ width: '100%' }}>
            <ReactGridLayout
              className="layout"
              cols={48}
              rowHeight={60}
              width={window.innerWidth - 25}
              verticalCompact={false}
              isDroppable={
                loginData.permissionId ===
                process.env.REACT_APP_ADMIN_PERMISSION
              }
              isDraggable={
                loginData.permissionId ===
                process.env.REACT_APP_ADMIN_PERMISSION
              }
              isResizable={
                loginData.permissionId ===
                process.env.REACT_APP_ADMIN_PERMISSION
              }
              onLayoutChange={onLayoutChange}
              preventCollision={true}
            >
              {layoutFromDb}
            </ReactGridLayout>
          </div>
        </>
      ) : (
        <div style={{ marginTop: '80px' }}>
          <UnauthenticateError
            isEnableUidLogin={station.enableUidLogin}
            permissionId={LastUserpermission}
          ></UnauthenticateError>
        </div>
      )}
      {loginData.permissionId === process.env.REACT_APP_ADMIN_PERMISSION ? (
        <SettingsPanel
          station={station}
          panels={dbPanels}
          layout={layoutForSetting}
          onAddPanel={addPanelToLayout}
        />
      ) : (
        ''
      )}

      {checkType ? (
        checkType === 'freqency' ? (
          <QualityCheck
            PopDataOfQueue={PopDataOfQueue}
            updateQueue={updateQueue}
            queue={queue}
            setQueue={setQueue}
            isShowQCCheck={isShowQCCheck}
            setIsShowQCCheck={setIsShowQCCheck}
            station={station}
            loginData={loginData}
            barcode={actualScannedBarcode}
            checks={qualityChecks}
            manual={manualQualityCheck}
            onAllQcCompleted={handleCompleteAllQc}
            userTrackingId={userTrackingId}
          ></QualityCheck>
        ) : (
          <DurationQualityCheck
            station={station}
            loginData={loginData}
            checks={qualityChecks}
            manual={manualQualityCheck}
          ></DurationQualityCheck>
        )
      ) : (
        ''
      )}
      {Object.keys(station).length > 0 && loginData.isLogged && (
        <StartUpCheck
          updateQueue={updateQueue}
          queue={queue}
          setQueue={setQueue}
          PopDataOfQueue={PopDataOfQueue}
          isShowStatupCheck={isShowStatupCheck}
          setIsShowStatupCheck={setIsShowStatupCheck}
          station={station}
          loginData={loginData}
          manual={manualStartUpCheck}
          onCheckCompleted={handleStartupCheckCompleted}
          onNoStartUpCheck={handleNoStartupCheck}
          onReportIssue={handleReportStartupCheckIssue}
        ></StartUpCheck>
      )}

      <StationFooter
        stationId={localStorage.getItem('station-id')}
        path={params.stationPath}
        station={station}
        loginData={loginData}
        isScanning={isScanning}
        onComplete={handleCompleteScan}
        latestLayout={latestLayout}
        openPanelSetting={openAvailablePanels}
        onStationUpdated={onStationUpdated}
      ></StationFooter>

      <GlazingForwardManuAlert
        updateQueue={updateQueue}
        queue={queue}
        setQueue={setQueue}
        PopDataOfQueue={PopDataOfQueue}
        show={showModalGFMA}
        setShowModalGFMA={setShowModalGFMA}
      ></GlazingForwardManuAlert>
      <NewAndCriticalAlert
        updateQueue={updateQueue}
        queue={queue}
        setQueue={setQueue}
        PopDataOfQueue={PopDataOfQueue}
        show={showNewCriticalAlert}
        setShowNewCriticalAlert={setShowNewCriticalAlert}
      ></NewAndCriticalAlert>
      <ProductCodeAlert
        queue={queue}
        updateQueue={updateQueue}
        setQueue={setQueue}
        PopDataOfQueue={PopDataOfQueue}
        show={isCodeChanged}
        setIsCodeChanged={setIsCodeChanged}
        data={diff}
        setDiff={setDiff}
      ></ProductCodeAlert>

      <CuttingListDetail
        startStage={station.startStage}
        completedStage={station.completedStage}
        stationId={station.id}
        currentCutting={currentCuttingDetail}
        show={isShowCuttingDetail}
        setIsShowCuttingDetail={setIsShowCuttingDetail}
        titleCuttingList={titleCuttingList}
      ></CuttingListDetail>
      <PatioWarning
        queue={queue}
        updateQueue={updateQueue}
        setQueue={setQueue}
        PopDataOfQueue={PopDataOfQueue}
        isShowPatioWarning={isShowPatioWarning}
        setIsShowPatioWarning={setIsShowPatioWarning}
      ></PatioWarning>

      <ProductCheckingWarning
        queue={queue}
        updateQueue={updateQueue}
        setQueue={setQueue}
        PopDataOfQueue={PopDataOfQueue}
        isShowSubmitedProductChecking={isShowSubmitedProductChecking}
        setIsShowSubmitedProductChecking={setIsShowSubmitedProductChecking}
        isPassedProductChecking={isPassedProductChecking}
      />
    </div>
  );
}
