import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserPermissions } from 'services/userService';

const initialState = {
  permissions: [],
  permissionId: 0
};

export const getPermissions = createAsyncThunk(
  'permission/getPermissions',
  async username => {
    try {
      const res = await getUserPermissions(username);
      return res.permissions;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPermission = createAsyncThunk(
  'permission/getPermission',
  permissionId => {
    try {
      return permissionId;
    } catch (error) {
      console.log(error);
    }
  }
);

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
  extraReducers: {
    [getPermissions.fulfilled]: (state, action) => {
      state.permissions = [];
      state.permissions.push(...action.payload);
    },
    [getPermission.fulfilled]: (state, action) => {
      state.permissionId = action.payload;
    }
  }
});

export default permissionSlice.reducer;
