import axios from 'axios';

const bucketName = process.env.REACT_APP_BUCKET_NAME;

const uploadFileToS3 = async (file, folderPrefix) => {
  const res = await axios({
    url: `${process.env.REACT_APP_FILE_API_HOST}/files/upload?bucketName=${bucketName}&prefix=${folderPrefix}`,
    method: 'POST',
    data: file
  });

  try {
    return {
      success: res.data.success,
      message: res.data.message,
      mapping: res.data.mapping
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const uploadMultiFilesToS3 = async (files, folderPrefix) => {
  const res = await axios({
    url: `${process.env.REACT_APP_FILE_API_HOST}/files/multi-upload?bucketName=${bucketName}&prefix=${folderPrefix}`,
    method: 'POST',
    data: files
  });

  try {
    return {
      success: res.data.success,
      message: res.data.message,
      mappings: res.data.mappings
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteS3File = async fileKey => {
  const res = await axios({
    url: `${process.env.REACT_APP_FILE_API_HOST}/files/${bucketName}?key=${fileKey}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data.StatusCode == 204,
      message: res.data.Message
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteMultipleS3Files = async fileKeys => {
  const res = await axios({
    url: `${process.env.REACT_APP_FILE_API_HOST}/files/multiple/${bucketName}`,
    method: 'DELETE',
    data: fileKeys
  });

  try {
    return {
      success: res.data.StatusCode == 204,
      message: res.data.Message
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getAttachmentByRecordId = async (recordId, app) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/attachment-management/attachments/${recordId}?app=${app}`
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getAttachmentsByRecordId = async (recordId, app) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/attachment-management/attachments/multiple/${recordId}?app=${app}`
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const insertAttachmentRecord = async item => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/attachment-management/attachments`,
    method: 'POST',
    data: item
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const insertMultiAttachmentRecords = async attachments => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/attachment-management/attachments/multiple`,
    method: 'POST',
    data: attachments
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteAttachmentRecord = async id => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/attachment-management/attachments/${id}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteAttachmentsByRecordId = async (recordId, app) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/attachment-management/attachments/multiple/${recordId}?app=${app}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateAttachmentImgDisplaySize = async displaySize => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/attachment-management/attachments/${displaySize.attachmentId}/img-size?width=${displaySize.imgWidth}&height=${displaySize.imgHeight}`,
    method: 'PATCH'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getAtachmentPathsByPcodes = async pcodes => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/attachment-management/attachments/paths`,
    method: 'POST',
    data: pcodes
  });

  try {
    return {
      data: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  uploadFileToS3,
  uploadMultiFilesToS3,
  deleteS3File,
  deleteMultipleS3Files,
  getAttachmentByRecordId,
  insertAttachmentRecord,
  insertMultiAttachmentRecords,
  deleteAttachmentRecord,
  deleteAttachmentsByRecordId,
  updateAttachmentImgDisplaySize,
  getAtachmentPathsByPcodes,
  getAttachmentsByRecordId
};
