export const colourData = [
  { name: 'Purple', value: '#D291BC' },
  { name: 'Yellow', value: '#F7EF64' },
  { name: 'Orange', value: '#F9A38A' },
  { name: 'Green', value: '#B1D394' },
  { name: 'Blue', value: '#2C7BE5' },
  { name: 'Grey', value: '#8C8185' },
  { name: 'Red', value: '#BD3241' },
  { name: 'White', value: '#FFFFFF' },
  { name: 'Black', value: '#000000' }
];
