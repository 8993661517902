import Logo from 'components/common/Logo';
import AppContext from 'context/Context';
import React, { useContext, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { FaCamera, FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import AttachmentModal from '../AttachmentModal';
import BarcodeInput from '../BarcodeInput';
import UserAvatar from '../common/Avatar';
import LiveTimeClock from '../common/LiveTime';
import SessionTimeClock from '../common/SessionTimeClock';
import { isMobile, isTablet } from 'react-device-detect';
import { FiCamera, FiCameraOff } from 'react-icons/fi';
import '../../../assets/scss/user.scss';

const StationNavbarTop = props => {
  const {
    config: { isRTL },
    setConfig
  } = useContext(AppContext);

  const innerWidth = window.innerWidth;

  const loginData = props.loginData;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleScan = barcodeValue => {
    props.onStationScan(barcodeValue);
  };

  const handleManualStartupCheck = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onManualStartupCheck();
  };

  const handleClickCamera = e => {
    e.preventDefault();
    props.setIsShowCamera(!props.isShowCamera);
  };

  return (
    <Navbar
      className="navbar bg-light navbar-dark d-flex justify-content-between align-items-center gap-1"
      style={{ padding: 0 }}
    >
      <Logo at="navbar-top" width={100} id="topLogo" loginData={loginData} />
      <span className="mx-0 navbar-brand">{props.name}</span>

      <div className="d-flex align-items-center gap-1">
        <span>
          <BarcodeInput
            readOnly={props.isShowCamera}
            onScan={handleScan}
          ></BarcodeInput>
        </span>
        {(isTablet || isMobile || innerWidth <= 1024) && (
          <span className="cursor-pointer" onClick={handleClickCamera}>
            {props.isShowCamera ? (
              <FiCameraOff color="#0275d8" size={24} />
            ) : (
              <FiCamera color="#0275d8" size={24} />
            )}
          </span>
        )}
      </div>

      <div className="navbar-brand m-0">
        <div className="d-flex justify-content-center align-items-center gap-1">
          <UserAvatar
            name={loginData.realName}
            size="30px"
            round="30px"
          ></UserAvatar>
          <span className="user-logged-name">{loginData.userName}</span>
        </div>
      </div>
      <div className="live-time-clock">
        <LiveTimeClock locale={'en-GB'}></LiveTimeClock>
      </div>

      {props.isDisabledSessionExpired === false && props.loginData.isLogged && (
        <div style={{ width: '260px' }}>
          <SessionTimeClock
            lastLoginTime={loginData.lastLogin}
            onSessionExpired={props.onSessionExpired}
            onAutoExpandSession={props.onAutoExpandSession}
            minutesSessionExpired={props.minutesSessionExpired}
          ></SessionTimeClock>
        </div>
      )}
      <div className="d-flex gap-1">
        <div className="cursor-pointer" onClick={handleManualStartupCheck}>
          <FaCheckCircle color="#0275d8" size={30} />
        </div>
        <div className="cursor-pointer" onClick={handleShow}>
          <FaInfoCircle color="#0275d8" size={30} />
        </div>
      </div>

      <AttachmentModal
        stationId={props.stationId}
        show={show}
        name={props.name}
        handleClose={handleClose}
      />
    </Navbar>
  );
};

export default StationNavbarTop;
