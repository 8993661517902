import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';

export default function LiveTimeClock(props) {
  const [liveTime, setLiveTime] = useState(Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setLiveTime(liveTime => Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <Moment format="ddd, D/MM/yyyy HH:mm:ss" date={liveTime}></Moment>;
}
