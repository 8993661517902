import React from 'react';
import Avatar from 'react-avatar';

export default function UserAvatar(props) {
  return (
    <Avatar
      name={props.name}
      size={props.size}
      round={props.round}
      color="#2c7be5"
    ></Avatar>
  );
}
