import axios from 'axios';

const getFilterGroups = async () => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Filter/filter-groups`
  );

  try {
    return {
      success: true,
      groups: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getMergedFilterGroups = async groupName => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Filter/filter-groups-merge-with?groupName=${groupName}`
  );

  try {
    return {
      success: true,
      mergedGroups: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getFilterItemsByGroup = async groupName => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Filter/filter-items?groupName=${groupName}`
  );

  try {
    return {
      success: true,
      filterItems: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getProductImagesByGroup = async (groupName, app) => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Filter/product-images?filterGroup=${groupName}&app=${app}`
  );

  try {
    return {
      success: true,
      imageItems: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getPartMatchFields = async () => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Filter/match-fields`
  );

  try {
    return {
      success: true,
      matchFields: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getMatchFieldByGroup = async groupName => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Filter/group-match-field?groupName=${groupName}`
  );

  try {
    return {
      success: true,
      matchField: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateGroupMatchField = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Filter/group-match-field`,
    method: 'PATCH',
    data: payload
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const insertFilterItemSetup = async item => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Filter/filter-item`,
    method: 'POST',
    data: item
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateFilterItemsSetup = async items => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Filter/filter-items`,
    method: 'POST',
    data: items
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateMergeFilterGroup = async (group, mergeGroup, action) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Filter/merge-filter-group?groupName=${group}&mergeGroup=${mergeGroup}&action=${action}`,
    method: 'POST'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteFilterItemById = async id => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Filter/filter-item/${id}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getGroupExcludeOption = async groupName => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/Filter/group-exclude-option?groupName=${groupName}`
  );

  try {
    return {
      success: true,
      isExcluded: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateGroupExcludeOption = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Filter/group-exclude-option`,
    method: 'PATCH',
    data: payload
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  getFilterGroups,
  getMergedFilterGroups,
  getFilterItemsByGroup,
  getProductImagesByGroup,
  getPartMatchFields,
  getMatchFieldByGroup,
  updateGroupMatchField,
  updateFilterItemsSetup,
  insertFilterItemSetup,
  updateMergeFilterGroup,
  deleteFilterItemById,
  getGroupExcludeOption,
  updateGroupExcludeOption
};
