import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Offcanvas, Button } from 'react-bootstrap';
import AppContext from 'context/Context';
import StationSpinner from 'components/station/common/Spinner';

const SettingsPanel = props => {
  const {
    config: { showSettingPanel },
    setConfig
  } = useContext(AppContext);

  let dbPanels = props.panels;
  const station = props.station;
  const layout = props.layout;
  const [isLoading, setIsLoading] = useState(false);

  let panelHtml = [];

  let panelArr = [];

  if (dbPanels.length > 0) {
    dbPanels.forEach(function (item) {
      let matchedObj = layout.find(ly => ly.panelId === item.id);

      if (matchedObj != undefined) {
        let cp = Object.assign({}, item);
        //cp.isDisabled = true;
        panelArr.push(cp);
      } else {
        let cp = Object.assign({}, item);
        //cp.isDisabled = false;
        panelArr.push(cp);
      }
    });
  }

  // console.log(panelArr);
  panelArr.forEach(function (item, index) {
    panelHtml.push(
      <div key={`btn${index}`}>
        <Button
          id={`btn${item.id}`}
          className="mt-1"
          onClick={e =>
            handleClick(e, item.id, item.name, item.filterGroupName)
          }
        >
          {item.name}
        </Button>
        <br />
      </div>
    );
  });

  const handleClick = (e, id, name, filterGroupName) => {
    let payload = {
      id: id,
      name: name,
      stationId: station.id,
      filterGroupName: filterGroupName
    };
    setIsLoading(true);
    props.onAddPanel(payload);
    setTimeout(() => {
      setIsLoading(false);
    }, 10);
  };

  return (
    <Offcanvas
      show={showSettingPanel}
      onHide={() => setConfig('showSettingPanel', false)}
      placement="end"
      style={{ maxWidth: '22rem' }}
      className="border-0"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        className="bg-shape settings-panel-header"
      >
        <Offcanvas.Title as="div" className="py-1 z-index-1 light">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h5 className="text-white">
              <FontAwesomeIcon icon="palette" className="me-2 fs-0" />
              Panel Settings
            </h5>
          </div>
          <p className="mb-0 fs--1 text-white opacity-75">
            Set your own customized layout
          </p>
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body className="scrollbar">
        <h5 className="fs-0">Available Panels</h5>
        <p className="fs--1">Choose the panels you want to display.</p>
        {isLoading ? <StationSpinner></StationSpinner> : panelHtml}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SettingsPanel;
