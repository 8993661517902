import React, { useContext, useState } from 'react';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import CartNotification from 'components/navbar/top/CartNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import UserAvatar from 'components/station/common/Avatar';

const TopNavRightSideNavItem = props => {
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const loginData = props.loginData;

  // useState(() => {
  //   const loggedData = sessionStorage.getItem('login-data');
  //   setLoginData({ ...JSON.parse(loggedData) });
  // }, [sessionStorage.getItem('login-data')]);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      {/* <CartNotification />
      <NotificationDropdown />
      <NineDotMenu /> */}
      {/* <ProfileDropdown /> */}
      <UserAvatar
        name={loginData.realName}
        size="40px"
        round="40px"
      ></UserAvatar>
    </Nav>
  );
};

export default TopNavRightSideNavItem;
