import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getStations } from 'services/stationService';

const StationSelector = () => {
  const IsNotRFIDLogin = () => {
    const user = JSON.parse(localStorage.getItem('login-data') || '');
    if (user && user.loginType !== 'RFID') {
      return true;
    }
    return false;
  };
  let dropSelections = [];
  const [stations, setStations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getStations().then(res => {
      setStations(res.stations);
    });
  }, []);

  const handleRedirect = item => {
    navigate(process.env.REACT_APP_STATION_PATH + item.stationPath);
  };

  if (stations?.length > 0) {
    stations.forEach(function (item, index) {
      dropSelections.push(
        <Dropdown.Item
          disabled={!item.enableUidLogin && IsNotRFIDLogin()}
          key={`station${index}`}
          onClick={() => handleRedirect(item)}
        >
          {item.stationName}
        </Dropdown.Item>
      );
    });
  } else {
    dropSelections.push(
      <Dropdown.Item key={`station0`} href="#">
        No station found.
      </Dropdown.Item>
    );
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="falcon-default">
        Select Scan Station
      </Dropdown.Toggle>
      <Dropdown.Menu className="py-2">{dropSelections}</Dropdown.Menu>
    </Dropdown>
  );
};

export default StationSelector;
