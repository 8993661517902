import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {
  BsFillFileEarmarkExcelFill,
  BsFillFileEarmarkPdfFill,
  BsFillFileEarmarkWordFill
} from 'react-icons/bs';
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import ReactPlayer from 'react-player';
import { getAttachmentsByRecordId } from 'services/attachmentService';

const fileTypes = {
  image: ['jpeg', 'png', 'jpg'],
  pdf: ['pdf'],
  msWord: ['doc', 'docx'],
  msExcel: ['xlsx', 'slx', 'csv'],
  video: ['mp4']
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/'
};

const DOCS_TRAINING_APP = 'training.docs';

function AttachmentModal(props) {
  const [attachments, setAttachments] = useState([]);
  const [numPages, setNumPages] = useState();
  const [pdfFile, setPdfFile] = useState();
  const [isShowPdf, setIsShowPdf] = useState(false);

  const [language, setLanguage] = useState('en');

  const handleClosePdf = () => setIsShowPdf(false);
  const handleShowPdf = () => setIsShowPdf(true);

  useEffect(() => {
    if (props.stationId) {
      getAttachmentsByRecordId(props.stationId, DOCS_TRAINING_APP).then(res => {
        const data = res.data.sort((a, b) =>
          dayjs(a).isAfter(dayjs(b)) ? 1 : -1
        );
        setAttachments(data.filter(file => file.language === language));
      });
    }
  }, [props.stationId, language]);

  const getFileExtention = fileName => {
    return fileName.split('.').pop();
  };

  const handleDownload = file => {
    const link = document.createElement('a');
    link.download = file.name;
    link.target = '_blank';
    link.rel = 'noreferrer';
    link.href = `${process.env.REACT_APP_FILE_API_HOST}/files/download/${
      process.env.REACT_APP_BUCKET_NAME
    }?key=${file.path.replace('s3/eva-files/', '')}`;
    link.click();
  };

  const viewPDFContent = file => {
    setPdfFile(file);
    handleShowPdf();
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const generateAttachment = file => {
    if (fileTypes.image.includes(getFileExtention(file.name))) {
      return (
        <PhotoView
          src={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${
            process.env.REACT_APP_BUCKET_NAME
          }?key=${file.path.replace('s3/eva-files/', '')}`}
        >
          <img
            title={file.name}
            className="cursor-pointer"
            width={50}
            height={50}
            src={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${
              process.env.REACT_APP_BUCKET_NAME
            }?key=${file.path.replace('s3/eva-files/', '')}`}
            alt={'document'}
          />
        </PhotoView>
      );
    } else if (fileTypes.msWord.includes(getFileExtention(file.name))) {
      return (
        <div>
          <BsFillFileEarmarkWordFill
            className="cursor-pointer"
            onClick={() => handleDownload(file)}
            size={50}
          />
        </div>
      );
    } else if (fileTypes.msExcel.includes(getFileExtention(file.name))) {
      return (
        <div>
          <BsFillFileEarmarkExcelFill
            className="cursor-pointer"
            onClick={() => handleDownload(file)}
            size={50}
          />
        </div>
      );
    } else if (fileTypes.pdf.includes(getFileExtention(file.name))) {
      return (
        <div>
          <BsFillFileEarmarkPdfFill
            className="cursor-pointer"
            onClick={() => viewPDFContent(file)}
            size={50}
          />
        </div>
      );
    } else if (fileTypes.video.includes(getFileExtention(file.name))) {
      return (
        <ReactPlayer
          url={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${
            process.env.REACT_APP_BUCKET_NAME
          }?key=${file.path.replace('s3/eva-files/', '')}`}
          width="200px"
          height="100px"
          light={false}
          controls={true}
          className="cursor-pointer"
        ></ReactPlayer>
      );
    }
  };

  const handleChangeLanguage = event => {
    setLanguage(event.target.value);
  };

  const generateTbodyByDocType = (files, type) => {
    return files.map((file, ind) => (
      <tr key={ind} className="hover-actions-trigger">
        <td style={{ paddingLeft: '3rem' }}>{generateAttachment(file)}</td>
        <td>{file.name}</td>
      </tr>
    ));
  };

  const generateTbody = attachments => {
    const sops = attachments.filter(file => file.documentType === 'sops');
    const sips = attachments.filter(file => file.documentType === 'sips');
    const evt = attachments.filter(file => file.documentType === 'evt');

    return (
      <>
        <>
          <tr>
            <td colSpan={2} className="fst-italic fw-bold">
              SOPS
            </td>
          </tr>
          {sops.length > 0 && generateTbodyByDocType(sops, 'sops')}
        </>
        <>
          <tr>
            <td colSpan={2} className="fst-italic fw-bold">
              SIPS
            </td>
          </tr>
          {sips.length > 0 && generateTbodyByDocType(sips, 'sips')}
        </>

        <>
          <tr>
            <td colSpan={2} className="fst-italic fw-bold">
              EVT
            </td>
          </tr>
          {evt.length > 0 && generateTbodyByDocType(evt, 'evt')}
        </>
      </>
    );
  };

  return (
    <>
      <Modal
        dialogClassName="w-75 mw-100"
        show={props.show}
        onHide={props.handleClose}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.name} - Training Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto" style={{ height: '85vh' }}>
          <div className="col-6 d-flex align-items-center gap-2">
            <span>Language/Limba/Język</span>
            {/* <span> {language === 'en' && 'Language'}</span>
            <span> {language === 'ro' && 'Limba'}</span>
            <span> {language === 'po' && 'Język'}</span> */}
            <Form.Select
              className="cursor-pointer"
              onChange={handleChangeLanguage}
              aria-label="Default select example"
              value={language}
            >
              <option value="en">English</option>
              <option value="ro">Romania</option>
              <option value="po">Polish</option>
            </Form.Select>
          </div>
          <PhotoProvider maskOpacity={0.5}>
            <Table hover responsive>
              <thead>
                <tr>
                  <th className="col-5" scope="col">
                    Attachment
                  </th>
                  <th className="col-7" scope="col">
                    Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {attachments.length > 0 ? (
                  generateTbody(attachments)
                ) : (
                  <tr>
                    <td
                      colSpan={2}
                      className="pt-4 text-center"
                      style={{ fontWeight: 500 }}
                    >
                      No Document Uploaded
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </PhotoProvider>
        </Modal.Body>
      </Modal>

      {/* PDF viewer */}
      <Modal
        dialogClassName="w-75 mw-100"
        show={isShowPdf}
        onHide={handleClosePdf}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{pdfFile?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="d-flex justify-content-center overflow-auto"
          style={{ height: '85vh' }}
        >
          <Document
            options={options}
            onLoadSuccess={onDocumentLoadSuccess}
            file={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${
              process.env.REACT_APP_BUCKET_NAME
            }?key=${pdfFile?.path.replace('s3/eva-files/', '')}`}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                className={'opacity-75'}
                canvasBackground={'#cccccc'}
                key={index + 1}
                pageNumber={index + 1}
                renderMode="canvas"
                scale={1.5}
              />
            ))}
          </Document>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AttachmentModal;
