import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';

export default function FrameImage({ jobNo, winNo, isBox }) {
  const [path, setPath] = useState('');
  const [width, setWidth] = useState(300);
  const [height, setHeight] = useState(300);
  useEffect(() => {
    let imgPath = `${process.env.REACT_APP_API_HOST}/FrameImage/${jobNo}/${winNo}`;
    if (isBox) {
      imgPath = `${process.env.REACT_APP_API_HOST}/FrameImage/${jobNo}/bay/${winNo}`;
      setWidth('auto');
      setHeight('auto');
    }
    setPath(imgPath);
  }, [jobNo, winNo, isBox]);

  return jobNo ? (
    <Image
      style={{ maxWidth: 800, maxHeight: 400 }}
      src={path}
      width={width}
      height={height}
      className="center-block"
      alt="frame image"
      unoptimized="true"
    ></Image>
  ) : (
    <h4>No image found</h4>
  );
}
