import StationSpinner from 'components/station/common/Spinner';
import { React, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { getPartV8ShowSize } from 'redux/slices/widgetSlice';
import { getPartItemsV2 } from 'services/partService';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function PartsWidgetV8(props) {
  const dispatch = useDispatch();

  const [partItems, setPartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isShowSize = useSelector(store => store.widget.partV8ShowSize);

  useEffect(() => {
    if (props.barcode != 0) {
      setIsLoading(true);
      getPartItemsV2(props.barcode, props.groupName)
        .then(res => {
          setPartItems(res?.partItems);
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setPartItems([]);
    }
  }, [props.barcode]);

  useEffect(() => {
    dispatch(getPartV8ShowSize(props.panelSetupId));
  }, []);

  const getTextDecorationStyle = item => {
    if (item.fontUnderline && item.fontStrikeOut) {
      return 'underline line-through';
    } else {
      if (item.fontUnderline) return 'underline';
      else if (item.fontStrikeOut) return 'line-through';
    }
  };

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Qty</th>
            <th>P/Code</th>
            <th>Description</th>
            {isShowSize && <th>Size</th>}
          </tr>
        </thead>
        <tbody>
          {partItems.length > 0 ? (
            partItems.map((item, index) => (
              <tr
                key={'row' + index}
                style={{
                  color: item.fontColour,
                  backgroundColor: item.bkColour,
                  fontWeight: item.fontBold ? 'bold' : '',
                  fontSize: item.fontSize,
                  fontStyle: item.fontItalic ? 'italic' : '',
                  textDecoration: getTextDecorationStyle(item)
                }}
              >
                <td>{item.qty}</td>
                <td>{item.pCode}</td>
                <td>{item.description}</td>
                {isShowSize && <td>{item.length1}</td>}
              </tr>
            ))
          ) : (
            <tr key={'row0'}>
              <td colSpan={3}>{'No records found.'}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
