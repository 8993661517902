import { React, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function ProductCheckingWarning({
  isShowSubmitedProductChecking,
  setIsShowSubmitedProductChecking,
  PopDataOfQueue,
  queue,
  updateQueue,
  isPassedProductChecking
}) {
  useEffect(() => {
    PopDataOfQueue(
      'isShowSubmitedProductChecking',
      setIsShowSubmitedProductChecking
    );
  }, [queue]);

  const handleClose = () => {
    setIsShowSubmitedProductChecking(false);
    const newQueue = queue;
    newQueue.pop();
    updateQueue(newQueue);
  };

  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      handleClose();
    }
  };

  return (
    <div onKeyUp={e => handleKeyUp(e)} className="product-code-popup">
      <Modal show={isShowSubmitedProductChecking} size="xl">
        <Modal.Header style={{ backgroundColor: '#d9534f' }}>
          <Modal.Title
            style={{
              textDecoration: 'underline',
              width: '100%',
              textAlign: 'center',
              color: '#fff',
              fontSize: 28
            }}
          >
            WARNING
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ backgroundColor: '#d9534f', fontSize: 24, color: '#fff' }}
        >
          <div>
            <div style={{ marginBottom: '20px' }}>
              {isPassedProductChecking
                ? 'This item has already passed QA checks.'
                : 'This item has been rejected, please QA check.'}
            </div>
            <div style={{ marginBottom: '20px' }}>
              {isPassedProductChecking
                ? 'Ta rama przeszła już kontrolę jakości.'
                : 'Ta rama została odrzucona, proszę sprawdzić pod kątem kontroli jakości.'}
            </div>
            <div>
              {isPassedProductChecking
                ? 'Acesta fereastra a trecut deja verificările QA.'
                : 'Acesta fereastra a fost respinsa, vă rugăm să verificați QA.'}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d9534f' }}>
          <Button onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
