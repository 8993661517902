import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const UnauthenticateError = props => {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {props.isEnableUidLogin
            ? 'Please scan User ID or RFID Card to log in'
            : 'Please use your Card to log in'}
        </p>
        <hr />
        <Link
          className={`btn btn-primary btn-sm mt-3 ${
            props.permissionId != process.env.REACT_APP_ADMIN_PERMISSION &&
            'disabled'
          }`}
          to="/"
        >
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </Card.Body>
    </Card>
  );
};

export default UnauthenticateError;
