import axios from 'axios';

const getQualityChecksByStation = async stationId => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/station-quality-checks?stationId=${stationId}`
  );

  try {
    return {
      qualityChecks: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getQualityCheckById = async checkId => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/station-quality-checks/${checkId}`
  );

  try {
    return {
      qualityCheck: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getQualityCheckStepsByCheck = async (checkId, app) => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/station-quality-checks/${checkId}/steps?app=${app}`
  );

  try {
    return {
      steps: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const createStationQc = async qcItem => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/station-quality-checks`,
    method: 'POST',
    data: qcItem
  });

  try {
    return {
      success: res.data.success,
      newQc: res.data.newQc
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateStationQc = async qcItem => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/station-quality-checks`,
    method: 'PATCH',
    data: qcItem
  });

  try {
    return {
      success: res.data.success,
      updatedQualityCheck: res.data.updatedQualityCheck
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteStationQc = async checkId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/station-quality-checks/${checkId}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteStationQcStepsByCheck = async checkId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/station-quality-checks/${checkId}/steps`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const createStationQcStep = async stepItem => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/station-quality-checks/step`,
    method: 'POST',
    data: stepItem
  });

  try {
    return {
      success: res.data.success,
      newStep: res.data.newStep
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateStationQcStep = async step => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/station-quality-checks/step`,
    method: 'PATCH',
    data: step
  });

  try {
    return {
      success: res.data.success,
      updatedStep: res.data.updatedStep
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const deleteStationQcStep = async stepId => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/station-quality-checks/step/${stepId}`,
    method: 'DELETE'
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getUserScanNoFromLastQc = async (userId, qcId) => {
  const res = await axios(
    `${process.env.REACT_APP_API_HOST}/station-quality-checks/scan-no?userId=${userId}&qcId=${qcId}`
  );

  try {
    return {
      scanNo: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const updateTrackingQc = async (userTrackingId, qcId) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/station-quality-checks/tracking?userTrackingId=${userTrackingId}&qcId=${qcId}`,
    method: 'POST'
  });

  try {
    return {
      success: res.data.success,
      newQc: res.data.newQc
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export {
  getQualityChecksByStation,
  getQualityCheckById,
  getQualityCheckStepsByCheck,
  createStationQc,
  updateStationQc,
  createStationQcStep,
  updateStationQcStep,
  deleteStationQcStep,
  getUserScanNoFromLastQc,
  deleteStationQc,
  deleteStationQcStepsByCheck,
  updateTrackingQc
};
