import { React, useEffect, useState } from 'react';
import { getTapeDetails } from 'services/tapeService';
import StationSpinner from 'components/station/common/Spinner';

export default function TapeDetailWidget(props) {
  let barcode = props.barcode;
  const [isLoading, setIsLoading] = useState(false);
  const [tapeType, setTapeType] = useState('UNKNOWN');
  const [glazingMethod, setGlazingMethod] = useState('UNKNOWN');

  useEffect(() => {
    if (barcode != 0) {
      setIsLoading(true);
      getTapeDetails(barcode).then(res => {
        if (res?.success) {
          setTapeType(res?.tapeType == '' ? 'UNKNOWN' : res?.tapeType);
          setGlazingMethod(
            res?.glazingMethod == '' ? 'UNKNOWN' : res?.glazingMethod
          );
          setIsLoading(false);
        }
      });
    } else {
      setTapeType('UNKNOWN');
      setGlazingMethod('UNKNOWN');
    }
  }, [barcode]);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div
      style={{
        backgroundColor:
          glazingMethod === 'GLAZED' || glazingMethod === 'GLAZE IN PANEL'
            ? '#5cb85c'
            : '#d9534f',
        textAlign: 'center'
      }}
    >
      <h3 style={{ color: 'yellow' }}>Tape type: {tapeType}</h3>
      <h3 style={{ color: 'yellow' }}>Glazing method: {glazingMethod}</h3>
    </div>
  );
}
