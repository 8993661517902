import axios from 'axios';

const loginByBarcode = async (barcode, enableUidLogin) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Auth/barcode`,
    method: 'POST',
    data: { barcode, enableUidLogin }
  });

  try {
    return {
      success: true,
      user: res.data.user,
      permission: res.data.permission,
      loginType: res.data.loginType
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const getUserPermissions = async username => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Auth/permissions?username=${username}`
  });

  try {
    return {
      success: true,
      permissions: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const extendSession = async (userId, sessionId) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/Auth/extend-session?userId=${userId}&sessionId=${sessionId}`,
    method: 'POST'
  });

  try {
    return {
      success: res.data.extended,
      user: res.data.user
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export { loginByBarcode, getUserPermissions, extendSession };
