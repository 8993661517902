import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import AccessDenied from 'components/errors/AccessDenied';
import dayjs from 'dayjs';
import { swalToast } from 'helpers/component-utils';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  ListGroup,
  Modal,
  Tab,
  Table,
  Tabs
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Form } from 'react-router-dom';
import { getPermission } from 'redux/slices/permissionSlice';
import {
  createOption,
  deleteOption,
  getOptionsByKey,
  updateOptionName
} from 'services/optionListService';
import Swal from 'sweetalert2';

export default function QualityCheckSettings() {
  const Actions = props => (
    <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
      <Button
        variant="light"
        size="sm"
        className="border-300 me-1 text-600"
        onClick={() => handleEditCheckType(props.optionId, props.optionName)}
      >
        <FontAwesomeIcon icon="edit" />
      </Button>
      <Button
        variant="light"
        size="sm"
        className="border-300 text-600"
        onClick={() => handleDeleteCheckType(props.optionId)}
      >
        <FontAwesomeIcon icon="trash-alt" />
      </Button>
    </div>
  );

  const [loginData, setLoginData] = useState({});
  const [checkTypes, setCheckTypes] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [selectedMeasure, setSelectedMeasure] = useState({});

  const dispatch = useDispatch();
  const permissionId = useSelector(store => store.permission.permissionId);

  useEffect(() => {
    let loggedData = JSON.parse(localStorage.getItem('login-data'));
    if (loggedData) setLoginData(loggedData);

    if (permissionId <= 0) {
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }

    getOptionsByKey('quality.check.type').then(res => {
      setCheckTypes(res.optionLists);
    });
    getOptionsByKey('quality.check.measure').then(res => {
      setMeasures(res.optionLists);
    });
  }, []);

  const openModalNewCheckType = () => {
    Swal.fire({
      title: 'New Check Type',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Save',
      showLoaderOnConfirm: true,
      preConfirm: checkType => {
        let payload = {
          optionKey: 'quality.check.type',
          optionName: checkType,
          createdBy: loginData.userId
        };
        return createOption(payload).then(res => {
          if (res?.success) {
            console.log(res);
            return res;
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.isConfirmed) {
        console.log(result);
        if (result.value.success) {
          let copiedArray = checkTypes.slice();
          copiedArray.push(result.value.newOptionList);
          setCheckTypes(copiedArray);
          swalToast.fire({
            icon: 'success',
            title: 'Successfully',
            background: '#5cb85c',
            iconColor: '#fff',
            color: '#fff'
          });
        }
      }
    });
  };

  const handleEditCheckType = (optionId, optionName) => {
    Swal.fire({
      title: 'Edit check type',
      html: `<input type="text" id="txtTypeNameEdit" class="swal2-input col-sm-10" title="Check type" value="${optionName}">`,
      confirmButtonText: 'Save',
      confirmButtonColor: '#5cb85c',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      width: 600,
      preConfirm: () => {
        let editCheckType = document.getElementById('txtTypeNameEdit').value;
        console.log(editCheckType);
        let payload = {
          optionId: optionId,
          optionKey: 'quality.check.type',
          optionName: editCheckType,
          updatedBy: loginData.userId
        };
        return updateOptionName(payload).then(res => {
          if (res?.success) {
            console.log(res);
            return res;
          }
        });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(result => {
      if (result.isConfirmed) {
        console.log(result);
        if (result.value.success) {
          let updatedCheckTypes = checkTypes.map(type => {
            if (type.optionId === result.value.updatedOption.optionId) {
              return result.value.updatedOption;
            }
            return type;
          });
          setCheckTypes(updatedCheckTypes);
          console.log(updatedCheckTypes);
          swalToast.fire({
            icon: 'success',
            title: 'Successfully',
            background: '#5cb85c',
            iconColor: '#fff',
            color: '#fff'
          });
        }
      }
    });
  };

  const handleDeleteCheckType = optionId => {
    Swal.fire({
      title: 'Delete this check type?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteOption(optionId)
          .then(res => {
            if (res?.success) {
              let updatedCheckTypes = checkTypes.filter(
                t => t.optionId !== optionId
              );
              setCheckTypes(updatedCheckTypes);
              swalToast.fire({
                icon: 'success',
                title: 'Deleted',
                background: '#5cb85c',
                iconColor: '#fff',
                color: '#fff'
              });
            } else {
              swalToast.fire({
                icon: 'error',
                title: 'Failed to delete',
                background: '#d9534f',
                iconColor: '#fff',
                color: '#fff'
              });
            }
          })
          .catch(err => {
            console.log(err);
            swalToast.fire({
              icon: 'error',
              title: 'Error. Something went wrong',
              background: '#d9534f',
              iconColor: '#fff',
              color: '#fff'
            });
          });
      }
    });
  };

  const handleSelectMeasure = measure => {
    console.log(measure);
    setSelectedMeasure(measure);
  };

  let dataRows = [];
  if (checkTypes.length > 0) {
    console.log(checkTypes);
    checkTypes.map((check, i) => {
      dataRows.push(
        <tr className="hover-actions-trigger" key={i}>
          <td>{check.optionName}</td>
          <td>{dayjs(check.created).format('DD/MM/YYYY HH:mm')}</td>
          {/* <td>{check.createdByName}</td> */}
          <td className="w-auto">
            <Actions optionId={check.optionId} optionName={check.optionName} />
          </td>
        </tr>
      );
    });
  } else {
    dataRows.push(
      <tr className="hover-actions-trigger" key={0}>
        <td colSpan={3} className="text-center">
          No check types found
        </td>
      </tr>
    );
  }

  console.log(measures);
  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <div>
      <div className="g-3 mt-1 row">
        <div className="col-lg-12">
          <Card style={{ maxHeight: '100vh' }}>
            <Card.Header>
              <h5 className="mb-0 text-center">Settings</h5>
            </Card.Header>
            <Card.Body>
              <Tabs defaultActiveKey="checkType">
                <Tab eventKey="checkType" title="Check Type">
                  <div className="row pt-3">
                    <div className="text-end">
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        onClick={openModalNewCheckType}
                      >
                        <span className="d-none d-sm-inline-block ms-1">
                          New
                        </span>
                      </IconButton>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th scope="col">Check Type</th>
                            <th scope="col">Created</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>{dataRows}</tbody>
                      </Table>
                    </div>
                  </div>
                </Tab>
                {/* <Tab eventKey="checkMeasure" title="Measures">
                  <div className="row pt-3">
                    <div className="col-sm-4">
                      <Card>
                        <Card.Header>
                          <h5 className="mb-0">Machine List</h5>
                        </Card.Header>
                        <Card.Body style={{ fontSize: '13px' }}>
                          <ListGroup
                            style={{ maxHeight: '70vh', overflowY: 'scroll' }}
                          >
                            {measures.map(item => (
                              <ListGroup.Item
                                key={item.optionId}
                                action
                                href={`#${item.optionName}`}
                                onClick={() => handleSelectMeasure(item)}
                              >
                                {item.optionName}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-sm-4">
                      <Card></Card>
                    </div>
                  </div>
                </Tab> */}
              </Tabs>
            </Card.Body>
          </Card>
        </div>
      </div>
      {/* <Modal
        show={modalNewCheckOpen}
        onHide={() => setModalNewCheckOpen(false)}
        contentClassName="border"
        size="lg"
      >
        <Modal.Header closeButton className="bg-light px-card border-bottom-0">
          <Modal.Title as="h4">New Check Typek</Modal.Title>
        </Modal.Header>
        <Modal.Body 
        // style={{ minHeight: '40vh', maxHeight: '80vh' }}
        >
          <div>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-6">
                <Form.Label>Check type:</Form.Label>
                <Form.Control
                  as="select"
                  name="checkType"
                  value={newCheck.checkType}
                  onChange={handleInputChange}
                  required
                >
                  {checkTypeOptions}
                </Form.Control>
              </Col>
              <Col className="col-lg-6">
                <Button className='btn'></Button>
              </Col>
            </Form.Group>
            </div>
            </Modal.Body>
            </Modal> */}
    </div>
  ) : (
    <AccessDenied></AccessDenied>
  );
}
