import {
  faExclamationTriangle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { swalToastError } from 'helpers/component-utils';
import { React, useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { getAtachmentPathsByPcodes } from 'services/attachmentService';
import '../../assets/scss/user.scss';

export default function ProductCodeAlert(props) {
  let data = props.data;
  let tableRows = [];
  const [modalAlertShow, setModalAlertShow] = useState(props.show);
  const [imgData, setImgData] = useState([]);

  useEffect(() => {
    setModalAlertShow(props.show);
  }, [props.show]);

  let lastKeypressTime = 0;
  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      let currentTime = new Date().getTime();
      console.log(
        'Product Code Changed Enter event time: ' +
          (currentTime - lastKeypressTime)
      );

      if (currentTime - lastKeypressTime < 80) {
        swalToastError(
          'Please confirm product code changed before continue scanning'
        );
      } else {
        setModalAlertShow(false);

        // pop data from queue
        props.setIsCodeChanged(false);
        const newQueue = props.queue;
        newQueue.pop();
        props.updateQueue(newQueue);
      }
    }
    lastKeypressTime = new Date().getTime();
  };

  useEffect(() => {
    props.PopDataOfQueue('isCodeChanged', props.setIsCodeChanged);
  }, [props.queue]);

  useEffect(() => {
    console.log(data);

    if (data.length > 0) {
      getAtachmentPathsByPcodes(data)
        .then(res => {
          if (res?.data) {
            console.log(res?.data);
            if (res?.data.length > 0) {
              const groupedArray = res?.data.sort((a, b) => {
                if (a.pCode < b.pCode) {
                  return -1;
                }
                if (a.pCode > b.pCode) {
                  return 1;
                }
                return 0;
              });
              console.log(groupedArray);

              setImgData([...groupedArray]);
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [data]);

  if (imgData.length > 0) {
    imgData.forEach(function (item, index) {
      tableRows.push(
        <tr key={`row${index + 1}`}>
          <td>{item.pCode}</td>
          <td>
            {item.path ? (
              <img
                src={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${process.env.REACT_APP_BUCKET_NAME}?key=${item.path}`}
                style={{
                  width: `200px`,
                  height: `200px`
                }}
              ></img>
            ) : (
              'No image found'
            )}
          </td>
        </tr>
      );
    });
  }

  const onHide = () => {
    setModalAlertShow(false);

    // pop data from queue
    props.setIsCodeChanged(false);
    const newQueue = props.queue;
    newQueue.pop();
    props.updateQueue(newQueue);
  };

  return (
    <div onKeyUp={e => handleKeyUp(e)} className="product-code-popup">
      <Modal
        show={
          modalAlertShow &&
          props.queue[props.queue.length - 1] === 'isCodeChanged'
        }
        onHide={onHide}
        dialogClassName="modal-60w"
        size="xl"
        backdrop="static"
        autoFocus="true"
      >
        <Modal.Header>
          <Modal.Title>
            Product Code changed - Inny typ listewki - Tipul bitului s-a
            schimbat
          </Modal.Title>
          <span className="blink">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="4x"
              color="red"
            />
          </span>
          <FontAwesomeIcon
            icon={faTimes}
            cursor={'pointer'}
            onClick={() => {
              setModalAlertShow(false);
            }}
          ></FontAwesomeIcon>
        </Modal.Header>
        <Modal.Body>
          {/* <input autoFocus={true} hidden={true}></input> */}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="col-sm-3 text-center">Product Code</th>
                <th className="col-sm-6 text-center">Image</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button id="btnOK" className="btn btn-primary" onClick={onHide}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
