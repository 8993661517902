import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Form,
  Row,
  Button,
  ListGroup,
  Table,
  Modal,
  Dropdown,
  Image
} from 'react-bootstrap';
import { swalToast } from 'helpers/component-utils';
import {
  createStartupCheck,
  deleteStartupCheck,
  getMachines,
  getStartUpChecksByMachine,
  updateCheck
} from 'services/machineService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Flex from 'components/common/Flex';
import { useDropzone } from 'react-dropzone';
import CardDropdown from 'components/common/CardDropdown';
import { getSize } from 'helpers/utils';
import { QRCodeSVG } from 'qrcode.react';
import {
  deleteAttachmentRecord,
  deleteAttachmentsByRecordId,
  deleteMultipleS3Files,
  deleteS3File,
  insertMultiAttachmentRecords,
  uploadMultiFilesToS3
} from 'services/attachmentService';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getPermission } from 'redux/slices/permissionSlice';
import { useDispatch } from 'react-redux';
import AccessDenied from 'components/errors/AccessDenied';

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

export default function MachineStartUpChecks() {
  const Actions = props => (
    <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
      <Button
        variant="light"
        size="sm"
        className="border-300 me-1 text-600"
        onClick={() => handleEditCheck(props.checkId)}
      >
        <FontAwesomeIcon icon="edit" />
      </Button>
      <Button
        variant="light"
        size="sm"
        className="border-300 text-600"
        onClick={() => handleDeleteCheck(props.checkId)}
      >
        <FontAwesomeIcon icon="trash-alt" />
      </Button>
    </div>
  );

  const imgApp = 'start.up.check';
  const [loginData, setLoginData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState({});
  const [startUpChecks, setStartUpChecks] = useState([]);
  const [isModalNewCheckOpen, setIsModalNewCheckOpen] = useState(false);
  const [isModalEditCheckOpen, setIsModalEditCheckOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [editFileRows, setEditFileRows] = useState([]);
  const [check, setCheck] = useState({
    checkCode: '',
    checkDescription: ''
  });
  const [selectedCheck, setSelectedCheck] = useState({});

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    onDrop: acceptedFiles => {
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  const dispatch = useDispatch();
  const permissionId = useSelector(store => store.permission.permissionId);

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  useEffect(() => {
    let loggedData = JSON.parse(localStorage.getItem('login-data'));
    if (loggedData) setLoginData(loggedData);

    if (permissionId <= 0) {
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }

    getMachines().then(res => {
      if (res?.success) {
        setMachines(res.machines);
      }
    });
  }, []);

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleSelectMachine = machine => {
    setSelectedMachine(machine);
  };

  const openModalNewCheck = () => {
    if (selectedMachine && selectedMachine.machineID > 0) {
      setIsModalNewCheckOpen(true);
      console.log(startUpChecks);

      let newCheckCode = '';

      if (startUpChecks.length < 1) {
        newCheckCode =
          'MSC' +
          selectedMachine.machineID.toString().padStart(2, '0') +
          '00001';
      } else {
        const lastCheck = startUpChecks.reduce((prev, current) => {
          return prev.id > current.id ? prev : current;
        });
        const nextCheckNumber =
          Number(
            lastCheck.checkCode.substring(lastCheck.checkCode.length - 5)
          ) + 1;
        console.log(lastCheck, nextCheckNumber);

        newCheckCode =
          'MSC' +
          selectedMachine.machineID.toString().padStart(2, '0') +
          nextCheckNumber.toString().padStart(5, '0');
      }
      console.log(newCheckCode);
      setCheck(prev => ({ ...prev, checkCode: newCheckCode }));
    } else
      swalToast.fire({
        icon: 'error',
        title: 'Please select a machine',
        background: '#d9534f',
        iconColor: '#fff',
        color: '#fff'
      });
  };

  const handleSaveNewCheck = () => {
    if (!check.checkCode) {
      swalToast.fire({
        icon: 'error',
        title: 'Please provide check code',
        background: '#d9534f',
        iconColor: '#fff',
        color: '#fff'
      });
      return;
    }
    if (!check.checkDescription) {
      swalToast.fire({
        icon: 'error',
        title: 'Please provide check description',
        background: '#d9534f',
        iconColor: '#fff',
        color: '#fff'
      });
      return;
    }
    const payload = {
      machineId: selectedMachine.machineID,
      checkCode: check.checkCode,
      checkDescription: check.checkDescription,
      createdBy: loginData.userId
    };
    console.log(payload);
    createStartupCheck(selectedMachine.machineID, payload)
      .then(res => {
        if (res?.success) {
          uploadImagesToS3AndSaveRecords(
            files,
            selectedMachine.machineID,
            res.newCheck.id
          );
        } else {
          swalToast.fire({
            icon: 'error',
            title: 'Failed to add new start-up check',
            background: '#d9534f',
            iconColor: '#fff',
            color: '#fff'
          });
        }
      })
      .catch(err => {
        console.log(err);
        swalToast.fire({
          icon: 'error',
          title: 'Error. Something went wrong',
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
      });
  };

  const getAttachmentKeysByCheckId = checkId => {
    let checkImages = startUpChecks.filter(x => x.id == checkId)[0].images;
    let keys = [];
    if (checkImages) {
      keys = checkImages.map(x => x.path);
    }
    console.log(keys);
    return keys;
  };

  const handleDeleteCheck = checkId => {
    Swal.fire({
      title: 'Delete this check?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteStartupCheck(checkId)
          .then(res => {
            if (res?.success) {
              let updatedChecks = startUpChecks.filter(m => m.id !== checkId);
              setStartUpChecks(updatedChecks);
              swalToast.fire({
                icon: 'success',
                title: 'Deleted',
                background: '#5cb85c',
                iconColor: '#fff',
                color: '#fff'
              });

              const keys = getAttachmentKeysByCheckId(checkId);
              if (keys.length > 0) {
                deleteMultipleS3Files(keys).then(res => {
                  if (res.success) {
                    deleteAttachmentsByRecordId(checkId, imgApp).then(res => {
                      if (!res.success) {
                        swalToast.fire({
                          icon: 'error',
                          title: 'Failed to delete images',
                          background: '#d9534f',
                          iconColor: '#fff',
                          color: '#fff'
                        });
                      }
                    });
                  }
                });
              }
            } else {
              swalToast.fire({
                icon: 'error',
                title: 'Failed to delete check',
                background: '#d9534f',
                iconColor: '#fff',
                color: '#fff'
              });
            }
          })
          .catch(err => {
            console.log(err);
            swalToast.fire({
              icon: 'error',
              title: 'Error. Something went wrong',
              background: '#d9534f',
              iconColor: '#fff',
              color: '#fff'
            });
          });
      }
    });
  };

  const handleEditCheck = checkId => {
    const _check = startUpChecks.filter(x => x.id == checkId)[0];
    setSelectedCheck(_check);

    let rows = [];

    _check.images.map((f, i) => {
      rows.push(
        <tr className="hover-actions-trigger" key={`wfr${i}`}>
          <td>{f.name}</td>
          <td>{dayjs(f.created).format('DD/MM/YYYY HH:mm')}</td>
          <td className="w-auto">
            <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
              <Button
                variant="light"
                size="sm"
                className="border-300 text-600"
                onClick={() => handleDeleteImage(_check.images, f.id, f.path)}
              >
                <FontAwesomeIcon icon="trash-alt" />
              </Button>
            </div>
          </td>
        </tr>
      );
    });

    setEditFileRows(rows);
    setIsModalEditCheckOpen(true);
  };

  const handleDeleteImage = (images, attachmentId, s3Path) => {
    console.log(attachmentId, s3Path);
    Swal.fire({
      title: 'Delete this image?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteS3File(s3Path.replace('s3/eva-files/', ''))
          .then(res => {
            if (res?.success) {
              deleteAttachmentRecord(attachmentId)
                .then(res1 => {
                  if (res1?.success) {
                    let rows = [];
                    let updatedImages = images.filter(
                      x => x.id != attachmentId
                    );
                    if (updatedImages.length > 0) {
                      updatedImages.map((f, i) => {
                        rows.push(
                          <tr className="hover-actions-trigger" key={`wfr${i}`}>
                            <td>{f.name}</td>
                            <td>
                              {dayjs(f.created).format('DD/MM/YYYY HH:mm')}
                            </td>
                            <td className="w-auto">
                              <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
                                <Button
                                  variant="light"
                                  size="sm"
                                  className="border-300 text-600"
                                  onClick={() =>
                                    handleDeleteImage(
                                      updatedImages,
                                      f.id,
                                      f.path
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon="trash-alt" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      });
                    } else {
                      rows.push(
                        <tr className="hover-actions-trigger" key={`wfr0`}>
                          <td colSpan={3}>No images</td>
                        </tr>
                      );
                    }

                    setEditFileRows(rows);

                    swalToast.fire({
                      icon: 'success',
                      title: 'Deleted',
                      background: '#5cb85c',
                      iconColor: '#fff',
                      color: '#fff'
                    });
                  }
                })
                .catch(err => {
                  console.log(err);
                  swalToast.fire({
                    icon: 'error',
                    title: 'Failed to delete',
                    background: '#d9534f',
                    iconColor: '#fff',
                    color: '#fff'
                  });
                });
            }
          })
          .catch(err => {
            console.log(err);
            swalToast.fire({
              icon: 'error',
              title: 'Failed to delete',
              background: '#d9534f',
              iconColor: '#fff',
              color: '#fff'
            });
          });
      }
    });
  };

  const handleSaveCheck = () => {
    const payload = {
      id: selectedCheck.id,
      checkCode: selectedCheck.checkCode,
      checkDescription: selectedCheck.checkDescription,
      updatedBy: loginData.userId
    };

    updateCheck(payload)
      .then(res => {
        if (res?.success) {
          if (files.length > 0) {
            uploadImagesToS3AndSaveRecords(
              files,
              selectedMachine.machineID,
              res.newCheck.id
            );
          } else {
            getStartUpChecksByMachine(selectedMachine.machineID, imgApp).then(
              res => {
                setStartUpChecks(res?.checks);
              }
            );
            clearEditCheckForm();
          }
          swalToast.fire({
            icon: 'success',
            title: 'Check has been updated',
            background: '#5cb85c',
            iconColor: '#fff',
            color: '#fff'
          });
          setIsModalEditCheckOpen(false);
        } else {
          swalToast.fire({
            icon: 'error',
            title: 'Failed to update check',
            background: '#d9534f',
            iconColor: '#fff',
            color: '#fff'
          });
        }
      })
      .catch(err => {
        console.log(err);
        swalToast.fire({
          icon: 'error',
          title: 'Error. Something went wrong',
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
      });
  };

  const uploadImagesToS3AndSaveRecords = (
    uploadedImages,
    machineID,
    recordId
  ) => {
    const formData = new FormData();
    if (uploadedImages.length > 0) {
      uploadedImages.map(item => {
        formData.append('files', item);
      });
    }
    const prefix = `eva-scan-station/startup-checks/${machineID}/${recordId}/`;

    uploadMultiFilesToS3(formData, prefix).then(res => {
      if (res?.success && res.mappings.length > 0) {
        const attachments = [];
        let attachmentItem = {};
        res.mappings.map(item => {
          attachmentItem = {
            name: item.originalName,
            hashedName: item.hashedName,
            type: item.extension,
            size: item.size,
            path: `s3/${process.env.REACT_APP_BUCKET_NAME}/${prefix}${item.hashedName}`,
            recordId: parseInt(recordId),
            recordValue: '',
            data: '',
            imgWidth: 0,
            imgHeight: 0,
            app: imgApp
          };
          attachments.push(attachmentItem);
        });

        if (attachments.length > 0) {
          insertMultiAttachmentRecords(attachments)
            .then(res => {
              if (res?.success) {
                swalToast.fire({
                  icon: 'success',
                  title: 'Attachments have been stored',
                  background: '#5cb85c',
                  iconColor: '#fff',
                  color: '#fff'
                });
                getStartUpChecksByMachine(
                  selectedMachine.machineID,
                  imgApp
                ).then(res => {
                  setStartUpChecks(res?.checks);
                });
                clearNewCheckForm();
              } else {
                swalToast.fire({
                  icon: 'error',
                  title: 'Failed to upload images',
                  background: '#d9534f',
                  iconColor: '#fff',
                  color: '#fff'
                });
              }
            })
            .catch(err => {
              console.log(err);
              swalToast.fire({
                icon: 'error',
                title: 'Error. Something went wrong',
                background: '#d9534f',
                iconColor: '#fff',
                color: '#fff'
              });
            });
        }
      }
    });
  };

  const getOuterHTMLById = elementId => {
    const element = document.getElementById(elementId);
    if (element) {
      return element.outerHTML;
    }
    return null;
  };

  const handlePrintQR = (e, checkId) => {
    e.preventDefault();
    console.log(checkId);
    const printWindow = window.open('', '_blank');
    printWindow.document.write('<html><head><title>QR Code</title>');
    printWindow.document.write(
      `<style>@media print { @page{
        size: A4;
      }
      svg {
        width:60%;
        height: 60%;
      } }</style>`
    );
    printWindow.document.write('</head><body>');

    printWindow.document.write(
      '<div style="display: flex; justify-content: center; align-items: center; height: 100vh;">'
    );
    printWindow.document.write(getOuterHTMLById(`qrcode${checkId}`));
    printWindow.document.write('</div></body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const clearNewCheckForm = () => {
    setIsModalNewCheckOpen(false);
    setFiles([]);
    setCheck({
      checkCode: '',
      checkDescription: ''
    });
  };

  const clearEditCheckForm = () => {
    setIsModalEditCheckOpen(false);
    setEditFileRows([]);
    setFiles([]);
    setSelectedCheck({});
  };

  useEffect(() => {
    if (selectedMachine && selectedMachine.machineID > 0) {
      getStartUpChecksByMachine(selectedMachine.machineID, imgApp).then(res => {
        if (res?.success) {
          console.log(res);
          setStartUpChecks(res.checks);
        }
      });
    }
  }, [selectedMachine]);

  const filteredMachines = machines.filter(item =>
    item.machineName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dataRows = [];

  if (startUpChecks.length > 0) {
    startUpChecks.map((check, i) => {
      dataRows.push(
        <tr className="hover-actions-trigger" key={i}>
          <td>{check.checkCode}</td>
          <td>{check.checkDescription}</td>
          <td>
            <PhotoProvider maskOpacity={0.5}>
              <div className="foo">
                {check.images.map((img, index) => (
                  <PhotoView
                    key={index}
                    src={`${
                      process.env.REACT_APP_FILE_API_HOST
                    }/files/download/${
                      process.env.REACT_APP_BUCKET_NAME
                    }?key=${img.path.replace('s3/eva-files/', '')}`}
                  >
                    <Image
                      key={`img${img.id}`}
                      src={`${
                        process.env.REACT_APP_FILE_API_HOST
                      }/files/download/${
                        process.env.REACT_APP_BUCKET_NAME
                      }?key=${img.path.replace('s3/eva-files/', '')}`}
                      alt=""
                      fluid
                      width={200}
                      height={'auto'}
                      style={{ padding: '5px', cursor: 'pointer' }}
                    ></Image>
                  </PhotoView>
                ))}
              </div>
            </PhotoProvider>
          </td>
          <td>
            <QRCodeSVG
              id={`qrcode${check.id}`}
              value={check.checkCode}
              size={140}
              level={'M'}
              includeMargin={true}
              onClick={e => handlePrintQR(e, check.id)}
              style={{ cursor: 'pointer' }}
            ></QRCodeSVG>
          </td>
          <td className="w-auto">
            <Actions checkId={check.id} />
          </td>
        </tr>
      );
    });
  } else {
    dataRows.push(
      <tr className="hover-actions-trigger" key={0}>
        <td colSpan={5} className="text-center">
          No start-up check found
        </td>
      </tr>
    );
  }

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <>
      <div className="g-3 mt-2 row">
        <div className="col-lg-3">
          <Card>
            <Card.Header>
              <h5 className="mb-0">Machine List</h5>
            </Card.Header>
            <Card.Body style={{ fontSize: '13px' }}>
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <br></br>
              <ListGroup style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                {filteredMachines.map(item => (
                  <ListGroup.Item
                    key={item.machineID}
                    action
                    href={`#${item.machineID}`}
                    onClick={() => handleSelectMachine(item)}
                  >
                    {item.machineName}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-9">
          <Card>
            <Card.Header>
              <h5 className="mb-1">Start-Up Checks</h5>
              <h6>Machine: {selectedMachine.machineName}</h6>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="text-end">
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    onClick={openModalNewCheck}
                  >
                    <span className="d-none d-sm-inline-block ms-1">New</span>
                  </IconButton>
                </div>
              </div>

              <Table hover responsive>
                <thead>
                  <tr>
                    <th scope="col" className="col-sm-2">
                      Check Code
                    </th>
                    <th scope="col" className="col-sm-5">
                      Check Description
                    </th>
                    <th scope="col" className="col-sm-2">
                      Photos
                    </th>
                    <th scope="col" className="col-sm-2">
                      QR Code
                    </th>
                    <th scope="col" className="col-sm-1"></th>
                  </tr>
                </thead>
                <tbody>{dataRows}</tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Modal
        show={isModalNewCheckOpen}
        onHide={() => setIsModalNewCheckOpen(false)}
        contentClassName="border"
        size="lg"
      >
        <Modal.Header closeButton className="bg-light px-card border-bottom-0">
          <Modal.Title as="h4">New Start-up Check</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '70vh', maxHeight: '80vh' }}>
          <div>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-6">
                <Form.Label>Code:</Form.Label>
                <br className="mb-2"></br>
                <small>Will be used to generate QR code for this check.</small>
                <Form.Control
                  name="checkCode"
                  type="text"
                  autoFocus={true}
                  value={check.checkCode}
                  readOnly={true}
                  // onChange={e => {
                  //   setCheck(prev => ({ ...prev, checkCode: e.target.value }));
                  // }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-12">
                <Form.Label>Description:</Form.Label>
                <Form.Control
                  name="checkDescription"
                  as="textarea"
                  rows={3}
                  value={check.checkDescription}
                  onChange={e => {
                    setCheck(prev => ({
                      ...prev,
                      checkDescription: e.target.value
                    }));
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Label>Upload Images (optinal):</Form.Label>
            <div {...getRootProps({ className: 'dropzone-area py-6' })}>
              <input {...getInputProps()} />
              <Flex justifyContent="center">
                <MdOutlineCloudUpload width={20} className="me-2 mt-1" />
                <p className="fs-0 mb-0 text-400">
                  Drop your images here or <u>browse</u>
                </p>
              </Flex>
            </div>
            <div
              className="mt-1"
              style={{ maxHeight: '200px', overflowY: 'auto' }}
            >
              {files.map(file => (
                <Flex
                  alignItems="center"
                  className="py-1 border-bottom btn-reveal-trigger"
                  key={file.path}
                >
                  <Image
                    rounded
                    width={40}
                    height={40}
                    src={file.preview}
                    alt={file.path}
                  />
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className="ms-3 flex-1"
                  >
                    <div>
                      <h6>{file.path}</h6>
                      <Flex className="position-relative" alignItems="center">
                        <p className="mb-0 fs--1 text-400 line-height-1">
                          <strong>{getSize(file.size)}</strong>
                        </p>
                      </Flex>
                    </div>
                  </Flex>
                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleRemove(file.path)}
                      >
                        Remove
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </Flex>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleSaveNewCheck}>
            Save
          </Button>
          <Button className="btn btn-secondary" onClick={clearNewCheckForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit check modal */}
      <Modal
        show={isModalEditCheckOpen}
        onHide={() => setIsModalEditCheckOpen(false)}
        contentClassName="border"
        size="lg"
      >
        <Modal.Header closeButton className="bg-light px-card border-bottom-0">
          <Modal.Title as="h4">Edit Start-up Check</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '70vh', maxHeight: '80vh' }}>
          <div>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-6">
                <Form.Label>Code:</Form.Label>
                <br className="mb-2"></br>
                <small>Will be used to generate QR code for this check.</small>
                <Form.Control
                  name="checkCode"
                  type="text"
                  value={selectedCheck.checkCode}
                  readOnly={true}
                  // onChange={e => {
                  //   setSelectedCheck(prev => ({
                  //     ...prev,
                  //     checkCode: e.target.value
                  //   }));
                  // }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-12">
                <Form.Label>Description:</Form.Label>
                <Form.Control
                  name="checkDescription"
                  as="textarea"
                  autoFocus={true}
                  rows={3}
                  value={selectedCheck.checkDescription}
                  onChange={e => {
                    setSelectedCheck(prev => ({
                      ...prev,
                      checkDescription: e.target.value
                    }));
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col className="col-lg-8">
                <table
                  className="table table-hover"
                  style={{ fontSize: '13px' }}
                >
                  <thead>
                    <tr>
                      <th scope="col" width="60%">
                        Filename
                      </th>
                      <th scope="col" width="30%">
                        Uploaded
                      </th>
                      <th scope="col" width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>{editFileRows}</tbody>
                </table>
              </Col>
            </Form.Group>
            <Form.Label>Upload new images (optinal):</Form.Label>
            <div {...getRootProps({ className: 'dropzone-area py-3' })}>
              <input {...getInputProps()} />
              <Flex justifyContent="center">
                <MdOutlineCloudUpload width={20} className="me-2 mt-1" />
                <p className="fs-0 mb-0 text-400">
                  Drop your images here or <u>browse</u>
                </p>
              </Flex>
            </div>
            <div
              className="mt-1"
              style={{ minHeight: '200px', overflowY: 'auto' }}
            >
              {files.map(file => (
                <Flex
                  alignItems="center"
                  className="py-1 border-bottom btn-reveal-trigger"
                  key={file.path}
                >
                  <Image
                    rounded
                    width={40}
                    height={40}
                    src={file.preview}
                    alt={file.path}
                  />
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className="ms-3 flex-1"
                  >
                    <div>
                      <h6>{file.path}</h6>
                      <Flex className="position-relative" alignItems="center">
                        <p className="mb-0 fs--1 text-400 line-height-1">
                          <strong>{getSize(file.size)}</strong>
                        </p>
                      </Flex>
                    </div>
                  </Flex>
                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleRemove(file.path)}
                      >
                        Remove
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </Flex>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={handleSaveCheck}>
            Save
          </Button>
          <Button className="btn btn-secondary" onClick={clearEditCheckForm}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <AccessDenied></AccessDenied>
  );
}
