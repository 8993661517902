import ActionButton from 'components/common/ActionButton';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import { Fragment, React, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import {
  deleteItemInPack,
  deletePack,
  getCompositeDoorJobDetails,
  getCuttingitemDetails,
  getItemLinkPack,
  getItemsInPack,
  getPacksByHeaderId
} from 'services/cuttingService';
import {
  insertCuttingLog,
  insertListCuttingLog,
  insertTrackingBox
} from 'services/trackingService';
import Swal from 'sweetalert2';
import AncillLabel from './AncillLabel';
import PackLabel from './PackLabel';
import StationSpinner from './common/Spinner';
import { getCurrentUserId } from 'helpers/utils';

export default function CuttingListDetail({
  currentCutting,
  show,
  setIsShowCuttingDetail,
  stationId,
  titleCuttingList,
  startStage,
  completedStage
}) {
  const [cuttingListDetail, setCuttingListDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupByPCode, setGroupByPCode] = useState([]);
  const [isLoadingLabel, setIsLoadingLabel] = useState(false);
  const [lstPrinting, setLstPrinting] = useState([]);
  const [packs, setPacks] = useState([]);
  const [currentPrint, setCurrentPrint] = useState();
  const [currentPrintItem, setCurrentPrintItem] = useState('');
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [pCodePrint, setPCodePrint] = useState([]);
  const [channel, setChannel] = useState();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  useEffect(() => {
    const bc = new BroadcastChannel('lockJob');
    setChannel(bc);

    return () => {
      bc.close();
    };
  }, []);

  const loadCuttingDetail = headerId => {
    setIsLoading(true);
    getCuttingitemDetails(headerId)
      .then(res => {
        if (res && res.data && res.data.length > 0) {
          let totalData = [];
          res.data.map((item, index) => {
            for (let i = 1; i <= item.qTy; i++) {
              totalData.push({ ...item, itemNo: i });
            }
          });
          getItemLinkPack(headerId).then(response => {
            if (response && response.data) {
              const { ancilsCut, packs } = response.data;

              totalData.map(ancil => {
                ancil.boxId = 0;
                const ancilHasBox = packs.filter(
                  pack =>
                    pack.partsItemId === ancil.partsItemId &&
                    pack.itemNo === ancil.itemNo
                );
                if (ancilHasBox && ancilHasBox.length > 0) {
                  ancil.boxId = ancilHasBox[0].boxId;
                }

                ancil.isCut = false;
                const ancilIsCut = ancilsCut.filter(
                  ancilCut =>
                    ancilCut.partsItemId === ancil.partsItemId &&
                    ancilCut.itemNo === ancil.itemNo
                );
                if (ancilIsCut && ancilIsCut.length > 0) {
                  ancil.isCut = true;
                }
              });
            }

            setCuttingListDetail(totalData);
            const groupByPCode = Object.groupBy(
              totalData,
              ({ pCode }) => pCode
            );
            setGroupByPCode(groupByPCode);
          });
        } else {
          setCuttingListDetail([]);
        }
        setLstPrinting([]);
        setIsLoading(false);
      })
      .catch(error => {
        swalToastError('Get Cutting Detail Failed!');
      });
  };

  const loadCompositeDoorJobDetail = headerId => {
    setIsLoading(true);
    getCompositeDoorJobDetails(headerId)
      .then(res => {
        if (res && res.data && res.data.length > 0) {
          const totalData = [];
          res.data.map((item, index) => {
            for (let i = 1; i <= item.qTy; i++) {
              totalData.push({ ...item, itemNo: i });
            }
          });
          getItemLinkPack(headerId).then(response => {
            if (response && response.data) {
              const { ancilsCut, packs } = response.data;

              totalData.map(ancil => {
                ancil.boxId = 0;
                const ancilHasBox = packs.filter(
                  pack =>
                    pack.partsItemId === ancil.partsItemId &&
                    pack.itemNo === ancil.itemNo
                );
                if (ancilHasBox && ancilHasBox.length > 0) {
                  ancil.boxId = ancilHasBox[0].boxId;
                }

                ancil.isCut = false;
                const ancilIsCut = ancilsCut.filter(
                  ancilCut =>
                    ancilCut.partsItemId === ancil.partsItemId &&
                    ancilCut.itemNo === ancil.itemNo
                );
                if (ancilIsCut && ancilIsCut.length > 0) {
                  ancil.isCut = true;
                }
              });
            }

            setCuttingListDetail(totalData);
            const groupByPCode = Object.groupBy(
              totalData,
              ({ pCode }) => pCode
            );
            setGroupByPCode(groupByPCode);
          });
        } else {
          setCuttingListDetail([]);
        }
        setLstPrinting([]);
        setIsLoading(false);
      })
      .catch(error => {
        swalToastError('Get Cutting Detail Failed!');
      });
  };

  const loadPack = headerId => {
    getPacksByHeaderId(headerId)
      .then(res => {
        if (res && res.data) {
          setPacks(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Cutting Detail Failed!');
      });
  };

  useEffect(() => {
    if (currentCutting.headerId) {
      //loading cutting details and packs
      if (titleCuttingList === 'default' || titleCuttingList === 'remake') {
        loadCuttingDetail(currentCutting.headerId);
      } else {
        loadCompositeDoorJobDetail(currentCutting.headerId);
      }
      loadPack(currentCutting.headerId);
    }
  }, [currentCutting]);

  const handleClosePopup = () => {
    channel.postMessage({ message: 'unLock', jobNo: currentCutting.jobNo });
    setIsShowCuttingDetail(false);
    setLstPrinting([]);
    setCurrentPrint();
    setCurrentPrintItem('');
    setPCodePrint([]);
  };

  const handlePrintCutting = async item => {
    setIsLoading(true);
    await setCurrentPrintItem('Ancill');
    await setCurrentPrint(item);
    await setPCodePrint([]);

    let userId = 0;
    const user = JSON.parse(localStorage.getItem('login-data') || '');
    if (user) {
      userId = user.userId;
    }
    const postData = {
      boxId: item.boxId,
      headerId: currentCutting.headerId,
      userId: userId,
      partsItemId: item.partsItemId,
      stationId: stationId,
      itemNo: item.itemNo,
      startStage: startStage,
      completedStage: completedStage
    };

    insertCuttingLog(postData)
      .then(res => {
        handlePrint();
        //loading cutting details and packs
        if (titleCuttingList === 'default' || titleCuttingList === 'remake') {
          loadCuttingDetail(currentCutting.headerId);
        } else {
          loadCompositeDoorJobDetail(currentCutting.headerId);
        }
      })
      .catch(error => {
        swalToastError('Insert Cutting Log Failed');
      });
  };

  const handlePrintPackLabel = async () => {
    await setCurrentPrintItem('Pack');
    await setPCodePrint([]);
    setIsLoadingLabel(true);

    const user = JSON.parse(localStorage.getItem('login-data') || '');

    const trackingBox = {
      headerId: currentCutting.headerId,
      requiredDate: currentCutting.requiredDate,
      userId: user.userId || 0,
      userName: user.userName || '',
      stationId: stationId,
      startStage: startStage,
      completedStage: completedStage
    };

    insertTrackingBox(trackingBox, lstPrinting)
      .then(async res => {
        setIsLoadingLabel(false);
        if (res && res.data && res.success) {
          const currentPack = {
            reference: currentCutting.reference,
            requiredDate: currentCutting.requiredDate,
            mainName: currentCutting.mainName,
            custCode: currentCutting.custCode,
            jobNo: currentCutting.jobNo,
            boxId: res.data
          };
          await setCurrentPrint(currentPack);

          handlePrint();

          //loading cutting details and packs
          if (titleCuttingList === 'default' || titleCuttingList === 'remake') {
            loadCuttingDetail(currentCutting.headerId);
          } else {
            loadCompositeDoorJobDetail(currentCutting.headerId);
          }
          loadPack(currentCutting.headerId);
        }
      })
      .catch(error => {
        swalToastError(`Add Tracking box failed!`);
      });
  };

  const handlePrintAllItemsInGroup = async items => {
    const itemsToSprint = items.filter(item => item.boxId > 0 && !item.isCut);

    if (itemsToSprint.length === 0) {
      swalToastError('Please Assign At Least One Item to Box!');
      return;
    } else {
      setIsLoading(true);
      const userId = getCurrentUserId();
      const postData = [];
      itemsToSprint.length > 0 &&
        itemsToSprint.map(item => {
          if (item.boxId > 0) {
            postData.push({
              boxId: item.boxId,
              headerId: currentCutting.headerId,
              userId: userId,
              partsItemId: item.partsItemId,
              stationId: stationId,
              itemNo: item.itemNo,
              startStage: startStage,
              completedStage: completedStage
            });
          }
        });

      await setCurrentPrintItem('ListAncill');
      await setCurrentPrint();
      await setPCodePrint(itemsToSprint);

      insertListCuttingLog(postData)
        .then(res => {
          handlePrint();
          //loading cutting details and packs
          if (titleCuttingList === 'default' || titleCuttingList === 'remake') {
            loadCuttingDetail(currentCutting.headerId);
          } else {
            loadCompositeDoorJobDetail(currentCutting.headerId);
          }
          setIsLoading(false);
        })
        .catch(error => {
          swalToastError('Insert Cutting Logs Failed');
        });
    }
  };

  const generateGroupByLength = groupByPCode => {
    groupByPCode = groupByPCode.sort((a, b) => {
      if (a.length1 < b.length1) {
        return 1;
      } else if (a.length1 == b.length1) {
        return 0;
      } else {
        return -1;
      }
    });
    return groupByPCode.map((item, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns: '0.2fr 2fr 0.6fr 0.7fr 0.8fr 0.7fr 0.6fr',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
          alignItems: 'center',
          padding: '0 0 0 10px',
          backgroundColor: item.isCut ? '#a7f2ca' : ''
        }}
      >
        <div>
          <Form.Check
            type="checkbox"
            id="checkbox"
            value={item}
            disabled={item.boxId > 0}
            onChange={event => handleCheckedItem(event, item)}
            checked={isItemChecked(item)}
          />
        </div>
        <div>{item.description}</div>
        <div>{1}</div>
        <div>{item.length1}</div>
        <div>{item.weight}</div>
        <div>
          <Button
            style={{ backgroundColor: item.isCut ? '#a7f2ca' : '' }}
            variant="falcon-default"
            className="me-2 my-1"
            onClick={() => handlePrintCutting(item)}
            disabled={item.boxId === 0 || item.isCut}
          >
            Print
          </Button>
        </div>
      </div>
    ));
  };

  const generateGroupByPCode = groupByPCode => {
    return (
      <div>
        <div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '0.2fr 2fr 0.6fr 0.7fr 0.8fr 0.7fr 0.6fr',
              padding: '0 0 0 10px',
              marginBottom: '10px'
            }}
          >
            <div style={{ fontWeight: 600 }}>#</div>
            <div style={{ fontWeight: 600 }}>Description</div>
            <div style={{ fontWeight: 600 }}>Qty</div>
            <div style={{ fontWeight: 600 }}>Length(mm)</div>
            <div style={{ fontWeight: 600 }}>Weight(g)</div>
            <div style={{ fontWeight: 600 }}>Print Ancil Label</div>
            <div>
              <Button
                variant="info"
                onClick={() => handlePrintAllItemsInGroup(groupByPCode)}
                disabled={isDisabledPrintAllItems(groupByPCode)}
              >
                Print all items
              </Button>
            </div>
          </div>

          {groupByPCode && generateGroupByLength(groupByPCode)}
        </div>
      </div>
    );
  };

  const handleCollapsePCode = idx => {
    const element = document.getElementById(`collapsePCode${idx}`);
    if (element) {
      if (element.classList.value.includes('show'))
        element.classList.remove('show');
      else element.classList.add('show');
    }
  };

  const handleDeletePack = (e, pack) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentPrintItem('');
    setPCodePrint([]);
    setCurrentPrint();
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to delete?',
      text: `There ${pack.totalItems > 1 ? 'are' : 'is'} ${
        pack.totalItems
      } item(s) in pack ${pack.boxId}. Are you sure you want to delete?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#d33',
      cancelButtonText: `Cancel`
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true);
        deletePack(pack.boxId)
          .then(res => {
            if (
              titleCuttingList === 'default' ||
              titleCuttingList === 'remake'
            ) {
              loadCuttingDetail(currentCutting.headerId);
            } else {
              loadCompositeDoorJobDetail(currentCutting.headerId);
            }
            const newPacks = packs.filter(p => p.boxId !== pack.boxId);
            setPacks(newPacks);
            swalToastSuccess(`Delete Pack Success`);
          })
          .catch(error => {
            swalToastError(`Delete Pack Failed`);
          });
      }
    });
  };

  const handleDeleteItemInPack = (item, index) => {
    setCurrentPrintItem('');
    setPCodePrint([]);
    setCurrentPrint();
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to delete this ancil?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      confirmButtonColor: '#d33',
      cancelButtonText: `Cancel`
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true);
        deleteItemInPack(item)
          .then(res => {
            if (
              titleCuttingList === 'default' ||
              titleCuttingList === 'remake'
            ) {
              loadCuttingDetail(currentCutting.headerId);
            } else {
              loadCompositeDoorJobDetail(currentCutting.headerId);
            }
            loadPack(currentCutting.headerId);
            swalToastSuccess(`Delete Item In Pack Success`);
          })
          .catch(error => {
            swalToastError(`Delete Item In Pack Failed`);
          });
      }
    });
  };

  const isItemChecked = item => {
    let hasChecked = false;
    lstPrinting.map(i => {
      if (item.partsItemId === i.partsItemId && i.itemNo === item.itemNo) {
        hasChecked = true;
      }
    });
    return hasChecked;
  };

  const handleCheckedItem = (e, item) => {
    if (e.target.checked) {
      setLstPrinting([
        ...lstPrinting,
        {
          headerId: item.headerId,
          partsItemId: item.partsItemId,
          requiredDate: item.requiredDate,
          winNo: item.winNo,
          locationA: item.locationA,
          pCode: item.pCode,
          description: item.description,
          jobNo: item.jobNo,
          length1: item.length1,
          qTy: 1,
          itemNo: item.itemNo
        }
      ]);
    } else {
      const printting = lstPrinting.filter(i => {
        return i.partsItemId != item.partsItemId || i.itemNo != item.itemNo;
      });
      setLstPrinting(printting);
    }
  };

  const handleCheckAll = e => {
    if (e.target.checked) {
      const AllItemChecked = [];
      cuttingListDetail.map(item => {
        if (item.boxId === 0) {
          AllItemChecked.push({
            headerId: item.headerId,
            partsItemId: item.partsItemId,
            requiredDate: item.requiredDate,
            winNo: item.winNo,
            locationA: item.locationA,
            pCode: item.pCode,
            description: item.description,
            jobNo: item.jobNo,
            length1: item.length1,
            qTy: 1,
            itemNo: item.itemNo
          });
        }
      });
      setLstPrinting(AllItemChecked);
      setIsCheckedAll(true);
    } else {
      setLstPrinting([]);
      setIsCheckedAll(false);
    }
  };

  const isCheckAllIsChecked = () => {
    const numberItemsChecked = cuttingListDetail.filter(
      i => i.boxId === 0
    ).length;
    return lstPrinting.length === numberItemsChecked;
  };

  const loadItemInPack = (boxId, index) => {
    const updateItem = packs[index];

    if (updateItem.children) {
      delete updateItem.children;
      setPacks([...packs]);
    } else {
      getItemsInPack(boxId)
        .then(res => {
          updateItem.children = res.data;
          setPacks([...packs]);
        })
        .catch(error => {
          swalToastError('Get Item In Pack Failed!');
        });
    }
  };

  const isDisabledPrintAllItems = groupByPCode => {
    let result = true;
    groupByPCode.map(item => {
      if (!item.isCut) {
        result = false;
      }
    });
    return result;
  };

  return (
    <Modal show={show} dialogClassName="w-75 mw-100">
      <Modal.Header>
        <Modal.Title>
          <h3>
            Job No: {currentCutting.jobNo + ' - ' + currentCutting.mainName}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            minHeight: '50px',
            maxHeight: '600px',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          {isLoading ? (
            <div>
              <div style={{ textAlign: 'center' }}>
                <StationSpinner></StationSpinner>
              </div>
            </div>
          ) : cuttingListDetail.length > 0 &&
            Object.keys(groupByPCode).length > 0 ? (
            <>
              <div
                style={{
                  padding: '0 0 0 10px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Form.Check type="checkbox" id="checkall" style={{ margin: 0 }}>
                  <Form.Check.Input
                    type={'checkbox'}
                    onChange={handleCheckAll}
                    checked={isCheckAllIsChecked()}
                  />
                  <Form.Check.Label style={{ fontSize: 14, margin: 0 }}>
                    {'Check All '}
                  </Form.Check.Label>
                </Form.Check>

                {groupByPCode['COM'] &&
                  groupByPCode['COM'].length > 0 &&
                  groupByPCode['COM'].map((item, index) => (
                    <span
                      key={index}
                      style={{
                        color: '#d9534f',
                        fontSize: 22,
                        marginLeft: '30px'
                      }}
                    >
                      {item.description}
                    </span>
                  ))}
              </div>
              <div>
                {Object.keys(groupByPCode).map((item, idx) => (
                  <div key={idx}>
                    <div
                      className="fst-italic fw-bold"
                      role="button"
                      onClick={() => handleCollapsePCode(idx)}
                      style={{
                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                        paddingTop: '10px',
                        paddingBottom: '10px'
                      }}
                    >
                      {'Product Code: ' + item}
                    </div>
                    <div
                      className="collapse multi-collapse show"
                      id={'collapsePCode' + idx}
                    >
                      {generateGroupByPCode(groupByPCode[item])}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div>
              <div className="text-center">No Record Found</div>
            </div>
          )}
          <div className="row">
            <div className="col-5" style={{ padding: ' 10px 0 0 10px' }}>
              {isLoadingLabel ? (
                <div style={{ textAlign: 'center' }}>
                  <StationSpinner></StationSpinner>
                </div>
              ) : (
                <div className="col-6">
                  <div ref={componentRef}>
                    {currentPrintItem === 'Ancill' && currentPrint && (
                      <AncillLabel cuttingPrint={currentPrint} />
                    )}
                    {currentPrintItem === 'Pack' && currentPrint && (
                      <PackLabel data={currentPrint} />
                    )}
                    {currentPrintItem === 'ListAncill' &&
                      pCodePrint.length > 0 &&
                      pCodePrint.map((item, index) => (
                        <AncillLabel key={index} cuttingPrint={item} />
                      ))}
                  </div>
                </div>
              )}
            </div>

            {packs.length > 0 && (
              <div className="col-7">
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">Pack</th>
                      <th scope="col">items</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody style={{ backgroundColor: '#9bfab4' }}>
                    {packs.map((pack, index) => (
                      <Fragment key={index}>
                        <tr
                          className="cursor-pointer"
                          onClick={() => loadItemInPack(pack.boxId, index)}
                          style={
                            !pack.children
                              ? {
                                  borderBottom: '10px solid',
                                  borderBottomColor: '#fff'
                                }
                              : {}
                          }
                        >
                          <td style={{ fontWeight: 600 }}>{pack.boxId}</td>
                          <td>{pack.totalItems}</td>
                          <td>
                            <ActionButton
                              icon="trash-alt"
                              title="Delete Pack"
                              variant="danger"
                              onClick={e => handleDeletePack(e, pack)}
                            />
                          </td>
                        </tr>
                        {pack.children && pack.children.length > 0 && (
                          <tr
                            style={{
                              borderBottom: '10px solid',
                              borderBottomColor: '#fff'
                            }}
                          >
                            <td colSpan={3}>
                              <Table responsive hover className="mb-0">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{ paddingLeft: '3rem' }}
                                    >
                                      P/Code
                                    </th>
                                    <th scope="col">Qty</th>
                                    <th scope="col">Length(mm)</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody style={{ backgroundColor: '#cfffdb' }}>
                                  {pack.children.map((item, ind) => (
                                    <tr key={ind}>
                                      <td style={{ paddingLeft: '3rem' }}>
                                        {item.pCode}
                                      </td>
                                      <td>{item.qTy}</td>
                                      <td>{item.length}</td>
                                      <td>
                                        <ActionButton
                                          icon="trash-alt"
                                          title="Delete Item"
                                          variant="outline-danger"
                                          onClick={() =>
                                            handleDeleteItemInPack(item, index)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>
        <div
          className="col-6"
          style={{ padding: ' 10px 0 0 10px', display: 'flex' }}
        >
          <Button
            disabled={lstPrinting.length === 0 || isLoadingLabel}
            variant="falcon-default"
            className="col-5 me-2 mb-1"
            onClick={handlePrintPackLabel}
          >
            Print Pack Label
          </Button>
          {isLoadingLabel && (
            <div>
              <StationSpinner></StationSpinner>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClosePopup}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}
