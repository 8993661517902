import StationSpinner from 'components/station/common/Spinner';
import dayjs from 'dayjs';
import {
  swalToast,
  swalToastError,
  swalToastSuccess
} from 'helpers/component-utils';
import { React, useEffect, useRef, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import Moment from 'react-moment';
import { addAlertLog, getRejectLog, getRejectLogs } from 'services/qcService';
import '../../assets/scss/user.scss';

export default function AlertWidget(props) {
  let station = props.station;
  let userId = props.userId;
  let userName = props.userName;

  const qcStage = 17;
  const pageLength = 10;
  const intervalTime = 30000;

  const [rejectLog, setRejectLog] = useState({});
  const [rejectLogs, setRejectLogs] = useState([]);
  const [faultItem, setFaultItem] = useState({
    product: '',
    faultGroup: '',
    faultDesc: '',
    detail: '',
    comment: ''
  });
  const [jobNo, setJobNo] = useState('');
  const [winNo, setWinNo] = useState('');
  const [alertClass, setAlertClass] = useState('shadow-element-yellow');
  const [logModalOpen, setLogModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let rejectRows = [];

  let isRendered = useRef(false);
  const confirmBtnRef = useRef(null);
  let lastKeypressTime = 0;

  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      let currentTime = new Date().getTime();
      console.log(
        'Alert widget Enter event time: ' + (currentTime - lastKeypressTime)
      );

      if (currentTime - lastKeypressTime < 80) {
        // probably a barcode scanner enter
        swalToast.fire({
          icon: 'error',
          title: 'Please confirm reject log berfore continue scanning',
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
      } else {
        // probably a human being enter
        e.preventDefault();
        confirmBtnRef.current.click();
      }
    }

    lastKeypressTime = new Date().getTime();
  };

  useEffect(() => {
    isRendered = true;

    if (userId > 0) {
      checkRejectLog(isRendered);

      const intervalCheck = setInterval(() => {
        checkRejectLog(isRendered);
      }, intervalTime);

      return () => {
        isRendered = false;
        clearInterval(intervalCheck);
      };
    }
  }, [station.id, userId]);

  useEffect(() => {
    if (userId > 0) {
      getRejectLogs(userId, pageLength)
        .then(res => {
          setRejectLogs(res?.rejectLogs);
        })
        .catch(err => {
          console.log(err);
        });
    }

    const intervalGetLogs = setInterval(() => {
      console.log(userId, pageLength);
      if (userId > 0) {
        getRejectLogs(userId, pageLength)
          .then(res => {
            console.log(res);
            setRejectLogs(res?.rejectLogs);
          })
          .catch(err => {
            console.log(err);
          });
      }
    }, intervalTime);
    return () => clearInterval(intervalGetLogs);
  }, [userId]);

  const checkRejectLog = isRendered => {
    getRejectLog()
      .then(res => {
        console.log(res);

        if (res?.rejectLog) {
          console.log('got log');
          if (isRendered) {
            setRejectLog(res.rejectLog);
            setJobNo(res.jobNo);
            setWinNo(res.winNo);

            if (res?.rejectLog.faultItem) {
              setFaultItem(res?.rejectLog.faultItem);
            }
            console.log(station.completedStage, res.rejectLog.stageId, qcStage);
            if (
              station.completedStage >= res.rejectLog.stageId &&
              station.completedStage < qcStage
            ) {
              setIsLoading(true);

              if (
                station.id == res.rejectLog.stationId ||
                station.completedStage == res.rejectLog.stageId
              ) {
                setAlertClass('shadow-element-red');
              }

              setLogModalOpen(true);

              setIsLoading(false);
            }
          }
        } else {
          //console.log('got no log');
          setLogModalOpen(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setLogModalOpen(false);
  };

  const handleConfirmLog = () => {
    const payload = {
      rejectId: rejectLog.id,
      userId: userId,
      stageId: station.completedStage,
      userName: userName,
      stageName: station.completedStageName
    };

    addAlertLog(payload)
      .then(res => {
        //console.log(res);
        if (res?.success) {
          setLogModalOpen(false);
          swalToastSuccess(`Alert has been logged`);
        } else {
          swalToastError(`Failed to log alert`);
        }
      })
      .catch(err => {
        console.log(err);
        swalToastError(`Error. Something went wrong`);
      });
  };

  if (rejectLogs && rejectLogs.length > 0) {
    rejectRows = [];
    rejectLogs.forEach(function (item, index) {
      let logRowClass = item.acknowledged ? 'bg-success' : 'bg-danger';
      rejectRows.push(
        <tr
          key={'row' + index}
          className={logRowClass}
          style={{ color: 'white' }}
        >
          <td className="text-start">{item.jobNo}</td>
          <td className="text-center">{item.frameId}</td>
          <td className="text-start">
            <b>
              {item.productType} - {item.product} - {item.faultGroup}-{' '}
              {item.faultDesc} - {item.detail} - {item.comment} -{' '}
              {item.decision}
            </b>
          </td>
          <td className="text-start">
            {<Moment format="DD/MM/yyyy HH:mm:ss" date={item.created}></Moment>}
          </td>
          <td className="text-start">{item.userName}</td>
          <td className="text-center">{item.acknowledged ? 'Yes' : 'No'}</td>
        </tr>
      );
    });
  } else {
    rejectRows.push(
      <tr key={'row0'}>
        <td colSpan={6}>No reject found.</td>
      </tr>
    );
  }

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div
      onKeyUp={e => handleKeyUp(e)}
      tabIndex={-1}
      className="reject-alert-popup"
    >
      <Modal
        show={logModalOpen}
        onHide={handleClose}
        contentClassName="logModal"
        size="lg"
        backdrop="static"
      >
        <Modal.Header className="bg-light px-card border-bottom-0 alert-modal">
          <Modal.Title as="h4">
            Reject Log found on Job No. {jobNo} - Win No. {winNo}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="alert-modal-body">
          <div className={alertClass}>
            <ol className="list-group list-group-numbered">
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Product Type</div>
                  {faultItem.productType}
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Product</div>
                  {faultItem.product}
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Fault Group</div>
                  {faultItem.faultGroup}
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Fault Description</div>
                  {faultItem.faultDesc}
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Detail</div>
                  {faultItem.detail}
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Comment</div>
                  {faultItem.comment}
                </div>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Decision</div>
                  {rejectLog.decision}
                </div>
              </li>
            </ol>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-primary"
            onClick={handleConfirmLog}
            ref={confirmBtnRef}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <h6>Last update: {dayjs().format('DD/MM/YYYY HH:mm:ss')}</h6>
      <Table className="h6 table-sm">
        <thead>
          <tr style={{ backgroundColor: '#FFFAA0' }}>
            <th>Job No</th>
            <th>Frame ID</th>
            <th>Fault</th>
            <th>Reject Time</th>
            <th>Reject By</th>
            <th>Acknowledged</th>
          </tr>
        </thead>
        <tbody>{rejectRows}</tbody>
      </Table>
    </div>
  );
}
