import elogo from 'assets/img/Emplas/eva-logo.png';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({ at, width, className, textClass, ...rest }) => {
  return (
    <>
      {rest.loginData &&
      rest.loginData.permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
        <Link
          to="/"
          className={
            'mx-0 ' +
            classNames(
              'text-decoration-none',
              { 'navbar-brand text-left': at === 'navbar-vertical' },
              { 'navbar-brand text-left': at === 'navbar-top' }
            )
          }
          {...rest}
        >
          <div
            className={classNames(
              'd-flex',
              {
                'align-items-center py-3': at === 'navbar-vertical',
                'align-items-center': at === 'navbar-top',
                'flex-center fw-bolder fs-5 mb-4': at === 'auth'
              },
              className
            )}
          >
            <img src={elogo} alt="Logo" width={width} />
            <span className={classNames('font-sans-serif', textClass)}></span>
          </div>
        </Link>
      ) : (
        <div
          className={
            'mx-0 ' +
            classNames(
              'text-decoration-none',
              { 'navbar-brand text-left': at === 'navbar-vertical' },
              { 'navbar-brand text-left': at === 'navbar-top' }
            )
          }
          {...rest}
        >
          <div
            className={classNames(
              'd-flex',
              {
                'align-items-center py-3': at === 'navbar-vertical',
                'align-items-center': at === 'navbar-top',
                'flex-center fw-bolder fs-5 mb-4': at === 'auth'
              },
              className
            )}
          >
            <img className="me-2" src={elogo} alt="Logo" width={width} />
            <span className={classNames('font-sans-serif', textClass)}></span>
          </div>
        </div>
      )}
    </>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
