import axios from 'axios';

const addQcLog = async logItem => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/station-quality-checks/log`,
    method: 'POST',
    data: logItem
  });

  try {
    return {
      success: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export { addQcLog };
