import React, { useEffect, useState } from 'react';

export default function SessionTimeClock(props) {
  const lastLoginTime = props.lastLoginTime;

  const calculateTimeLeft = () => {
    const minutesSessionExpired = props.minutesSessionExpired * 60;
    const timeWasLogged = (Date.now() - new Date(lastLoginTime)) / 1000;

    return Math.floor(minutesSessionExpired - timeWasLogged);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (lastLoginTime) {
      const timerId = setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        if (newTimeLeft) {
          setTimeLeft(newTimeLeft);
        }

        if (newTimeLeft <= 0) {
          clearInterval(timerId); // Stop the interval
          props.onSessionExpired();
        }
      }, 1000);

      // const checkSessionTimerId = setInterval(() => {
      //   const loggedData = localStorage.getItem('login-data');
      //   //console.log(JSON.parse(loggedData));
      //   if (!loggedData) {
      //     clearInterval(timerId);
      //     props.onSessionExpired();
      //   } else {
      //     props.onAutoExpandSession();
      //   }
      // }, 300000);

      return () => {
        clearInterval(timerId);
        //clearInterval(checkSessionTimerId);
      };
    } else {
      setTimeLeft(0);
    }
  }, [lastLoginTime, props.minutesSessionExpired]);

  // Calculate minutes and seconds
  const minutes =
    Math.floor(timeLeft / 60) < 10
      ? '0' + Math.floor(timeLeft / 60)
      : Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60 < 10 ? '0' + (timeLeft % 60) : timeLeft % 60;
  const timer = minutes + ':' + seconds;

  let textColor = 'text-success';
  if (minutes < 10) textColor = 'text-warning';
  if (minutes < 5) textColor = 'text-danger';

  return (
    <div className={textColor} style={{ fontSize: '20px', fontWeight: 600 }}>
      Session expired: {timeLeft <= 0 ? '00:00' : timer}
    </div>
  );
}
