import dayjs from 'dayjs';
import { React } from 'react';
import Barcode from 'react-barcode';

export default function PackLabel({ data }) {
  const getBardCode = boxId => {
    if (boxId < 1000000) {
      return 'BID000' + boxId;
    } else if (boxId >= 1000000 && boxId < 10000000) {
      return 'BID00' + boxId;
    } else if (boxId >= 10000000 && boxId < 100000000) {
      return 'BID0' + boxId;
    } else if (boxId >= 100000000 && boxId < 1000000000) {
      return 'BID' + boxId;
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        fontSize: 16,
        width: '380px',
        height: '143px',
        background: '#fff',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '-53px',
          transform: 'rotate(90deg)',
          background: '#000',
          color: '#fff',
          borderRadius: '0 0 5px 5px',
          width: '140px',
          height: '33px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold'
        }}
      >
        {dayjs(data.requiredDate).format('DD/MM/YYYY')}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          margin: '0 40px',
          width: '100%',
          textAlign: 'center'
        }}
      >
        <div>
          <div style={{ color: '#000', fontWeight: 'bold' }}>
            {data.mainName}
          </div>
          <div style={{ color: '#000', fontWeight: 'bold' }}>
            {data.reference}
          </div>
        </div>
        <div>
          <Barcode
            value={getBardCode(data.boxId)}
            height={40}
            width={2}
            fontSize={16}
            font="bold"
          />
        </div>
        <div className="d-flex justify-content-center">
          <div
            style={{
              textAlign: 'center',
              background: '#000',
              color: '#fff',
              width: 'fit-content',
              padding: '0 25px',
              borderRadius: '5px',
              fontWeight: 'bold'
            }}
          >
            Box {data.boxId}
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          right: '-53px',
          transform: 'rotate(90deg)',
          background: '#000',
          color: '#fff',
          borderRadius: '5px 5px 0 0',
          width: '140px',
          height: '33px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold'
        }}
      >
        {data.jobNo}
      </div>
    </div>
  );
}
