import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {
  insertAttachmentRecord,
  uploadFileToS3
} from 'services/attachmentService';
import { toast } from 'react-toastify';
import { swalToast } from 'helpers/component-utils';

// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType);

export default function UploadZone(props) {
  const [files, setFiles] = useState([]);
  const filterItemId = props.filterItemId;
  const group = props.group;
  const pcode = props.pcode;

  const toastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
  };

  const onFilesUpdated = selectedFiles => {
    setFiles([...selectedFiles]);
  };

  const onUploaded = isSuccess => {
    props.handleUploaded(isSuccess);
  };

  const handleUpload = () => {
    if (files.length < 1) {
      swalToast.fire({
        icon: 'error',
        title: `Please select a product image`,
        background: '#d9534f',
        iconColor: '#fff',
        color: '#fff'
      });
      return;
    }

    console.log(files[0].file);

    const folderPrefix = `eva-scan-station/photos/${group}/${filterItemId}/`;

    const formData = new FormData();
    formData.append('file', files[0].file);

    uploadFileToS3(formData, folderPrefix).then(res => {
      if (res?.success) {
        var attachmentItem = {
          name: res.mapping.originalName,
          hashedName: res.mapping.hashedName,
          type: files[0].file.type,
          size: files[0].file.size,
          path: `s3/${process.env.REACT_APP_BUCKET_NAME}/${folderPrefix}${res.mapping.hashedName}`,
          recordId: filterItemId,
          recordValue: group,
          data: pcode.replace('%', ''),
          imgWidth: 50,
          imgHeight: 50,
          app: 'product.image'
        };

        insertAttachmentRecord(attachmentItem).then(res1 => {
          if (res1?.success) {
            swalToast.fire({
              icon: 'success',
              title: `${res.message}`,
              background: '#5cb85c',
              iconColor: '#fff',
              color: '#fff'
            });
            onUploaded(res1?.success);
          }
        });
      }
    });
  };

  return (
    <>
      <FilePond
        files={files}
        allowReorder={true}
        allowMultiple={false}
        allowFileTypeValidation={true}
        acceptedFileTypes={['image/*']}
        onupdatefiles={onFilesUpdated}
        labelIdle='Drag & Drop your image or <span class="filepond--label-action">Browse</span>'
      />
      <Button className="btn btn-primary" onClick={handleUpload}>
        Upload
      </Button>
    </>
  );
}
