import ActionButton from 'components/common/ActionButton';
import IconButton from 'components/common/IconButton';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import {
  addStationPCSettings,
  deleteStationPCSettings,
  editStationPCSettings,
  getStationPCSettings,
  getStations
} from 'services/stationService';
import Swal from 'sweetalert2';
import { SlCheck, SlClose } from 'react-icons/sl';

const defaultPCSetting = {
  pcName: '',
  stationName: '',
  stationLink: '',
  enabled: false
};

const StationsPCSetting = () => {
  const [pcSettings, setPcSettings] = useState([]);
  const [pc, setPc] = useState(defaultPCSetting);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState('');
  const [stations, setStations] = useState([]);

  const getData = () => {
    getStationPCSettings(search)
      .then(res => {
        if (res && res.data) {
          setPcSettings(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Station PC Setting Failed');
      });
  };

  useEffect(() => {
    getData();

    getStations().then(res => {
      setStations(res.stations);
    });
  }, []);

  const onClickEdit = item => {
    setPc(item);
    setShowModal(true);
  };

  const onClickDelete = item => {
    Swal.fire({
      title: `Are You Sure Delete PC Setting ${item.pcName}?`,
      text: "You Won't Be Able To Revert This!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete It!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteStationPCSettings(item.id)
          .then(res => {
            if (res && res.data) {
              swalToastSuccess('Delete Station PC Setting Success');
              getData();
            }
          })
          .catch(error => {
            swalToastError('Delete Station PC SettingFailed');
          });
      }
    });
  };

  const handleChangeInput = e => {
    if (e.target.name === 'enabled') {
      setPc({
        ...pc,
        [e.target.name]: e.target.checked
      });
    } else {
      setPc({
        ...pc,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleChangeStation = e => {
    setPc({
      ...pc,
      stationLink: e.target.value,
      stationName:
        stations.find(item => item.stationPath == e.target.value).stationName ||
        ''
    });
  };

  const onSubmit = () => {
    if (pc.id) {
      editStationPCSettings(pc.id, pc)
        .then(res => {
          swalToastSuccess('Edit Station PC Setting Success');
          getData();
        })
        .catch(error => {
          swalToastError('Edit Station PC Setting Failed ');
        });
    } else {
      addStationPCSettings(pc)
        .then(res => {
          swalToastSuccess('Add Station PC Setting Success');
          getData();
        })
        .catch(error => {
          swalToastError('Add Station PC Setting Failed ');
        });
    }
    setShowModal(false);
  };

  const onChangSearch = e => {
    setSearch(e.target.value);
  };

  const onSearch = () => {
    getData();
  };

  return (
    <>
      <div className="g-3 mt-2 row">
        <div className="col-lg-11">
          <Card>
            <Card.Header>
              <div>
                <h5 className="mb-0">Scan Station PC Setting List</h5>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="d-flex align-items-center gap-5 ">
                <Col className="d-flex align-items-center gap-4 col-5">
                  <Form.Label>Search:</Form.Label>
                  <Form.Control
                    type="text"
                    value={search}
                    onChange={onChangSearch}
                    size="xl"
                  ></Form.Control>
                </Col>
                <Col>
                  <Button variant="primary" onClick={onSearch}>
                    Search
                  </Button>
                </Col>
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() => {
                    setPc(defaultPCSetting);
                    setShowModal(true);
                  }}
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    New PC Setting
                  </span>
                </IconButton>
              </div>
              <div className="mt-2">
                <Table responsive>
                  <thead>
                    <tr>
                      <th scope="col">PC Name</th>
                      <th scope="col">Station Name</th>
                      <th scope="col">Station Link</th>
                      <th scope="col">Enabled</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pcSettings.length > 0 &&
                      pcSettings.map((item, index) => (
                        <tr key={index}>
                          <td>{item.pcName}</td>
                          <td>{item.stationName}</td>
                          <td>{item.stationLink}</td>
                          <td>
                            {item.enabled ? (
                              <SlCheck className="fs-3 text-success" />
                            ) : (
                              <SlClose className="fs-3 text-danger" />
                            )}
                          </td>
                          <td>
                            <div className="d-flex align-item-center">
                              <ActionButton
                                color="red"
                                icon="edit"
                                title="Edit"
                                variant="action"
                                className="p-0 me-3 text-primary"
                                size="lg"
                                onClick={() => onClickEdit(item)}
                              />
                              <ActionButton
                                color="red"
                                icon="trash-alt"
                                title="Delete"
                                variant="action"
                                className="p-0 text-danger"
                                size="lg"
                                onClick={() => onClickDelete(item)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Station PC Setting Translation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Col>
              <Form.Label>PC Name</Form.Label>
              <Form.Control
                autoFocus
                name="pcName"
                type="text"
                value={pc?.pcName}
                onChange={handleChangeInput}
              ></Form.Control>
            </Col>

            <Col>
              <Form.Label>Station</Form.Label>
              <Form.Control
                as={'select'}
                name="station"
                onChange={handleChangeStation}
                defaultValue={pc?.stationLink}
              >
                <option>Select Station Link</option>
                {stations.length > 0 &&
                  stations.map((item, index) => (
                    <option key={index} value={item.stationPath}>
                      {item.stationName}
                    </option>
                  ))}
              </Form.Control>
            </Col>

            <Col className="mt-3">
              <Form.Check
                inline
                label="Enabled"
                name="enabled"
                type="checkbox"
                id={`enabled`}
                checked={pc?.enabled}
                onChange={handleChangeInput}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit}>
            Save
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default StationsPCSetting;
