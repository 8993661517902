import { configureStore } from '@reduxjs/toolkit';
import stationReducer from './slices/stationSlice';
import permissionReducer from './slices/permissionSlice';
import widgetSlice from './slices/widgetSlice';

export const store = configureStore({
  reducer: {
    station: stationReducer,
    widget: widgetSlice,
    permission: permissionReducer
  }
});
