import axios from 'axios';

const getLastestCheckByMachine = async machineId => {
  try {
    const res = await axios(
      `${process.env.REACT_APP_API_HOST}/machine-check-logs/lastest/${machineId}`
    );

    return {
      success: true,
      lastCheck: res.data
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const logMachineCheck = async payload => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/machine-check-logs`,
    method: 'POST',
    data: payload
  });

  try {
    return {
      success: res.data.success,
      logId: res.data.logId
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

const resolveIssueCheck = async (payload, app) => {
  const res = await axios({
    url: `${process.env.REACT_APP_API_HOST}/machine-check-logs?app=${app}`,
    method: 'PATCH',
    data: payload
  });

  try {
    return {
      success: res.data.success,
      checks: res.data.checks
    };
  } catch (err) {
    if (err.response) {
      // Request made but the server responded with an error
      console.log('ERR', err);
      return { message: err.response.data.message, success: false };
    } else if (err.request) {
      // Request made but no response is received from the server.
    } else {
      // Error occured while setting up the request
    }
  }
};

export { getLastestCheckByMachine, logMachineCheck, resolveIssueCheck };
