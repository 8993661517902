import { React, useState, useEffect } from 'react';
import { Button, Form, Row, Col, Modal, Table } from 'react-bootstrap';
import {
  getFilterItemsByGroup,
  getProductImagesByGroup
} from 'services/filterService';
import StationSpinner from './common/Spinner';
import UploadZone from './common/UploadZone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  deleteAttachmentRecord,
  deleteS3File,
  updateAttachmentImgDisplaySize
} from 'services/attachmentService';
import { toast } from 'react-toastify';
import { swalToast } from 'helpers/component-utils';

export default function ProductImageTable(props) {
  const group = props.group;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedPCode, setSelectedPCode] = useState('');
  const [selectedAttId, setSelectedAttId] = useState(0);
  const [selectedFilterItemId, setSelectedFilterItemId] = useState(0);
  const [selectedS3FileKey, setSelectedS3FileKey] = useState('');
  const [modalUploadShow, setModalUploadShow] = useState(false);
  const [modalConfirmDeleteShow, setModalConfirmDeleteShow] = useState(false);
  const [modalDisplaySizeShow, setModalDisplaySizeShow] = useState(false);

  const [displaySize, setDisplaySize] = useState({
    attachmentId: 0,
    imgWidth: 0,
    imgHeight: 0
  });
  let tableRows = [];

  useEffect(() => {
    if (group != '') {
      getItems(group);
    }
  }, [group]);

  const toastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark'
  };

  const getItems = () => {
    console.log(group);

    setIsLoading(true);

    getProductImagesByGroup(group, 'product.image').then(res => {
      console.log(res);

      setTableData(res?.imageItems);
      setIsLoading(false);
    });
  };

  const handleSelectPCode = (e, filterItemId, pcode) => {
    setModalUploadShow(true);
    setSelectedPCode(pcode);
    setSelectedFilterItemId(filterItemId);

    //console.log(filterItemId);
  };

  const handleSelectPCodeToDelete = (e, id, pcode, s3Key) => {
    setModalConfirmDeleteShow(true);
    setSelectedPCode(pcode);
    setSelectedAttId(id);
    setSelectedS3FileKey(s3Key);
  };

  const handleDeleteImage = () => {
    deleteS3File(selectedS3FileKey).then(res => {
      console.log(res);
      if (res?.success) {
        deleteAttachmentRecord(selectedAttId).then(res1 => {
          if (res1?.success) {
            swalToast.fire({
              icon: 'success',
              title: `Image has been deleted`,
              background: '#5cb85c',
              iconColor: '#fff',
              color: '#fff'
            });
            setModalConfirmDeleteShow(false);
            getItems(group);
          }
        });
      } else {
        swalToast.fire({
          icon: 'error',
          title: `Failed to delete image`,
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
      }
    });
  };

  const onImageUploaded = success => {
    if (success) {
      setModalUploadShow(false);
      getItems(group);
    }
  };

  const handleOpenDisplaySize = (id, width, height) => {
    setModalDisplaySizeShow(true);

    setDisplaySize({
      attachmentId: id,
      imgWidth: width,
      imgHeight: height
    });
  };

  const handleInputChange = e => {
    setDisplaySize({ ...displaySize, [e.target.name]: e.target.value });
  };

  const handleChangeImgDisplaySize = e => {
    updateAttachmentImgDisplaySize(displaySize).then(res => {
      if (res?.success) {
        swalToast.fire({
          icon: 'success',
          title: `Display size has been updated`,
          background: '#5cb85c',
          iconColor: '#fff',
          color: '#fff'
        });
        setModalDisplaySizeShow(false);
        getItems(group);
      }
    });
  };

  if (tableData.length > 0) {
    tableData.forEach(function (item, index) {
      tableRows.push(
        <tr key={`row${index + 1}`}>
          <td className="text-center">{item.productCode}</td>
          <td>
            {item.attachmentId > 0 ? (
              <img
                src={`${process.env.REACT_APP_FILE_API_HOST}/files/download/${
                  process.env.REACT_APP_BUCKET_NAME
                }?key=${item.s3Path.replace('s3/eva-files/', '')}`}
                style={{
                  width: `${item.imgWidth}px`,
                  height: `${item.imgHeight}px`
                }}
              ></img>
            ) : (
              ''
            )}
          </td>
          <td className="d-flex justify-content-center align-item-center gap-1">
            {item.attachmentId > 0 ? (
              <>
                <Button
                  className="btn btn-sm btn-info px-2"
                  onClick={e =>
                    handleOpenDisplaySize(
                      item.attachmentId,
                      item.imgWidth,
                      item.imgHeight
                    )
                  }
                >
                  Display Size
                </Button>
                <Button
                  className="btn btn-sm btn-danger px-2"
                  onClick={e =>
                    handleSelectPCodeToDelete(
                      e,
                      item.attachmentId,
                      item.productCode,
                      item.s3Path
                    )
                  }
                >
                  Delete
                </Button>
              </>
            ) : (
              <Button
                className="btn btn-sm btn-primary"
                onClick={e =>
                  handleSelectPCode(e, item.filterItemId, item.productCode)
                }
              >
                Upload
              </Button>
            )}
          </td>
        </tr>
      );
    });
  } else {
    tableRows.push(
      <tr key="row0">
        <td colSpan={3}>No product code found</td>
      </tr>
    );
  }

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <div className="pt-4">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="col-sm-2 text-center">Product Code</th>
            <th className="col-sm-6 text-center">Image</th>
            <th className="col-sm-4 text-center">Action</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>
      <Modal
        show={modalUploadShow}
        dialogClassName="modal-30w"
        size="xl"
        animation={false}
        autoFocus={false}
      >
        <Modal.Header>
          <Modal.Title>Image Upload for: {selectedPCode}</Modal.Title>
          <FontAwesomeIcon
            icon={faTimes}
            cursor={'pointer'}
            onClick={() => {
              setModalUploadShow(false);
            }}
          ></FontAwesomeIcon>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-2">Image:</div>
            <div className="col-sm-10">
              <UploadZone
                filterItemId={selectedFilterItemId}
                pcode={selectedPCode}
                group={group}
                handleUploaded={onImageUploaded}
              ></UploadZone>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10"></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setModalUploadShow(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalConfirmDeleteShow}
        dialogClassName="modal-30w"
        size="lg"
        animation={false}
        autoFocus={false}
      >
        <Modal.Header>
          <Modal.Title>Delete {selectedPCode} Image</Modal.Title>
          <FontAwesomeIcon
            icon={faTimes}
            cursor={'pointer'}
            onClick={() => {
              setModalConfirmDeleteShow(false);
            }}
          ></FontAwesomeIcon>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure to delete this product image?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleDeleteImage}>
            Yes
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setModalConfirmDeleteShow(false);
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalDisplaySizeShow}
        dialogClassName="modal-30w"
        size="sm"
        animation={false}
        autoFocus={false}
      >
        <Modal.Header>
          <Modal.Title>Image Display Size</Modal.Title>
          <FontAwesomeIcon
            icon={faTimes}
            cursor={'pointer'}
            onClick={() => {
              setModalDisplaySizeShow(false);
            }}
          ></FontAwesomeIcon>
        </Modal.Header>
        <Modal.Body>
          <Form
            autoComplete="off"
            noValidate
            // validated={validated}
            // onSubmit={handleSubmit}
          >
            <Form.Group as={Row} className="mb-3">
              <Form.Label>Width:</Form.Label>
              <Col className="col-lg-6">
                <Form.Control
                  name="imgWidth"
                  type="number"
                  autoFocus={true}
                  required
                  value={displaySize.imgWidth}
                  onChange={e => handleInputChange(e)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label>Height:</Form.Label>
              <Col className="col-lg-6">
                <Form.Control
                  name="imgHeight"
                  type="number"
                  autoFocus={true}
                  required
                  value={displaySize.imgHeight}
                  onChange={e => handleInputChange(e)}
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary"
            onClick={handleChangeImgDisplaySize}
          >
            Save
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setModalDisplaySizeShow(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
