import { React, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useState } from 'react';
import { checkGlassGeorgianImgPath } from 'services/glassService';
import StationSpinner from 'components/station/common/Spinner';

export default function GlassGeorgianWidget(props) {
  let barcode = props.barcode;

  const [imgPath, setImgPath] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (barcode) {
      setIsLoading(true);

      checkGlassGeorgianImgPath(barcode).then(res => {
        if (res?.success) {
          if (!res?.missing) {
            setImgPath(
              `${process.env.REACT_APP_API_HOST}/Panel/georgian-glass-image?imgPath=${res?.path}`
            );
          } else {
            setImgPath('missing');
          }
        }
        setIsLoading(false);
      });
    } else {
      setImgPath('');
    }
  }, [barcode]);

  console.log(imgPath);

  return isLoading ? (
    <StationSpinner></StationSpinner>
  ) : (
    <>
      {imgPath != '' && imgPath != 'missing' ? (
        <Image
          src={imgPath}
          className="center-block"
          width={300}
          height={300}
          alt="georgian lead image"
          unoptimized="true"
        ></Image>
      ) : (
        ''
      )}
    </>
  );
}
