import { React, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function PatioWarning({
  isShowPatioWarning,
  setIsShowPatioWarning,
  PopDataOfQueue,
  queue,
  updateQueue
}) {
  useEffect(() => {
    PopDataOfQueue('isShowPatioWarning', setIsShowPatioWarning);
  }, [queue]);

  const handleClose = () => {
    setIsShowPatioWarning(false);
    const newQueue = queue;
    newQueue.pop();
    updateQueue(newQueue);
  };

  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      handleClose();
    }
  };

  return (
    <div onKeyUp={e => handleKeyUp(e)} className="product-code-popup">
      <Modal show={isShowPatioWarning} size="xl">
        <Modal.Header style={{ backgroundColor: '#d9534f' }}>
          <Modal.Title
            style={{
              textDecoration: 'underline',
              width: '100%',
              textAlign: 'center',
              color: '#fff',
              fontSize: 28
            }}
          >
            WARNING
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ backgroundColor: '#d9534f', fontSize: 24, color: '#fff' }}
        >
          <div>
            <div style={{ marginBottom: '20px' }}>
              This is a PAS024 Patio. Please check and fit the correct parts
            </div>
            <div style={{ marginBottom: '20px' }}>
              To jest patio PAS024. Sprawdź i zamontuj odpowiednie części
            </div>
            <div>
              Acesta este un patio PAS024. Vă rugăm să verificați și să
              potriviți piesele corecte
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: '#d9534f' }}>
          <Button onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
