import ActionButton from 'components/common/ActionButton';
import AccessDenied from 'components/errors/AccessDenied';
import { swalToastError, swalToastSuccess } from 'helpers/component-utils';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPermission } from 'redux/slices/permissionSlice';
import {
  addPartsTranslate,
  deletePartsTranslate,
  editPartsTranslate,
  getPartsTranslate
} from 'services/partService';
import Swal from 'sweetalert2';

const defaultSsDescription = {
  pcode: '',
  ssDescription: ''
};

const PartTranslation = () => {
  const dispatch = useDispatch();
  const permissionId = useSelector(store => store.permission.permissionId);

  useEffect(() => {
    if (permissionId <= 0) {
      let loggedData = JSON.parse(localStorage.getItem('login-data'));
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }
  }, []);

  const [ssDescription, setSsDescription] = useState(defaultSsDescription);
  const [ssDescriptionEditing, setSsDescriptionEditing] =
    useState(defaultSsDescription);
  const [itemEditting, setItemEditting] = useState();
  const [validated, setValidated] = useState(false);
  const [search, setSearch] = useState('');
  const [partsTranslates, setPartsTranslates] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = e => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (!ssDescription.pcode) {
      swalToastError("PCode can't be blank");
      return;
    }
    if (!ssDescription.ssDescription) {
      swalToastError("Description can't be blank");
      return;
    }

    if (form.checkValidity()) {
      addPartsTranslate(ssDescription)
        .then(res => {
          if (res.data) {
            swalToastSuccess('Add Scan Station Description Success!');
            loadData(search);
          } else {
            swalToastError('PCode Is Duplicated. Please Try Again!');
          }
        })
        .catch(error => {
          swalToastError('Add Scan Station Description Falied!');
        });
    } else {
      setValidated(true);
    }
  };

  const handleInputChange = e => {
    setSsDescription({
      ...ssDescription,
      [e.target.name]: e.target.value
    });
  };
  const handleInputChangeEditing = e => {
    setSsDescriptionEditing({
      ...ssDescriptionEditing,
      [e.target.name]: e.target.value
    });
  };

  const clearInputValues = () => {
    setSsDescription(defaultSsDescription);
  };

  const loadData = search => {
    getPartsTranslate(search)
      .then(res => {
        if (res && res.data) {
          setPartsTranslates(res.data);
        }
      })
      .catch(error => {
        swalToastError('Get Parts Translate Failed');
      });
  };

  useEffect(() => {
    loadData(search);
  }, []);

  const onSearchPartsTranslate = () => {
    loadData(search);
  };

  const onChangSearch = e => {
    setSearch(e.target.value);
  };

  const onSubmitEdit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!ssDescriptionEditing.ssDescription) {
      swalToastError("Description can't be blank");
      return;
    }

    editPartsTranslate(itemEditting, ssDescriptionEditing)
      .then(res => {
        if (res.data) {
          swalToastSuccess('Edit Scan Station Description Success!');
          setShowModal(false);
          loadData(search);
          setSsDescriptionEditing(defaultSsDescription);
        }
      })
      .catch(error => {
        swalToastError('Edit Scan Station Description Falied!');
      });
  };

  const onClickEdit = item => {
    setSsDescriptionEditing({
      ...ssDescriptionEditing,
      pcode: item.pCode,
      ssDescription: item.ssDescription
    });
    setItemEditting(item.id);
    setShowModal(true);
  };

  const onClickDelete = item => {
    Swal.fire({
      title: `Are You Sure Delete Product code ${item.pCode} ?`,
      text: "You Won't Be Able To Revert This!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete It!'
    }).then(result => {
      if (result.isConfirmed) {
        deletePartsTranslate(item.id)
          .then(res => {
            if (res && res.data) {
              swalToastSuccess('Delete Part Translation Success');
              loadData(search);
            }
          })
          .catch(error => {
            swalToastError('Delete Part Translation Failed');
          });
      }
    });
  };

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <div>
      <div className="g-3 mt-2 row">
        <div className="col-lg-11">
          <Card>
            <Card.Header>
              <h5 className="mb-0">New Scan Station Description</h5>
            </Card.Header>
            <Card.Body>
              <Form
                autoComplete="off"
                noValidate
                // validated={validated}
                onSubmit={handleSubmit}
              >
                <Form.Group as={Row} className="mb-3">
                  <Col className="col-lg-4">
                    <Form.Label>PCode</Form.Label>
                    <Form.Control
                      name="pcode"
                      type="text"
                      autoFocus={true}
                      value={ssDescription.pcode}
                      onChange={handleInputChange}
                    ></Form.Control>
                  </Col>

                  <Col className="col-lg-6">
                    <Form.Label>Scan Station Description</Form.Label>
                    <Form.Control
                      name="ssDescription"
                      type="text"
                      value={ssDescription.ssDescription}
                      onChange={handleInputChange}
                    ></Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Col className="d-flex justify-content-end gap-4">
                    <Button size="lg" variant="primary" type="submit">
                      Save
                    </Button>
                    <Button
                      size="lg"
                      variant="secondary"
                      onClick={clearInputValues}
                    >
                      Clear
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="g-3 mt-2 row">
        <div className="col-lg-11">
          <Card>
            <Card.Header>
              <div>
                <h5 className="mb-0">Scan Station Description List</h5>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="d-flex align-items-center gap-5 ">
                <Col className="d-flex align-items-center gap-4 col-5">
                  <Form.Label>Search:</Form.Label>
                  <Form.Control
                    type="text"
                    value={search}
                    onChange={onChangSearch}
                    size="xl"
                  ></Form.Control>
                </Col>
                <Col>
                  <Button variant="primary" onClick={onSearchPartsTranslate}>
                    Search
                  </Button>
                </Col>
              </div>
              <div className="mt-2">
                <Table responsive>
                  <thead>
                    <tr>
                      <th scope="col">Pcode</th>
                      <th scope="col">SS Description</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partsTranslates.length > 0 &&
                      partsTranslates.map((item, index) => (
                        <tr key={index}>
                          <td>{item.pCode}</td>
                          <td>{item.ssDescription}</td>
                          <td>
                            <ActionButton
                              color="red"
                              icon="edit"
                              title="Edit"
                              variant="action"
                              className="p-0 me-3"
                              size="lg"
                              onClick={() => onClickEdit(item)}
                            />
                            <ActionButton
                              color="red"
                              icon="trash-alt"
                              title="Delete"
                              variant="action"
                              className="p-0"
                              size="lg"
                              onClick={() => onClickDelete(item)}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Part Translation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-3">
            <Col className="col-lg-4">
              <Form.Label>PCode</Form.Label>
              <Form.Control
                name="pcode"
                type="text"
                value={ssDescriptionEditing.pcode}
                onChange={handleInputChangeEditing}
              ></Form.Control>
            </Col>

            <Col className="col-lg-7">
              <Form.Label>Scan Station Description</Form.Label>
              <Form.Control
                autoFocus={true}
                name="ssDescription"
                type="text"
                value={ssDescriptionEditing.ssDescription}
                onChange={handleInputChangeEditing}
              ></Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSubmitEdit}>
            Save
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  ) : (
    <AccessDenied></AccessDenied>
  );
};

export default PartTranslation;
