import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStations } from 'services/stationService';
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { SlCheck, SlClose } from 'react-icons/sl';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

const StationTable = () => {
  const IsNotRFIDLogin = () => {
    const user = JSON.parse(localStorage.getItem('login-data') || '');
    if (user && user.loginType !== 'RFID') {
      return true;
    }
    return false;
  };
  let dropSelections = [];
  const [stations, setStations] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  useEffect(() => {
    getStations(search).then(res => {
      setStations(res.stations);
    });
  }, []);

  const onSearch = () => {
    getStations(search).then(res => {
      setStations(res.stations);
    });
  };

  const onChangSearch = e => {
    setSearch(e.target.value);
  };

  const handleRedirect = item => {
    navigate(process.env.REACT_APP_STATION_PATH + item.stationPath);
  };

  return (
    <div className="g-3 row">
      <Card>
        <Card.Header>
          <div>
            <h5 className="mb-0">Scan Stations List</h5>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-items-center gap-5 ">
            <Col className="d-flex align-items-center gap-4 col-5">
              <Form.Label>Search:</Form.Label>
              <Form.Control
                type="text"
                value={search}
                onChange={onChangSearch}
                size="xl"
              ></Form.Control>
            </Col>
            <Col>
              <Button variant="primary" onClick={onSearch}>
                Search
              </Button>
            </Col>
          </div>
          <div className="mt-2">
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">Station Name</th>
                  <th scope="col">Station Link</th>
                  <th scope="col">Enabled</th>
                  <th scope="col">Created</th>
                </tr>
              </thead>
              <tbody>
                {stations.length > 0 &&
                  stations.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Link to={`/station/${item.stationPath}`}>
                          {item.stationName}
                        </Link>
                      </td>
                      <td>{item.stationPath}</td>
                      <td>
                        {item.enableUidLogin ? (
                          <SlCheck className="fs-3 text-success" />
                        ) : (
                          <SlClose className="fs-3 text-danger" />
                        )}
                      </td>
                      <td>
                        <Moment
                          format="DD/MM/yyyy HH:mm"
                          date={item.created}
                        ></Moment>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StationTable;
