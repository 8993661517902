import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Button, Table } from 'react-bootstrap';
import {
  createMachine,
  deleteMachine,
  getMachineById,
  getMachines,
  updateMachine
} from 'services/machineService';
import { swalToast } from 'helpers/component-utils';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPermission } from 'redux/slices/permissionSlice';
import AccessDenied from 'components/errors/AccessDenied';
import { useSelector, useDispatch } from 'react-redux';

export default function MachineSettings() {
  const Actions = props => (
    <div className="end-0 top-50 pe-3 translate-middle-y hover-actions">
      <Button
        variant="light"
        size="sm"
        className="border-300 me-1 text-600"
        onClick={() => handleEditMachine(props.machineId)}
      >
        <FontAwesomeIcon icon="edit" />
      </Button>
      <Button
        variant="light"
        size="sm"
        className="border-300 text-600"
        onClick={() => handleDeleteMachine(props.machineId)}
      >
        <FontAwesomeIcon icon="trash-alt" />
      </Button>
    </div>
  );
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({});
  const [machines, setMachines] = useState([]);
  const [newMachineNumber, setNewMachineNumber] = useState('');
  const [newMachineName, setNewMachineName] = useState('');
  const [selectedMachine, setSelectedMachine] = useState({});
  const permissionId = useSelector(store => store.permission.permissionId);

  useEffect(() => {
    let loggedData = JSON.parse(localStorage.getItem('login-data'));
    if (loggedData) setLoginData(loggedData);
    if (permissionId <= 0) {
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }

    getMachines().then(res => {
      //console.log(res);
      if (res?.success) {
        setMachines(res.machines);
      }
    });
  }, []);

  const handleEditMachine = id => {
    getMachineById(id).then(res => {
      if (res?.success && res?.machine) {
        Swal.fire({
          title: 'Edit machine',
          html: `<input type="number" id="txtMachineNumberEdit" class="swal2-input col-sm-2" title="Machine number" value="${res.machine.machineNumber}">
            <input type="text" id="txtMachineNameEdit" class="swal2-input col-sm-8" title="Machine name" value="${res.machine.machineName}">`,
          confirmButtonText: 'Save',
          confirmButtonColor: '#5cb85c',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          allowOutsideClick: true,
          width: 1200,
          preConfirm: () => {
            let updatedNumber = document.getElementById(
              'txtMachineNumberEdit'
            ).value;
            let updatedName =
              document.getElementById('txtMachineNameEdit').value;

            if (!updatedNumber) {
              Swal.showValidationMessage(`Please enter Machine number`);
            } else {
              if (
                machines.some(m => m.machineNumber == updatedNumber) &&
                res.machine.machineNumber != updatedNumber
              ) {
                Swal.showValidationMessage(
                  `Machine number ${updatedNumber} is exists. Please try another number`
                );
              }
            }
            if (!updatedName) {
              Swal.showValidationMessage(`Please enter Machine name`);
            }
            return { machineNumber: updatedNumber, machineName: updatedName };
          }
        }).then(result => {
          if (result.isConfirmed) {
            let payload = {
              machineID: id,
              machineNumber: result.value.machineNumber,
              machineName: result.value.machineName,
              updatedBy: loginData.userId
            };
            //console.log(payload);
            updateMachine(payload).then(res => {
              if (res?.success && res?.newMachine) {
                let updatedMachines = machines.map(m => {
                  if (m.machineID === res.newMachine.machineID) {
                    return res.newMachine;
                  }
                  return m;
                });
                setMachines(
                  updatedMachines.sort(
                    (a, b) => a.machineNumber - b.machineNumber
                  )
                );
                swalToast.fire({
                  icon: 'success',
                  title: 'Successfully',
                  background: '#5cb85c',
                  iconColor: '#fff',
                  color: '#fff'
                });
              }
            });
          }
        });
      }
    });
  };

  const handleDeleteMachine = id => {
    Swal.fire({
      title: 'Delete this machine?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#5cb85c',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        deleteMachine(id)
          .then(res => {
            if (res?.success) {
              let updatedMachines = machines.filter(m => m.machineID !== id);
              setMachines(updatedMachines);
              swalToast.fire({
                icon: 'success',
                title: 'Deleted',
                background: '#5cb85c',
                iconColor: '#fff',
                color: '#fff'
              });
            } else {
              swalToast.fire({
                icon: 'error',
                title: 'Failed to delete',
                background: '#d9534f',
                iconColor: '#fff',
                color: '#fff'
              });
            }
          })
          .catch(err => {
            console.log(err);
            swalToast.fire({
              icon: 'error',
              title: 'Error. Something went wrong',
              background: '#d9534f',
              iconColor: '#fff',
              color: '#fff'
            });
          });
      }
    });
  };

  const submitNewMachine = () => {
    if (!newMachineNumber) {
      swalToast.fire({
        icon: 'error',
        title: 'Please type in Machine number',
        background: '#d9534f',
        iconColor: '#fff',
        color: '#fff'
      });
      return;
    } else {
      if (machines.some(m => m.machineNumber == newMachineNumber)) {
        swalToast.fire({
          icon: 'error',
          title: `Machine number ${newMachineNumber} is exists. Please try another number`,
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
        return;
      }
    }
    if (!newMachineName) {
      swalToast.fire({
        icon: 'error',
        title: 'Please type in Machine name',
        background: '#d9534f',
        iconColor: '#fff',
        color: '#fff'
      });
      return;
    }

    let payload = {
      machineNumber: newMachineNumber,
      machineName: newMachineName,
      createdBy: loginData.userId
    };
    //console.log(payload);

    createMachine(payload)
      .then(res => {
        //console.log(res);
        if (res?.success && res.newMachine) {
          swalToast.fire({
            icon: 'success',
            title: 'New machine has been added successfully',
            background: '#5cb85c',
            iconColor: '#fff',
            color: '#fff'
          });
          let updatedMachines = [...machines, res.newMachine].sort(
            (a, b) => a.machineNumber - b.machineNumber
          );
          setMachines(updatedMachines);
          setNewMachineNumber('');
          setNewMachineName('');
        } else {
          swalToast.fire({
            icon: 'error',
            title: 'Failed. Please try again',
            background: '#d9534f',
            iconColor: '#fff',
            color: '#fff'
          });
        }
      })
      .catch(err => {
        console.log(err);
        swalToast.fire({
          icon: 'error',
          title: 'Error. Something went wrong',
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
      });
  };

  const dataRows = [];

  if (machines.length > 0) {
    machines.map((m, i) => {
      dataRows.push(
        <tr className="hover-actions-trigger" key={i}>
          <td>{m.machineNumber}</td>
          <td>{m.machineName}</td>
          <td className="w-auto">
            <Actions machineId={m.machineID} />
          </td>
        </tr>
      );
    });
  } else {
    dataRows.push(
      <tr className="hover-actions-trigger" key={0}>
        <td colSpan={3} className="text-center">
          No machine found.
        </td>
      </tr>
    );
  }

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <div>
      <div className="g-3 mt-2 row">
        <div className="col-lg-10">
          <Card>
            <Card.Header>
              <h5 className="mb-0">New Machine</h5>
            </Card.Header>
            <Card.Body style={{ paddingTop: '0.25rem' }}>
              <Form.Group as={Row} className="mb-2">
                <Form.Label>Machine Number:</Form.Label>
                <Col className="col-lg-3">
                  <Form.Control
                    name="machineNumber"
                    type="number"
                    autoFocus={true}
                    required
                    value={newMachineNumber}
                    onChange={e => {
                      setNewMachineNumber(e.target.value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-2">
                <Form.Label>Machine Name:</Form.Label>
                <Col className="col-lg-7">
                  <Form.Control
                    name="machineName"
                    type="text"
                    required
                    value={newMachineName}
                    onChange={e => {
                      setNewMachineName(e.target.value);
                    }}
                  />
                </Col>
                <Col className="col-lg-2">
                  <Button
                    className="btn btn-primary"
                    onClick={submitNewMachine}
                  >
                    Add
                  </Button>
                </Col>
              </Form.Group>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="g-3 mt-2 row">
        <div className="col-lg-10">
          <Card>
            <Card.Header>
              <h5 className="mb-0">Machine List</h5>
            </Card.Header>
            <Card.Body style={{ paddingTop: '0.25rem' }}>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th scope="col">Machine Number</th>
                    <th scope="col">Machine Name</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>{dataRows}</tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <AccessDenied></AccessDenied>
  );
}
