import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { swalToast, swalToastError } from 'helpers/component-utils';
import { React, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';

export default function NewAndCriticalAlert(props) {
  const btnRef = useRef(null);

  useEffect(() => {
    if (
      props.show &&
      props.queue[props.queue.length - 1] === 'showNewCriticalAlert' &&
      btnRef
    ) {
      setTimeout(() => {
        btnRef?.current?.focus();
      }, 200);
    }
  }, [props.show, props.queue]);

  let lastKeypressTime = 0;
  const handleKeyUp = e => {
    e.stopPropagation();
    e.preventDefault();
    let currentTime = new Date().getTime();
    console.log(
      'Glazing Forward Manufacture Enter event time: ' +
        (currentTime - lastKeypressTime)
    );

    if (currentTime - lastKeypressTime < 80) {
      swalToastError(
        'Please confirm Glazing Forward Manufacture before continue scanning'
      );
    } else {
      props.setShowNewCriticalAlert(false);
      const newQueue = props.queue;
      newQueue.pop();
      props.updateQueue(newQueue);
    }
    lastKeypressTime = new Date().getTime();
  };

  useEffect(() => {
    props.PopDataOfQueue('showNewCriticalAlert', props.setShowNewCriticalAlert);
  }, [props.queue]);

  return (
    <Modal
      show={
        props.show &&
        props.queue[props.queue.length - 1] === 'showNewCriticalAlert'
      }
      dialogClassName="modal-60w"
      size="xl"
      animation={false}
    >
      <Modal.Header style={{ backgroundColor: '#B22222' }}>
        <Modal.Title style={{ textAlign: 'center', margin: 'auto' }}>
          <h2 style={{ color: 'white' }}>ATTENTION</h2>
        </Modal.Title>
        <span className="blink">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="4x"
            color="white"
          />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: '50px', textAlign: 'center' }}>
          <h2>New and Critical Customer</h2>
          <br></br>
          <h2>Nowy i Krytyczny Klient</h2>
          <br></br>
          <h2>Client Nou și Critic</h2>
          <br></br>
          <h3></h3>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <input
          type="button"
          className="btn btn-primary"
          value={'Confirm'}
          onClick={e => handleKeyUp(e)}
          ref={btnRef}
        />
      </Modal.Footer>
    </Modal>
  );
}
