import AccessDenied from 'components/errors/AccessDenied';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPermission } from 'redux/slices/permissionSlice';
import StationTable from './StationTable';

const StationManager = () => {
  const dispatch = useDispatch();
  const permissionId = useSelector(store => store.permission.permissionId);

  useEffect(() => {
    if (permissionId <= 0) {
      let loggedData = JSON.parse(localStorage.getItem('login-data'));
      if (loggedData && loggedData.isLogged)
        dispatch(getPermission(loggedData.permissionId));
    }
  }, []);

  return permissionId == process.env.REACT_APP_ADMIN_PERMISSION ? (
    <StationTable />
  ) : (
    <AccessDenied></AccessDenied>
  );
};

export default StationManager;
