import { swalToast } from 'helpers/component-utils';
import React, { useEffect, useState } from 'react';
import { Button, Carousel, Image, Modal } from 'react-bootstrap';
import { getStartUpChecksByMachine } from 'services/machineService';
import '../../assets/scss/user.scss';
import {
  getLastestCheckByMachine,
  logMachineCheck,
  resolveIssueCheck
} from 'services/machineCheckLogService';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { sendEmail } from 'services/mailService';
import { FaArrowRight } from 'react-icons/fa';
import { useCallback } from 'react';

export default function StartUpCheck(props) {
  const station = props.station;
  const loginData = props.loginData;
  const manual = props.manual;

  const [checks, setChecks] = useState([]);
  const [currentCheck, setCurrentCheck] = useState({
    checkCode: '',
    checkDescription: '',
    images: []
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalCheckOpen, setIsModalCheckOpen] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [isCorrectQrCode, setIsCorrectQrCode] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [logId, setLogId] = useState(0);
  const tryFocus = () => {
    const input = document.getElementById('qrCodeInput');
    if (input) {
      input.focus();
    }
  };

  useEffect(() => {
    if (!station) return;

    if (props.manual && station.machineId < 1) {
      swalToast.fire({
        icon: 'warning',
        title: 'Machine not assigned to this station',
        background: '#d9534f',
        iconColor: '#fff',
        color: '#fff'
      });
      props.onNoStartUpCheck();
      return;
    }

    if (!loginData || loginData.userId < 1) return;

    if (props.station.machineId < 1) return;

    checkTimeAtStartOfNextHour();

    return () => {
      clearTimeout();
    };
  }, [props.station, props.manual]);

  const checkTimeAtStartOfNextHour = () => {
    const currentTime = new Date();
    const minutes = currentTime.getMinutes();
    let promptStartUpChecks = false;

    console.log('do check: ' + currentTime.getHours() + ':' + minutes);
    getLastestCheckByMachine(station.machineId).then(res => {
      if (res?.success) {
        const lastCheck = res.lastCheck;
        if (lastCheck && !lastCheck.checkSuccess && !lastCheck.issueResolved) {
          // freeze station
          Swal.fire({
            icon: 'warning',
            title: 'ISSUE REPORT!',
            text: 'Please do NOT use this machine and scan station!',
            confirmButtonText: 'Issue Fixed?',
            confirmButtonColor: '#5cb85c',
            keydownListenerCapture: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            color: '#d9534f',
            iconColor: '#d9534f',
            width: '80vh',
            height: '70vh',
            didOpen: () => {
              setQrCodeValue('');
              Swal.getConfirmButton().focus();
            }
          }).then(result => {
            if (result.isConfirmed) {
              const payload = {
                logId: lastCheck.logId,
                machineId: station.machineId,
                resolvedBy: loginData.userId
              };
              console.log(payload);
              resolveIssueCheck(payload, 'start.up.check').then(res => {
                console.log(res.checks[0]);
                if (res?.success) {
                  swalToast.fire({
                    icon: 'success',
                    title: 'Issue has been resolved. Starting re-check...',
                    background: '#5cb85c',
                    iconColor: '#fff',
                    color: '#fff'
                  });
                  setChecks(res?.checks);
                  setCurrentCheck(res?.checks[0]);
                  setCurrentStep(0);
                  setIsScanning(false);
                  setIsCorrectQrCode(false);
                  setIsModalCheckOpen(true);
                  props.updateQueue('isShowStatupCheck');
                }
              });
            }
          });
        }

        const hours = currentTime.getHours();
        const lastTimeCheck =
          parseInt(currentTime - new Date(lastCheck.checked)) /
          (1000 * 60 * 60);
        // Check if the current time matches the desired hours
        if (
          (hours === 6 || hours === 14 || hours === 22) &&
          (lastCheck.checkSuccess || lastCheck.issueResolved) &&
          lastTimeCheck >= 7
        ) {
          promptStartUpChecks = true;
        } else {
          promptStartUpChecks = false;
        }

        // if (seconds === 0) {
        //   const minutes = currentTime.getMinutes();

        //   // Check if the current time matches the desired hours
        //   if ((minutes === 17 || minutes === 44) && lastCheck.checkSuccess) {
        //     promptStartUpChecks = true;
        //   } else {
        //     promptStartUpChecks = false;
        //   }
        // }
        // else{
        //   if()
        // }

        console.log(
          'promptStartUpChecks: ' + promptStartUpChecks,
          'manual: ' + props.manual
        );
        if (promptStartUpChecks || props.manual) {
          getStartUpChecksByMachine(station.machineId, 'start.up.check').then(
            res => {
              //console.log(res);
              if (res?.success) {
                if (res.checks.length > 0) {
                  setChecks(res.checks);
                  setCurrentStep(0);
                  setCurrentCheck(res.checks[0]);
                  setIsModalCheckOpen(true);
                  props.updateQueue('isShowStatupCheck');
                }
              }
            }
          );
        }

        // Schedule the next check at the start of the next hour
        if (!props.manual) {
          const nextHour = new Date();
          nextHour.setHours(nextHour.getHours() + 1);
          nextHour.setMinutes(0);
          nextHour.setSeconds(0);
          const timeUntilNextHour = nextHour - currentTime;
          console.log(timeUntilNextHour);
          setTimeout(checkTimeAtStartOfNextHour, timeUntilNextHour);
        }
      }
    });
  };

  const handleCheckOK = () => {
    //console.log(currentStep);
    //console.log(currentCheck);
    console.log(isCorrectQrCode);
    if (!isCorrectQrCode) {
      return;
    }

    if (currentStep < checks.length) {
      //console.log(qrCodeValue);

      if (!qrCodeValue) {
        swalToast.fire({
          icon: 'error',
          title: 'Please scan QR Code',
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
        return;
      } else {
        if (
          qrCodeValue.toLocaleLowerCase() !=
          currentCheck.checkCode.toLocaleLowerCase()
        ) {
          swalToast.fire({
            icon: 'error',
            title: 'Invalid QR Code',
            background: '#d9534f',
            iconColor: '#fff',
            color: '#fff'
          });
          return;
        } else {
          // log check to db
          var payload = {
            stationId: station.id,
            machineId: station.machineId,
            checkId: currentCheck.id,
            checkSuccess: true,
            checkedBy: loginData.userId
          };
          console.log(payload);
          logMachineCheck(payload)
            .then(res => {
              console.log(res);
              if (res?.success) {
                let nextStep = currentStep;
                nextStep++;
                if (nextStep >= checks.length) {
                  swalToast.fire({
                    icon: 'success',
                    title: 'Start Up Checks Completed',
                    background: '#5cb85c',
                    iconColor: '#fff',
                    color: '#fff'
                  });
                  setIsScanning(false);
                  setQrCodeValue('');
                  setIsModalCheckOpen(false);
                  setIsCorrectQrCode(false);
                  handleCloseCheck();
                } else {
                  setCurrentStep(nextStep);
                  setCurrentCheck(checks[nextStep]);
                  setQrCodeValue('');
                  setIsCorrectQrCode(false);
                  setIsScanning(false);
                }
              } else {
                swalToast.fire({
                  icon: 'error',
                  title: 'Failed to log check',
                  background: '#d9534f',
                  iconColor: '#fff',
                  color: '#fff'
                });
              }
            })
            .catch(err => {
              console.log(err);
              swalToast.fire({
                icon: 'error',
                title: 'Error. Something went wrong',
                background: '#d9534f',
                iconColor: '#fff',
                color: '#fff'
              });
            });
        }
      }
    } else {
      console.log(currentStep);

      swalToast.fire({
        icon: 'success',
        title: 'Start Up Checks Completed',
        background: '#5cb85c',
        iconColor: '#fff',
        color: '#fff'
      });
      setIsModalCheckOpen(false);
    }
  };

  // const handleKeyDown = event => {
  //   console.log('modal check enter');
  //   console.log(isScanning);
  //   if (event.keyCode == 13) {
  //     handleCheckOK();
  //   }
  // };

  const handleReportIssues = () => {
    var payload = {
      stationId: station.id,
      machineId: station.machineId,
      checkId: currentCheck.id,
      checkSuccess: false,
      checkedBy: loginData.userId
    };
    //console.log(payload);
    logMachineCheck(payload)
      .then(res => {
        //console.log(res);
        if (res?.success) {
          setIsModalCheckOpen(false);
          setChecks([]);
          setCurrentStep(0);
          setLogId(res.logId);

          // send email
          const email = {
            subject: 'Machine Issue Report',
            app: 'machine-issue-report',
            // to: 'dawid.hajduk@emplas.co.uk',
            // cc: 'trung.tran@emplas.co.uk, thien.pham@emplas.co.uk, quang.dinh@emplas.co.uk',
            body: `Machine issue reported at ${
              station.stationName
            } station by ${loginData.userName} - ${dayjs().format(
              'DD/MM/YYYY HH:mm'
            )}`
          };

          sendEmail(email).then(res => {
            console.log(res);
          });

          Swal.fire({
            icon: 'warning',
            title: 'ISSUE REPORT!',
            text: 'Please do NOT use this machine and scan station!',
            confirmButtonText: 'Issue Fixed?',
            confirmButtonColor: '#5cb85c',
            keydownListenerCapture: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            color: '#d9534f',
            iconColor: '#d9534f',
            width: '80vh',
            height: '70vh',
            didOpen: () => {
              setQrCodeValue('');
              // handleCloseCheck();
              props.onReportIssue();
              Swal.getConfirmButton().focus();
            }
          }).then(result => {
            console.log(result);
            if (result.isConfirmed) {
              const payload = {
                logId: res.logId,
                machineId: station.machineId,
                resolvedBy: loginData.userId
              };
              //console.log(payload);
              resolveIssueCheck(payload, 'start.up.check').then(res => {
                console.log(res);
                if (res?.success) {
                  swalToast.fire({
                    icon: 'success',
                    title: 'Issue has been resolved. Starting re-check...',
                    background: '#5cb85c',
                    iconColor: '#fff',
                    color: '#fff'
                  });
                  setChecks(res?.checks);
                  // console.log(res?.checks, res?.checks.length, res?.checks[0]);
                  setCurrentCheck(res?.checks[0]);
                  setCurrentStep(0);
                  setIsScanning(false);
                  setIsCorrectQrCode(false);
                  setIsModalCheckOpen(true);
                }
              });
            }
          });
        } else {
          swalToast.fire({
            icon: 'error',
            title: 'Failed to log check',
            background: '#d9534f',
            iconColor: '#fff',
            color: '#fff'
          });
        }
      })
      .catch(err => {
        console.log(err);
        swalToast.fire({
          icon: 'error',
          title: 'Error. Something went wrong',
          background: '#d9534f',
          iconColor: '#fff',
          color: '#fff'
        });
      });
  };

  const handleScan = e => {
    setQrCodeValue(e.target.value);
  };

  const onQrCodeKeyup = e => {
    e.preventDefault();
    e.stopPropagation();

    if (e.key === 'Enter') {
      setIsScanning(true);

      if (isCorrectQrCode) {
        handleCheckOK();
        return;
      }

      console.log(qrCodeValue);
      if (!qrCodeValue) {
        return;
      } else {
        if (qrCodeValue.toLowerCase() != currentCheck.checkCode.toLowerCase()) {
          setIsCorrectQrCode(false);
          setQrCodeValue('');

          Swal.fire({
            icon: 'error',
            title: 'Incorrect barcode!',
            timer: 3000,
            timerProgressBar: true,
            background: '#d9534f',
            iconColor: '#fff',
            color: '#fff',
            width: 1300,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        } else {
          console.log('correct one');
          setIsCorrectQrCode(true);
          Swal.fire({
            icon: 'success',
            title: 'Correct barcode!',
            timer: 3000,
            timerProgressBar: true,
            background: '#5cb85c',
            iconColor: '#fff',
            color: '#fff',
            width: 1300,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        }
      }
    }
  };

  const handleCloseCheck = () => {
    console.log('close');
    props.onCheckCompleted();
  };

  useEffect(() => {
    props.PopDataOfQueue('isShowStatupCheck', props.setIsShowStatupCheck);
  }, [props.queue]);

  useEffect(() => {
    if (isModalCheckOpen && props.isShowStatupCheck) {
      tryFocus();
    }
  }, [isModalCheckOpen, props.isShowStatupCheck]);

  return (
    <div className="start-up-check-modal">
      <Modal
        keyboard={false}
        // allowEscapeKey={false}
        show={
          isModalCheckOpen &&
          props.isShowStatupCheck &&
          props.queue[props.queue.length - 1] === 'isShowStatupCheck'
        }
        // onKeyDown={handleKeyDown}
        onHide={handleCloseCheck}
        contentClassName="border"
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          className="bg-light px-card border-bottom-0"
          closeButton={null}
        >
          <Modal.Title as="h4">
            Machine Start Up Checks<br></br>
            <small>
              Step: {currentStep + 1}/{checks.length}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '80vh' }}>
          <div>
            <div className="row">
              <div className="col-sm-12">{currentCheck.checkDescription}</div>
            </div>
            <div className="row">
              <div
                className="col-sm-12 d-flex"
                style={{ justifyContent: 'center' }}
              >
                {currentCheck.images.length > 0 ? (
                  <Carousel interval={10000} style={{ height: '55vh' }}>
                    {currentCheck.images.map((img, index) => (
                      <Carousel.Item key={index}>
                        <Image
                          key={`img${img.id}`}
                          src={`${
                            process.env.REACT_APP_FILE_API_HOST
                          }/files/download/${
                            process.env.REACT_APP_BUCKET_NAME
                          }?key=${img.path.replace('s3/eva-files/', '')}`}
                          alt=""
                          fluid
                          //className="check-img"
                          style={{
                            padding: '5px',
                            cursor: 'pointer',
                            height: 'auto',
                            maxHeight: '55vh'
                          }}
                        ></Image>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="row mt-4" style={{ justifyContent: 'center' }}>
              <div className="col-sm-6 d-flex">
                <input
                  type="text"
                  id="qrCodeInput"
                  onBlur={tryFocus}
                  className="form-control"
                  placeholder="Scan QR Code"
                  value={qrCodeValue}
                  onChange={e => handleScan(e)}
                  onKeyUp={e => onQrCodeKeyup(e)}
                ></input>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isCorrectQrCode ? (
            <span
              className="d-flex blink"
              style={{ alignItems: 'center', marginRight: '20px' }}
            >
              <h4 style={{ marginRight: '10px' }}>
                Please now select the correct option
              </h4>{' '}
              <FaArrowRight
                size={20}
                style={{ marginBottom: '4px' }}
              ></FaArrowRight>
            </span>
          ) : (
            ''
          )}

          <Button
            className="btn btn-primary"
            onClick={handleCheckOK}
            disabled={!isCorrectQrCode}
          >
            OK
          </Button>
          <Button
            className="btn btn-warning"
            onClick={handleReportIssues}
            disabled={!isCorrectQrCode}
          >
            Report Issue
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
